import React from "react";
import Search from './Search';
import SearchResult from './SearchResult';
import RequestCourseSection from './RequestCourseSection';
import SuccessMessage from './SuccessMessage';

class SearchPage extends React.Component 
{

    constructor(props) 
    {
        super(props);
        this.state = {searchParams:null, search:false, successAlert: false};
    }

    componentWillMount(props){
      
        if((this.props.location.state) && (this.props.location.state.requestSuccess === true))
        {
          this.setState({successAlert:true});
        }

    }

    handleSearch = (searchData=null)=>
    {
        this.setState({search:true});
        this.setState({searchParams: searchData});
    }

    hideAlert=()=>
    {

      setTimeout(() => {
        this.setState({successAlert:false});
      }, 3000);

    }
    
    render() 
    {

        return (
            <div className="container-fluid bn_g_page-bg bn_g mtb50">
                <SuccessMessage successAlert={this.state.successAlert}/>
                <Search handleSearch={this.handleSearch}/>
                {this.state.search === true ? <SearchResult searchParams={this.state.searchParams}/> : null}
                {this.state.search === true ? <RequestCourseSection/> : null}
            </div>
        );

    }

}

export default SearchPage;