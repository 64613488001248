import * as commonService from "./commonService.js";
import * as updateService from "./updateService.js";
import * as bushnellAPI from "../services/bushnellAPIService.js";
import * as courseSearchService from "../services/courseSearchService";

var selectedFolder;
var versionData = null;

export function init() {
    window.updateStatus = {
        download: { progress: 0, maxProgress: 0, details: null },
        firmware: { progress: 0, maxProgress: 0, details: null },
        course: { progress: 0, flipCount: 0, maxProgress: 0, details: null },
        device: { progress: 0, maxProgress: 0, details: null },
        font: { progress: 0, maxProgress: 0, details: null },
    };
}

export function refreshProgress() {
    if (window.updateStatus.download.progress < window.updateStatus.download.maxProgress) {
        window.updateStatus.download.progress = window.updateStatus.download.progress + 1;
    }
    if (window.updateStatus.firmware.progress < window.updateStatus.firmware.maxProgress) {
        window.updateStatus.firmware.progress = window.updateStatus.firmware.progress + 1;
    }
    if (window.updateStatus.course.progress < window.updateStatus.course.maxProgress) {
        window.updateStatus.course.flipCount += 1;
        if (window.updateStatus.course.flipCount > 1) {
            window.updateStatus.course.flipCount = 0;
            window.updateStatus.course.progress = window.updateStatus.course.progress + 1;
        }
    }
    if (window.updateStatus.device.progress < window.updateStatus.device.maxProgress) {
        window.updateStatus.device.progress = window.updateStatus.device.progress + 1;
    }
    if (window.updateStatus.font.progress < window.updateStatus.font.maxProgress) {
        window.updateStatus.font.progress = window.updateStatus.font.progress + 1;
    }
}

// function ab2str(buf) {
//     return String.fromCharCode.apply(null, new Uint8Array(buf));
// }

// const toBase64 = (file) =>
//     new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = () => resolve(reader.result);
//         reader.onerror = (error) => reject(error);
//     });

export async function getMetadataInfo() {
    let metadataBuffer = await updateService.getMetadataFile(selectedFolder);
    // let deviceInfo = btoa(String.fromCharCode.apply(null, new Uint8Array(metadataBuffer))); // btoa is deprecated
    let deviceInfo = Buffer.from(metadataBuffer, 'binary').toString('base64');
    return deviceInfo;
}

function getDeviceName(dvn) {
    let result;
    if (dvn.endsWith(" Slope")) {
        result = dvn.substring(0, (dvn.length - " Slope".length))
            .replace(" ", "");
    } else {
        result = dvn;
    }
    return result;
}

async function getMetadata() {
    let deviceInfo = await getMetadataInfo();
    let decryptDeviceInfo = await bushnellAPI.RegisterUSBToolBushnellDeviceGET(deviceInfo).then((response) => {
        if (!response.errorCode) {
            return {
                name: getDeviceName(response.dvn),
                fullName: response.dvn,
                SN: response.sns,
                SKU: response.sku,
                bma: response.bma,
                mdl: response.mdl,
                mft: response.mft,
            };
        } else {
            throw new Error(response.status);
        }
    });
    return decryptDeviceInfo;
}

export async function selectFolder() {
    commonService.Log("Selecting folder");
    selectedFolder = await updateService.selectFolder();
    if (selectedFolder) {
        let metadata = await getMetadata();
        versionData = await updateService.readLocalFileVersions(selectedFolder, metadata);
        commonService.Log("Folder selected");

        bushnellAPI.validateSKU(versionData.deviceSKU);
        versionData.macID = metadata.bma;

        versionData.isKRJPLangSupported = commonService.isLanguageSupportRequiredKRJP(versionData.deviceName, versionData.firmware.localVersion)
        versionData = await updateService.checkForUpdate(versionData, selectedFolder);
        commonService.LogData("Version Data", versionData);

        return versionData;
    } else {
        return null;
    }
}

export function getVersionData() {
    return versionData;
}

export async function setUpdateFlags(enableCourse, enableFirmware, partialCourse) {
    let changeVersionFile = getVersionData();
    const isFirmwareNewVersion = (enableFirmware ? true : false);
    changeVersionFile.course.newVersion = (enableCourse ? true : false);
    changeVersionFile.firmware.newVersion = isFirmwareNewVersion;
    if (enableCourse) {
        changeVersionFile.coursePartial.enabled = partialCourse;
    }
    changeVersionFile.needUpdate = (enableCourse || enableFirmware);
    
    if (!isFirmwareNewVersion){
        changeVersionFile.font.updateRequired = false
    }
    if (changeVersionFile.isKRJPLangSupported && !isFirmwareNewVersion){
        changeVersionFile = await updateService.updateCourcePackageData(changeVersionFile)
    }
    if (changeVersionFile.isKRJPLangSupported)
        changeVersionFile.isKRJPLangSupported = isFirmwareNewVersion
    versionData = changeVersionFile;
    commonService.LogData("Final Version Data", versionData);
}

export function setPartialCourses(courses) {
    let changeVersionFile = getVersionData();
    changeVersionFile.coursePartial.enabled = true;
    changeVersionFile.coursePartial.selectedCourses = courses;
    versionData = changeVersionFile;
}

export function setFullCourseUpdate() {
    let changeVersionFile = getVersionData();
    changeVersionFile.coursePartial.enabled = false;
    changeVersionFile.coursePartial.selectedCourses = [];
    versionData = changeVersionFile;
}

export async function setRestoreDevice() {
    let changeVersionFile = getVersionData();
    changeVersionFile = await updateService.setRestoreDevice(changeVersionFile);
    versionData = changeVersionFile;
    commonService.LogData("Version Data", versionData);
}

export function setCourseProgress(currentFile, totalFiles) {
    let progress = Math.floor((currentFile / totalFiles) * 100);
    if (progress > 90) {
        progress = 90;
    }
    //window.updateStatus.course.details = " - (" + currentFile + " of " + totalFiles + ")";
    window.updateStatus.course.maxProgress = progress;
}

export function startDownload() {
    window.updateStatus.download.maxProgress = 65;
    window.updateStatus.download.progress = 1;
}

export function endDownload() {
    window.updateStatus.download.maxProgress = 0;
    window.updateStatus.download.progress = 100;
}

export async function slowdown() {
    await new Promise(function (resolve) {
        setTimeout(resolve, 3000);
    });
}

function isKRJPUpdateRequired(){
    let isUpdateRequired = false
    let changeVersionFile = getVersionData()   
    if (changeVersionFile.isKRJPLangSupported && changeVersionFile.firmware.newVersion) {
        if ( changeVersionFile.coursePartial.enabled || changeVersionFile.course.newVersion === false ) {
            changeVersionFile.coursePartial.enabled = true;
            isUpdateRequired = true
            versionData = changeVersionFile
        }
    }
    commonService.Log(`Korean and Japanees course download required : ${isUpdateRequired} `)
    return isUpdateRequired
}

async function downloadCoursesKRJP(){
    let KRJPCourses = [];
    let changeVersionFile = getVersionData()
    if (isKRJPUpdateRequired()) {
        const country_Korea = await courseSearchService.downloadData_CourseSearch('KR', '');
        const country_Japan = await courseSearchService.downloadData_CourseSearch('JP', '');
        KRJPCourses = [...country_Korea, ...country_Japan]
        commonService.Log(`${KRJPCourses.length} Korean and Japanees courses need to be updated.`);
    }

    if (changeVersionFile.coursePartial.selectedCourses) {
        changeVersionFile.coursePartial.selectedCourses = [...changeVersionFile.coursePartial.selectedCourses, ...KRJPCourses]
    }
    else {
        changeVersionFile.coursePartial.selectedCourses = [...KRJPCourses]
    }
    commonService.Log(`Total ${changeVersionFile.coursePartial.selectedCourses.length} course(s) need to be updated.`);
    versionData = changeVersionFile
}

async function updateCource(changeVersionFile){
    if (changeVersionFile.course.newVersion || (changeVersionFile.isKRJPLangSupported && changeVersionFile.firmware.newVersion)) {
        window.updateStatus.course.details = null;
        window.updateStatus.course.maxProgress = 1;
        window.updateStatus.course.progress = 1;
        await slowdown();

        if (changeVersionFile.coursePartial.enabled) {
            await updateService.updatePartialCourse(selectedFolder, changeVersionFile, setCourseProgress);
        } else {
            await updateService.updateCourse(selectedFolder, changeVersionFile, setCourseProgress);
        }

        // let changeCourseFile = await updateService.checkCourseList(selectedFolder);
        // let totalFiles = changeCourseFile.create.length + changeCourseFile.update.length + changeCourseFile.delete.length;
        // if (totalFiles > 0) {
        //     await updateService.downloadCourseFiles(selectedFolder, changeCourseFile, setCourseProgress);
        //     await updateService.createNewFileCourseFile(selectedFolder, changeCourseFile);
        //     await updateService.updateCourseList(selectedFolder);
        // }

        await slowdown();
    }
    window.updateStatus.course.maxProgress = 0;
    window.updateStatus.course.progress = 100;
}

async function updateFirmware(changeVersionFile){
    if (changeVersionFile.firmware.newVersion) {
        window.updateStatus.firmware.details = null;
        window.updateStatus.firmware.maxProgress = 65;
        window.updateStatus.firmware.progress = 5;
        await updateService.updateFirmware(selectedFolder, changeVersionFile);
        await slowdown();
    }
    window.updateStatus.firmware.maxProgress = 0;
    window.updateStatus.firmware.progress = 100;
}

async function downloadSaveFontFile(){
    window.updateStatus.font.details = null;
    window.updateStatus.font.maxProgress = 65;
    window.updateStatus.font.progress = 5;
    await updateService.downloadSaveFontFile(selectedFolder)
    await slowdown();
    window.updateStatus.font.maxProgress = 0;
    window.updateStatus.font.progress = 100;
}

export async function startUpdate() {
    commonService.Log("Cource & Firmware update started.");
    // Download and check version files
    startDownload();
    await downloadCoursesKRJP()
    // let changeVersionFile = await updateService.checkForUpdate(versionData);
    let changeVersionFile = getVersionData();
    endDownload();
    //await slowdown();

    if (changeVersionFile.needUpdate) {
        commonService.logTime("Log time");
        await commonService.ScreenWakeLock_Enable();
        //// Download files
        // startDownload();
        // await updateService.downloadCourseListFile(selectedFolder, changeVersionFile, setDownloadFileName);
        // endDownload();
        // await slowdown();

        // const isUpdateRequiredKOJA = commonService.isLanguageSupportRequiredKRJP(changeVersionFile.deviceName, changeVersionFile.firmware.localVersion)
        if(changeVersionFile.isKRJPLangSupported){
            if(changeVersionFile.font.updateRequired){
                // Download Font file
                await downloadSaveFontFile(changeVersionFile)
            }
            // Firmware update
            await updateFirmware(changeVersionFile)
            // Course update
            await updateCource(changeVersionFile)
        }
        else {
            if(changeVersionFile.font.updateRequired){
                // Download Font file
                await downloadSaveFontFile(changeVersionFile)
            }
            // Course update
            await updateCource(changeVersionFile)
            // Firmware update
            await updateFirmware(changeVersionFile)
        }

        // Cleanup
        window.updateStatus.device.details = null;
        window.updateStatus.device.maxProgress = 65;
        window.updateStatus.device.progress = 5;
        //await updateService.updateVersionsFile(selectedFolder, changeVersionFile);
        await updateService.cleanUp(selectedFolder, changeVersionFile.deviceName);
        versionData = null;
        await slowdown();
        window.updateStatus.device.maxProgress = 0;
        window.updateStatus.device.progress = 100;

        await commonService.ScreenWakeLock_Disable();
        commonService.logTime("Log time");
    }
    return changeVersionFile.needUpdate;
}
