const SKey_Token = "SKey_Token";
const SKey_UserProfile = "SKey_UserProfile";

function sessionGet(key) {
    let sData = sessionStorage.getItem(key);
    return JSON.parse(sData);
}

function sessionSet(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
}

function sessionClear() {
    sessionStorage.removeItem(SKey_UserProfile);
    sessionStorage.removeItem(SKey_Token);
}

export function logout() {
    sessionClear();
}

export function isLoggedIn() {
    var token = sessionGet(SKey_Token);
    if (token) return true;
    else return false;
}

export function isExpired() {
    let expired = false;
    let token = getToken();
    if (token.accessTokenExpiry < new Date().getTime() / 1000) {
        expired = true;
    }
    return expired;
}

export function needTokenRefresh() {
    let result = false;
    if (isLoggedIn()) {
        if (isExpired()) {
            result = true;
        }
    }
    return result;
}

export function isValidAccessToken() {
    let isValid = false;
    if (isLoggedIn()) {
        if (!isExpired()) {
            isValid = true;
        }
    }
    return isValid;
}

export function getValidAccessToken() {
    let APIKey = null;
    let token = getToken();
    if (isLoggedIn()) {
        if (!isExpired()) {
            APIKey = token.accessToken;
        }
    }
    return APIKey;
}

export function getToken() {
    return sessionGet(SKey_Token);
}

export function setToken(value) {
    sessionSet(SKey_Token, value);
}

export function getUserProfile() {
    return sessionGet(SKey_UserProfile);
}

export function setUserProfile(value) {
    sessionSet(SKey_UserProfile, value);
}
