import React from "react";
import * as langService from '../services/langService';

class UpdateSuccess extends React.Component {
    handleNext() {
        window.location.replace(process.env.PUBLIC_URL + "/");
    }
    render() {
        return (
            <div className="container-fluid mt-4">
                <div className="co-bx form-cstm">
                    <div className="content-banner text-center">
                        <div className="sucess">
                            <div className="icon">
                                <img alt="tick" src={process.env.PUBLIC_URL + "/images/tick.svg"} />
                            </div>
                            <h2>{langService.t("UpdateProcess_UpdateSuccess")}</h2>
                            <p>{langService.t("UpdateProcess_UpdateSuccessMessage")}</p>
                            <div className="button-wrap text-center mt-4">
                                <button type="button" className="btn btn-primary mt-4" onClick={() => this.handleNext()}>
                                    <i className="fas fa-home"></i>{langService.t("Btn_Home")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UpdateSuccess;
