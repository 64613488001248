import React from 'react';
import * as commonService from "../../services/commonService.js";
import * as langService from '../../services/langService';
import * as configService from "../../services/configDataService";
import * as downloadService from "../../services/downloadService";
import SelectList from '../selectList/SelectList';
import PageLoader from '../Loader/Loader';

class Search extends React.Component {
    constructor() 
    {
      super();
      this.state = {
        countryData: null,
        stateData: null,
        country: "",
        stateName: "",
        searchAll:"",
        city:"",
        emptyCountry:false,
        emptyState:false,
        loader:true,
        errorFlag:false
      }

      this.handleChangeCountry = this.handleChangeCountry.bind(this);
      this.handleChangeState = this.handleChangeState.bind(this);

    }

    componentDidMount = async () => {
        try 
        {
          await this.storeCountryData();
        } 
        catch (err) 
        {
          commonService.showMessage("Error: " + err.message);
        }
    };

    handleChangeCountry(value) 
    {
        this.setState({ country: value, emptyCountry: false, stateName:"", city:""});
    }

    handleChangeState(value) 
    {
        this.setState({ state: value, stateName: value, emptyState: false});
    }

    downloadDataFile = async (dataURL) => {
      let fileObject = await downloadService.downloadData(dataURL);
      if (fileObject.status !== 200) throw new Error(langService.t("UserAccount_FileRequestFailed"));
      return fileObject.json();
    };

    downloadData_Country = async () => 
    {
        let URL_Country = "/testData/data/country.json";
        let countryData = await this.downloadDataFile(URL_Country);
        return countryData;
    };

    downloadData_State = async () => 
    {
        let URL_State = "/testData/data/state.json";
        let stateData = await this.downloadDataFile(URL_State);
        return stateData;
    };

    storeCountryData = async () => {

      let data = this.state.countryData;
      if (!data) {
          let configData = configService.getConfigData();
          let URL_Country = '../'+configData.LocalURL.CountryList;
          let URL_State = '../'+configData.LocalURL.StateList;

          let cData = await this.downloadDataFile(URL_Country);
          let sData = await this.downloadDataFile(URL_State);
          this.setState({
              countryData: cData,
              stateData: sData
          });
      }
    };

    searchData = ()=>
    {

      let errorFlag = 0;
      if((this.state.country === null) || (this.state.country === ''))
      {
        this.setState({emptyCountry:true});
        errorFlag=1;
      }

      if(((this.state.country === 'US') || (this.state.country === 'CA')) && ((this.state.stateName === '') || (this.state.stateName === null)))
      {
        this.setState({emptyState:true});
        errorFlag=1;
      }

      if(errorFlag === 1)
      {
        return;
      }

      let searchAll = this.state.searchAll;
      let city = this.state.city;
      let stateName = this.state.stateName;
      
      let searchData = {country: this.state.country, state:stateName, searchAll:searchAll, city:city};
      this.props.handleSearch(searchData);

    }

    checkKeyUp=(event)=>
    {
      if (event.key === 'Enter') {
        this.searchData();
      }
    }

    changeInput =(event)=>
    {


      // let str = event.target.value
      if(event.target.name === "searchAll")
      {
        this.setState({searchAll:event.target.value});
      }
      else if(event.target.name === "city")
      {
        this.setState({city:event.target.value});
      }

    }

    componentWillMount=()=>{

      let configData = configService.getConfigData();
      let url = configData.CourseSearchURL.SearchURL;
      let token = configData.AWS_APIKey;

      const requestOptions = {
        method: 'GET',
        headers: {'x-api-key':token}
      };

      fetch(url, requestOptions)
      .then(async response => {

          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();

          if ((response.ok) && (data.statusCode === 200)) 
          {

              let countrySearch = '';
              let stateSearch = '';
              if((data.body.country !== '') && (data.body.country !== null) && (data.body.country !== '-'))
              {

                countrySearch = data.body.country;

                if((data.body.state !== '') && (data.body.state !== null) && (data.body.state !== '-'))
                {

                  stateSearch = data.body.state;

                }

              }
              else
              {
                countrySearch = 'US';
                stateSearch = 'FL';
              }

              this.setSearch({country:countrySearch, state:stateSearch});

          }
          else
          {
              this.setState({errorFlag:true});
              this.hideAlert();
          }

          this.setState({loader:false});

      })
      .catch(error => {
          this.setState({errorFlag:true, loader:false});
          this.hideAlert();
      });

    }

    setSearch=(data)=>{

      this.setState({country: data.country,stateName:data.state}, ()=>{
                      
        this.searchData();

      });

    }

    render() 
    {

        let selectCountry = "";
        let cData = this.state.countryData;
        if (cData) 
        {
          selectCountry = (
            <SelectList
              handleChange={this.handleChangeCountry}
              value={this.state.country}
              dataList={cData.countries}
            />
          );
        } 
        else 
        {
          selectCountry = "";
        }

        let selectState = "";
        let sData = this.state.stateData;
        let countryCode = this.state.country;
        if (sData) {
          var filterData = sData.states.filter(function (el) {
            return el.countryCode === countryCode;
          });
          if (filterData.length === 0) {
              selectState = "";
          } else {
            selectState = (
              <SelectList
                handleChange={this.handleChangeState}
                value={this.state.stateName}
                dataList={filterData}
              />
            );
          }
        } else {
          selectState = "";
        }

        return (
            <>

                {(this.state.loader === true ? <PageLoader/>  : null)}
                <div className="bn_g_custom-container">
                    <div className="course-request-search">
                    <div className="row">
                        <div className="col-lg-12">
                            <input onKeyUp={this.checkKeyUp} onChange={this.changeInput} name="searchAll" type="text" placeholder={langService.t("CourseSearch_PHNameOrZip")} className="w-100" value={this.state.searchAll}/>
                        </div>
                    </div>
                    <div className="row location-select">
                        <div className="col-sm">
                            <div className="row">
                                <div className="col-3 loc-name">{langService.t("CourseSearch_Country")}</div>
                                <div className="col-9">
                                 <div className="dropdown custom-select2">
                                    {selectCountry}
                                    {(this.state.emptyCountry === true) ? <span className="errorMsg">{langService.t("CourseSearch_SelectCountry")}</span> : null}
                                </div> 
                                
                                </div>
                            </div>
                        </div>
                        {/* {(selectState !== "") ?
                        <div className="col-sm">
                            <div className="row">
                                <div className="col-3 text-center loc-name">{langService.t("CourseSearch_State")}</div>
                                <div className="col-9">
                                <div className="dropdown custom-select2">
                                    {selectState}
                                    {(this.state.emptyState === true) ? <span className="errorMsg">{langService.t("CourseSearch_SelectState")}</span> : null}
                                </div>
                                </div>
                            </div>
                        </div> : null
                        } */}

                        <div className="col-sm">
                            <div className="row">
                                <div className="col-3 text-center  loc-name">{langService.t("CourseSearch_City")}</div>
                                <div className="col-9">
                                    <input onKeyUp={this.checkKeyUp} onChange={this.changeInput} name="city" type="text" placeholder={langService.t("CourseSearch_Optional")} className="w-100" value={this.state.city}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row location-select">
                        {(selectState !== "") ?
                            <div className="col-sm">
                                <div className="row">
                                    <div className="col-3 loc-name">{langService.t("CourseSearch_State")}</div>
                                    <div className="col-9">
                                        <div className="dropdown custom-select2">
                                            {selectState}
                                            {(this.state.emptyState === true) ? <span className="errorMsg">{langService.t("CourseSearch_SelectState")}</span> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            <div className="col-sm">
                                <div className="row">
                                    <div className="col-3 text-center loc-name"></div>
                                    <div className="col-9 w-100"></div>
                                </div>
                            </div>
                        }
                        <div className="col-sm">
                            <input type="button" value={langService.t("Btn_Search")} className="btn-primary float-right searchBtn" onClick={this.searchData}/>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-lg-12 search-btn">
                            <input type="button" value={langService.t("Btn_Search")} className="btn-primary float-right searchBtn" onClick={this.searchData}/>
                        </div>
                    </div> */}
                    </div>
                </div>

            </>
        );
    }
}

export default Search;