import React from "react";
import Alerts from './alerts';
import * as langService from '../../services/langService'

class SuccessMessage extends React.Component 
{

    constructor(props) 
    {
        super(props);
        this.state = {successAlert: false};
    }

    hideAlert=()=>
    {

      setTimeout(() => {
        this.setState({successAlert:false});
      }, 10000);

    }

    componentWillMount(props)
    {
        if(this.props.successAlert === true)
        {
            this.setState({successAlert:true}, ()=>{
                this.hideAlert();
            });
        } 
    }

    render()
    {
        return(<>{(this.state.successAlert === true) ? <div className="bn_g_custom-container"><Alerts alertType="success" alertMessage={langService.t("CourseSearch_AddSuccess")} /></div> : null}</>);
    }

}

export default SuccessMessage;
