import InitScript from "./InitScript";
import MessagePopup from "./MessagePopup";
import LoaderDisplay from "./LoaderDisplay";
import Home from "./Home";
import {
  Switch,
  Route,
  HashRouter
} from "react-router-dom";
import AppCourse from '../src/course/AppCourse';

function App() {
  return (
    <div className="App">
      <InitScript />
      <HashRouter>
          <Switch>
              <Route path="/course">  
                <AppCourse/>
              </Route>
              <Route path="/request">
                <AppCourse/>
              </Route>
              <Route exact path="/">
            	  <LoaderDisplay />              
                <Home />
                <MessagePopup />
              </Route>
          </Switch>
      </HashRouter>
     </div>
  );
}

export default App;
