import * as commonService from "./commonService";
import * as langService from '../services/langService';
import * as configDataService from "../services/configDataService";
import * as updateFileService from "./updateFileService";
import * as updateDownloadService from "./updateDownloadService";

const _ = require('lodash') 

var CoursesUpdated = [];
var CourseImagesUpdated = [];

export async function selectFolder() {
    return await updateFileService.selectFolder();
}

export function getBlankVersions() {
    let versionData = {
        deviceName: "",
        deviceFullName: "",
        deviceSN: "",
        deviceSKU: "",
        isSlope: false,
        course: { localVersion: "", serverVersion: "", newVersion: false, forcedUpdate: false },
        firmware: { localVersion: "", serverVersion: "", newVersion: false, forcedUpdate: false },
        coursePartial: { enabled: false, selectedCourses: [] },
        font: { updateRequired: false, isExistLocal: false, languageVersion:"" },
        isKRJPLangSupported: false,
        needUpdate: false,
        packageData: null,
        approxUpdateTime: 0,
    };
    return versionData;
}

export async function readLocalFileVersions(baseFolder, metadata) {
    commonService.Log("Reading local file versions");
    let checkVersionData = getBlankVersions();

    checkVersionData.deviceName = metadata.name;
    checkVersionData.deviceFullName = metadata.fullName;
    checkVersionData.deviceSN = metadata.SN;
    checkVersionData.deviceSKU = metadata.SKU;

    let firmwareVersion = await getFirmwareVersion(baseFolder);
    checkVersionData.firmware.localVersion = firmwareVersion.version;

    // All Phantom 3 devices are slope-enabled by default.
    // We don't have any information about whether the phantom 3 has a slope disabled version.
    // So in the Bushnell server, it is considering like Phantom 3 is slope disabled. ie, isSlope = false.
    checkVersionData.isSlope = metadata.name === 'Phantom3' ? false : firmwareVersion.isSlope;

    checkVersionData.course.localVersion = await updateFileService.readCourseVersionFile(baseFolder);

    return checkVersionData;
}

export async function getMetadataFile(baseFolder) {
    return await updateFileService.readMetadataFile(baseFolder);
}

async function getFirmwareVersion(baseFolder) {
    let deviceName = "";
    let localVersion = "";
    let isSlope = false;
    let haveFirmwareVersion = false;
    commonService.Log("Reading firmware version");
    let sysVerData = await updateFileService.readFirmwareVersionFile(baseFolder);
    let lines = sysVerData.split("\r\n");
    let subLines = lines[0].split("\n");
    for (let line of subLines) {
        let cols = line.split("|");
        if (cols.length === 2) {
            if (cols[0] === "FWR") {
                let subSplit = cols[1].split("_V");
                if (subSplit.length === 2) {
                    if (subSplit[0].endsWith("_S")) {
                        deviceName = subSplit[0].substring(0, subSplit[0].length - 2);
                        isSlope = true;
                    } else {
                        deviceName = subSplit[0];
                    }
                    localVersion = subSplit[1];
                    haveFirmwareVersion = true;
                }
            }
        }
    }
    if (!haveFirmwareVersion) {
        throw new Error(langService.t("UpdateService_ReadingFirmwareVersionFailed"));
    }
    return { name: deviceName, version: localVersion, isSlope: isSlope };
}

function getApproxUpdateTime(checkVersionData) {
    let i;
    let totalSize = 0;
    for (i in checkVersionData.packageData.packages) {
        totalSize = totalSize + checkVersionData.packageData.packages[i].sz;
    }
    if(checkVersionData.packageData.imgPackages){
        for (i in checkVersionData.packageData.imgPackages) {
            totalSize = totalSize + Math.ceil(checkVersionData.packageData.imgPackages[i].sz / 2);
        }
    }
    return Math.ceil(totalSize / 600);
}

export async function checkForUpdate(checkVersionData, baseFolder) {
    commonService.Log("Load server file versions");
    checkVersionData.firmware.serverVersion = "";
    if (isIonElite(checkVersionData.deviceName)) {
        let firmwareData = await getFirmwareVersionPath_IonEdge(checkVersionData.deviceName, checkVersionData.firmware.localVersion);
        if (firmwareData.imagePath !== "") {
            checkVersionData.firmware.serverVersion = firmwareData.imageVersion;
        }
    }
    else {
        let rebootData = await updateDownloadService.downloadFileReboot(checkVersionData.deviceName, checkVersionData.isSlope);
        if (rebootData) {
            let haveFirmwareVersion = false;
            let lines = rebootData.split("\r\n");
            let subLines = lines[0].split("\n");
            let subSplit = subLines[0].split("_V");

            if (subSplit.length === 2) {
                checkVersionData.firmware.serverVersion = subSplit[1];
                haveFirmwareVersion = true;
            }
            if (!haveFirmwareVersion) {
                throw new Error(langService.t("UpdateService_ReadingFirmwareVersionFailed"));
            }
        } else {
            checkVersionData.firmware.serverVersion = "";
        }
    }

    let isHighEnd = isIonElite(checkVersionData.deviceName);
    let packageText = await updateDownloadService.downloadCourseVersionPackageData(checkVersionData.course.localVersion, isHighEnd);
    checkVersionData.packageData = JSON.parse(packageText);
    checkVersionData.course.serverVersion = checkVersionData.packageData.MapDataVer;
    checkVersionData.approxUpdateTime = getApproxUpdateTime(checkVersionData);

    // Update if versions are different, no need to check version numbers
    commonService.Log("Check firmware version");
    if (checkVersionData.firmware.serverVersion !== "") {
        if (checkVersionData.firmware.localVersion !== checkVersionData.firmware.serverVersion) {
            checkVersionData.firmware.newVersion = true;
            checkVersionData.needUpdate = true;
            commonService.Log("New firmware available");
        }
    }
    commonService.Log("Check course version");
    if (checkVersionData.course.localVersion !== checkVersionData.course.serverVersion) {
        checkVersionData.course.newVersion = true;
        checkVersionData.needUpdate = true;
        commonService.Log("New course available");
    }

    commonService.Log("Check firmware force update");
    if (checkVersionData.firmware.newVersion === true) {
        let txtFirmwareVersions = await updateDownloadService.downloadFirmwareVersions();
        let apiDataFirmwareVersions = JSON.parse(txtFirmwareVersions);

        let filterData = apiDataFirmwareVersions.firmwareVersions.filter(function (el) {
            return el.name.replace(/\s+/g, '') === checkVersionData.deviceName.replace(/\s+/g, '') && el.isSlope === checkVersionData.isSlope;
        });
        if (filterData.length === 1) {
            if (isLargerVersion(checkVersionData.firmware.localVersion, filterData[0].minVersion)) {
                if (commonService.checkForceUpdateRequired(checkVersionData.deviceName, checkVersionData.firmware.localVersion)) {
                    checkVersionData.firmware.forcedUpdate = false;
                }
                else {
                    checkVersionData.firmware.forcedUpdate = true;
                    commonService.Log("Firmware force update enabled.");
                }
            }
            checkVersionData.font.languageVersion = filterData[0].lngVersion;
        } else {
            commonService.Log("No matching firmware version found");
        }
    }
    commonService.Log("Check font file exists and requires an update");
    const isFileExist = await updateFileService.isFontFileExist(baseFolder);
    if (!isFileExist) {
        if (versionCompare(checkVersionData.firmware.serverVersion, checkVersionData.font.languageVersion) >= 0) {
            if(isIonElite(checkVersionData.deviceName)){
                checkVersionData.font.updateRequired = false;
            } else {
                checkVersionData.font.updateRequired = true;
                commonService.Log("The font file does not exist locally and requires an update.");
            }
        }
    } else {
        checkVersionData.font.isExistLocal = true;
        commonService.Log("The font file exists locally and no update is required.");
    }

    return checkVersionData;
}

export async function setRestoreDevice(checkVersionData) {
    let rebootData = await updateDownloadService.downloadFileReboot(checkVersionData.deviceName, checkVersionData.isSlope);
    if (rebootData) {
        let lines = rebootData.split("\r\n");
        let subLines = lines[0].split("\n");
        let subSplit = subLines[0].split("_V");

        if (subSplit.length === 2) {
            checkVersionData.firmware.serverVersion = subSplit[1];
            checkVersionData.firmware.newVersion = true;
            checkVersionData.firmware.forcedUpdate = true;
        }
    }

    let isHighEnd = isIonElite(checkVersionData.deviceName);
    let packageText = await updateDownloadService.downloadCourseVersionPackageData("full", isHighEnd);
    checkVersionData.packageData = JSON.parse(packageText);
    checkVersionData.course.serverVersion = checkVersionData.packageData.MapDataVer;
    checkVersionData.approxUpdateTime = getApproxUpdateTime(checkVersionData);
    checkVersionData.course.newVersion = true;
    checkVersionData.course.forcedUpdate = true;

    checkVersionData.needUpdate = true;
    return checkVersionData;
}

function escapeJSON(json) {
    // eslint-disable-next-line
    let escapable = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g;
    let meta = {    // table of character substitutions
                '\b': '\\b',
                '\t': '\\t',
                '\n': '\\n',
                '\f': '\\f',
                '\r': '\\r',
                '"' : '\\"',
                '\\': '\\\\'
                };
    escapable.lastIndex = 0;
    return escapable.test(json) ? json.replace(escapable, function (a) {
        let c = meta[a];
        return (typeof c === 'string') ? c : '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
    }) : json;
};

function courseAPIRecordsToJson(courseData) {
    let jsonData = [];
    let tRow;
    let name, address, city;
    for (let row of courseData) {
        if(row.nam_nl && row.adr_nl && row.cit_nl){
            name = `${escapeJSON(row.nam)} *${escapeJSON(row.cny)} ${escapeJSON(row.nam_nl)}`;
            address = `${escapeJSON(row.adr)} * ${escapeJSON(row.adr_nl)}`;
            city = `${escapeJSON(row.cit)} * ${escapeJSON(row.cit_nl)}`;
        }
        else {
            name = `${escapeJSON(row.nam)}`;
            address = `${escapeJSON(row.adr)}`;
            city = `${escapeJSON(row.cit)}`;
        }

        tRow = '{ "ID": "' + row.cid +
            '", "Name": "' + name +
            '", "Address": "' + address +
            '", "Country": "' + escapeJSON(row.cny) +
            '", "State": "' + escapeJSON(row.sta) +
            '", "City": "' + city +
            '", "Lat": "' + row.lat +
            '", "Lon": "' + row.lon +
            '", "Active": "' + 1 +
            '", "Ver": "' + row.ver +
            '", "Folder": "' + row.idx +
            '" }';
        jsonData.push(JSON.parse(tRow));
    }
    return jsonData;
}

function courseJsonToText(cItem) {
    let courseData = "";
    courseData += cItem.ID + "|";
    courseData += cItem.Name + "|";
    courseData += cItem.Address + "|";
    courseData += cItem.Country + "|";
    courseData += cItem.State + "|";
    courseData += cItem.City + "|";
    courseData += cItem.Lat + "|";
    courseData += cItem.Lon + "|";
    courseData += cItem.Active + "|";
    courseData += cItem.Ver + "|";
    courseData += cItem.Folder + "\r\n";
    return courseData;
}

function courseJsonToText_Array(courses) {
    let i;
    let cItem;
    let courseData = "";
    for (i = 0; i < courses.length; i += 1) {
        cItem = courses[i];
        courseData += courseJsonToText(cItem);
    }
    return courseData;
}

function courseTextToJson(cols) {
    let tRow = '{ "ID": "' + cols[0] +
        '", "Name": "' + escapeJSON(cols[1]) +
        '", "Address": "' + escapeJSON(cols[2]) +
        '", "Country": "' + escapeJSON(cols[3]) +
        '", "State": "' + escapeJSON(cols[4]) +
        '", "City": "' + escapeJSON(cols[5]) +
        '", "Lat": "' + cols[6] +
        '", "Lon": "' + cols[7] +
        '", "Active": "' + cols[8] +
        '", "Ver": "' + cols[9] +
        '", "Folder": "' + cols[10] +
        '" }';
    let jsonData = JSON.parse(tRow);
    return jsonData;
}

async function getCoursePipeBugFixData() {
    let txtCoursePipeBugFixData = await updateDownloadService.downloadCoursePipeBugFixData();
    let apiDataCoursePipeBugFixData = JSON.parse(txtCoursePipeBugFixData);
    let coursePipeBugFixData = courseAPIRecordsToJson(apiDataCoursePipeBugFixData);
    return coursePipeBugFixData;
}

function getPipeBugFixRow(ID, coursePipeBugFixData) {
    let bugFixItem = null;
    let filterData;
    commonService.Log("Reading pipe bug data for id " + ID);
    filterData = coursePipeBugFixData.filter(function (el) {
        return el.ID === ID;
    });
    if (filterData.length === 1) {
        bugFixItem = filterData[0];
    } else {
        commonService.Log("Pipe bug missing id " + ID);
    }
    return bugFixItem;
}

async function readCourseList(baseFolder) {
    let jsonData = [];
    let txtCourseData = await updateFileService.readCourseFile(baseFolder);
    let coursePipeBugFixData = await getCoursePipeBugFixData();
    let bugFixItem;
    let lines = txtCourseData.split("\r\n");
    for (let line of lines) {
        let cols = line.split("|");
        if (cols.length > 11) {
            bugFixItem = getPipeBugFixRow(cols[0], coursePipeBugFixData);
            if (bugFixItem) {
                jsonData.push(bugFixItem);
            }
        } else if (cols.length === 11 && cols[0] === "00000000" ) {
            // No need to do anything (Remove this course)
        } else if (cols.length === 11) {
            jsonData.push(courseTextToJson(cols));
        }
    }
    return jsonData;
}

async function readCourseUpdateList(baseFolder) {
    let jsonData = [];
    let fileExist = await updateFileService.existCourseUpdateFile(baseFolder);
    if (fileExist) {
        let txtData = await updateFileService.readCourseUpdateFile(baseFolder);
        let lines = txtData.split("\r\n");
        for (let line of lines) {
            let cols = line.split("|");
            if (cols.length === 11 && cols[0] === "00000000" ) {
                // No need to do anything (Remove this course)
            } else if (cols.length === 11) {
                jsonData.push(courseTextToJson(cols));
            }
        }
    }
    return jsonData;
}

export async function checkCourseList(baseFolder, courseData) {
    let checkData = {
        fullCourseVals: [],
        updateCourseVals: [],
        newCourseVals: [],
        courseListUpdateRequired: false
    }
    let i, j;
    let updateCourseIds = [];

    commonService.Log("Checking course list");
    let localCourseData = await readCourseList(baseFolder);
    commonService.Log("Convert API course list");
    let updateCourseData = courseAPIRecordsToJson(courseData);
    
    commonService.Log("Cross check course list");
    // Must update course list in order
    for (i = 0; i < localCourseData.length; i += 1) {
        let selectedVal, krjpUpdatedVal;
        for (j = 0; j < updateCourseData.length; j += 1) {
            selectedVal = null;
            krjpUpdatedVal = null;
            if (localCourseData[i].ID === updateCourseData[j].ID) {
                updateCourseIds.push(updateCourseData[j].ID);
                krjpUpdatedVal = updateCourseData[j];
                if (isLargerVersion(localCourseData[i].Ver, updateCourseData[j].Ver)) {
                    selectedVal = updateCourseData[j];
                }
                break;
            }
        }
        if (krjpUpdatedVal === null) { // value is not in API
            checkData.fullCourseVals.push(localCourseData[i]);
        } else if (selectedVal) { // value is in API & course version is changed
            checkData.fullCourseVals.push(selectedVal);
            checkData.updateCourseVals.push(selectedVal);
            checkData.courseListUpdateRequired = true;
        } else { // value is in API & no change in course version but may have language changes
            checkData.fullCourseVals.push(krjpUpdatedVal);
            checkData.courseListUpdateRequired = true;
        }
    }
    for (i = 0; i < updateCourseData.length; i += 1) {
        let itemExist = updateCourseIds.includes(updateCourseData[i].ID);
        if (!itemExist) {
            checkData.newCourseVals.push(updateCourseData[i]);
        }
    }
    return checkData;
}

async function downloadSaveCourseDataFile(baseFolder, fileId, folderId, isHighEnd) {
    let data = await updateDownloadService.downloadCourseData(fileId, folderId, isHighEnd);
    await updateFileService.saveCourseDataFile(baseFolder, fileId, folderId, data);
}

async function downloadSaveCourseImageFile(baseFolder, fileId, folderId) {
    let data = await updateDownloadService.downloadCourseImage(fileId, folderId);
    await updateFileService.saveCourseImageFile(baseFolder, fileId, folderId, data);
}

async function downloadDataFiles(baseFolder, isHighEnd, courses, setFileDownloadEvent) {
    let i;
    let fileId;
    let folderId;
    let selectedData;
    commonService.Log("Downloading course data");
    for (i = 0; i < courses.length; i += 1) {
        selectedData = courses[i];
        fileId = selectedData.ID;
        folderId = selectedData.Folder;
        await downloadSaveCourseDataFile(baseFolder, fileId, folderId, isHighEnd);
        if(isHighEnd)
            await downloadSaveCourseImageFile(baseFolder, fileId, folderId);
        setFileDownloadEvent(i);
    }
}

async function updateCourseList(baseFolder, courses) {
    commonService.Log("Updating new course list");
    let courseText = courseJsonToText_Array(courses);
    await updateFileService.updateCourseFile(baseFolder, courseText);
}

async function updateUpdateList(baseFolder, courses) {
    let i;
    let filterData;
    commonService.Log("Updating update course list");
    let currentUpdateCourseData = await readCourseUpdateList(baseFolder);
    let newCourseList = [];
    for (i = 0; i < currentUpdateCourseData.length; i += 1) {
        let selectedCourse = currentUpdateCourseData[i];
        filterData = courses.filter(function (el) {
            return el.ID === selectedCourse.ID;
        });
        if (filterData.length > 0) {
            newCourseList.push(filterData[0]);
        }
        else {
            newCourseList.push(selectedCourse);
        }
    }
    
    for (i = 0; i < courses.length; i += 1) {
        let selectedCourse = courses[i];
        filterData = newCourseList.filter(function (el) {
            return el.ID === selectedCourse.ID;
        });
        if (filterData.length === 0 ) {
            newCourseList.push(selectedCourse);
        }
    }
    let courseText = courseJsonToText_Array(newCourseList);
    await updateFileService.updateCourseUpdateFile(baseFolder, courseText);
}

export async function updatePartialCourse(baseFolder, changeVersionFile, setCourseProgress) {
    let progressFileCount = 0;
    let selectedCourses = changeVersionFile.coursePartial.selectedCourses;
    let totalFileCount = selectedCourses.length + 3;
    setCourseProgress(progressFileCount, totalFileCount);
    let checkData = await checkCourseList(baseFolder, selectedCourses);
    let isHighEnd = isIonElite(changeVersionFile.deviceName);

    if(checkData.courseListUpdateRequired){
        if (checkData.updateCourseVals.length > 0) {
            await downloadDataFiles(baseFolder, isHighEnd, checkData.updateCourseVals, function(i) { progressFileCount = progressFileCount + 1; setCourseProgress(progressFileCount, totalFileCount); });
        }
        await updateCourseList(baseFolder, checkData.fullCourseVals);
    }
    if (checkData.newCourseVals.length > 0) {
        await downloadDataFiles(baseFolder, isHighEnd, checkData.newCourseVals, function(i) { progressFileCount = progressFileCount + 1; setCourseProgress(progressFileCount, totalFileCount); });
        await updateUpdateList(baseFolder, checkData.newCourseVals);
    }
    progressFileCount = progressFileCount + 1;
    setCourseProgress(progressFileCount, totalFileCount);
}

function getApproxFileCount(packageJSON) {
    let i;
    let avgFileSize = 7; // 7.4 KB
    let totalFileSize = 0;
    let totalFileCount = 0;
    for (i in packageJSON.packages) {
        totalFileSize = totalFileSize + packageJSON.packages[i].sz;
    }
    totalFileCount = totalFileSize / avgFileSize;
    return totalFileCount;
}

const checkAndUpdateCourse = function(name) { 
    if(!_.find(CoursesUpdated, (course) => course === name)){
        CoursesUpdated.push(name);
        return true;
    } else {
        return false;
    } 
}

const checkAndUpdateCourseImage = function(name) { 
    if(!_.find(CourseImagesUpdated, (course) => course === name)){
        CourseImagesUpdated.push(name);
        return true;
    } else {
        return false;
    } 
}

export async function updateCourse(baseFolder, changeVersionFile, setCourseProgress) {
    let i;
    let url;
    let data;
    let fileObject;
    const packageJSON = changeVersionFile.packageData;
    if (packageJSON.packages.length > 0) {
        let progressFileCount = 0;
        let totalFileCount = getApproxFileCount(packageJSON);
        let progressUpdate = function(pos) { progressFileCount = progressFileCount + 1; setCourseProgress(progressFileCount, totalFileCount); }
        CoursesUpdated = [];
        i = packageJSON.packages.length;
        while(i--){
            commonService.Log("Downloading data package : " + (packageJSON.packages.length - i) + " of " + packageJSON.packages.length);
            url = packageJSON.packages[i].url;
            fileObject = await updateDownloadService.downloadData(url);
            if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
            data = await fileObject.arrayBuffer();
            await extractToFolder(data, baseFolder, progressUpdate, checkAndUpdateCourse, false);
        }
        if(isIonElite(changeVersionFile.deviceName)) {
            CourseImagesUpdated = [];
            i = packageJSON.imgPackages.length;
            while(i--){
                commonService.Log("Downloading data image package : " + (packageJSON.imgPackages.length - i) + " of " + packageJSON.imgPackages.length);
                url = packageJSON.imgPackages[i].url;
                fileObject = await updateDownloadService.downloadData(url);
                if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
                data = await fileObject.arrayBuffer();
                await extractToFolder(data, baseFolder, progressUpdate, checkAndUpdateCourseImage, true);
            }
        }

        commonService.Log("Updating local course list");
        const firmwareVersion = changeVersionFile.firmware.newVersion ? changeVersionFile.firmware.serverVersion : changeVersionFile.firmware.localVersion;
        const isLangCourseFileRequired = commonService.checkNonLanguageSupportedDevice(changeVersionFile.deviceName, firmwareVersion)
        url = isLangCourseFileRequired ? packageJSON.CourseList_lng : packageJSON.CourseList;
        fileObject = await updateDownloadService.downloadData(url);
        if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
        data = await fileObject.arrayBuffer();
        await updateFileService.updateCourseFile(baseFolder, data);

        commonService.Log("Updating course version");
        await updateFileService.updateCourseVersionFile(baseFolder, packageJSON.MapDataVer);
        await updateFileService.deleteCourseUpdateFile(baseFolder);
    }
}

export async function updateFirmware(baseFolder, checkVersionData) {
    if (checkVersionData.firmware.newVersion) {
        if (isIonElite(checkVersionData.deviceName)) {
            let firmwareData = await getFirmwareVersionPath_IonEdge(checkVersionData.deviceName, checkVersionData.firmware.localVersion);
            let firmwareImageData = await updateDownloadService.downloadFirmwareImageFile(firmwareData.imagePath);
            let firmwareRebootData = await updateDownloadService.downloadFirmwareRebootFile(firmwareData.imagePath.replace("image.delta", "reboot.pcf"));
            await updateFileService.updateFirmware(baseFolder, firmwareImageData, firmwareRebootData, false);
        } else {
            let firmwareImageData = await updateDownloadService.downloadFileImage(checkVersionData.deviceName, checkVersionData.isSlope);
            let firmwareRebootData = await updateDownloadService.downloadFileReboot(checkVersionData.deviceName, checkVersionData.isSlope);
            await updateFileService.updateFirmware(baseFolder, firmwareImageData, firmwareRebootData, true);
        }
    }
}

function isIonElite(deviceName) {
    return deviceName.replace(/\s+/g, '').toLocaleLowerCase() === "ionelite";
}

export async function getFirmwareVersionPath_IonEdge(deviceName, deviceVersion) {
    let i;
    let newDeviceVersion = deviceVersion;
    let newDeviceImagePath = "";
    deviceName = deviceName.replace(/\s+/g, '').toLocaleLowerCase();
    let versionsList = await updateDownloadService.downloadFirmwareImageVersionsFile(deviceName);
    for (i = 0; i < versionsList.length; i += 1) {
        if (versionsList[i].From === deviceVersion) {
            if (isLargerVersion(newDeviceVersion, versionsList[i].To)) {
                newDeviceVersion = versionsList[i].To;
                newDeviceImagePath = versionsList[i].FileName;
            }
        }
    }
    return { imageVersion: newDeviceVersion, imagePath: newDeviceImagePath };
}

async function getUnusedFiles(baseFolder) {
    let i;
    let fileList = [];
    let courseFileList = [];
    let unusedFileList = [];

    commonService.Log("Reading course list");
    let localCourseData = await readCourseList(baseFolder);
    for (i = 0; i < localCourseData.length; i += 1) {
        courseFileList.push({ ID: localCourseData[i].ID + ".dat", Folder: localCourseData[i].Folder });
    }

    commonService.Log("Reading update course list");
    let fileExist = await updateFileService.existCourseUpdateFile(baseFolder);
    if (fileExist) {
        let updateCourseData = await readCourseUpdateList(baseFolder);
        for (i = 0; i < updateCourseData.length; i += 1) {
            courseFileList.push({ ID: updateCourseData[i].ID + ".dat", Folder: updateCourseData[i].Folder });
        }
    }
    fileList = await updateFileService.getCourseDataFileContents(baseFolder);

    let filterData;
    commonService.Log("Cross check course with data files");
    for (i = 0; i < fileList.length; i += 1) {
        let item = fileList[i];
        filterData = courseFileList.filter(function (el) {
            return el.ID === item.ID && el.Folder === item.Folder;
        });
        if (filterData.length === 0) {
            unusedFileList.push({ ID: item.ID, Folder: item.Folder });
        }
    }
    return unusedFileList;
}

async function deleteUnusedFiles(baseFolder, deviceName) {
    let unusedFileList = await getUnusedFiles(baseFolder);
    let configData = configDataService.getConfigData();
    commonService.LogData("Unused files", unusedFileList);
    const hasImage = isIonElite(deviceName);
    if (configData.Flags.UpdateDeleteUnusedDataFiles === "1") {
        await updateFileService.deleteCourseDataFiles(baseFolder, unusedFileList, hasImage);
    } else {
        commonService.Log("Unused files delete disabled");
    }
}

export async function cleanUp(baseFolder, deviceName) {
    await updateFileService.deleteDownloadFolder(baseFolder);
    await deleteUnusedFiles(baseFolder, deviceName);
}
// async function extractToFolder(zipArrayBuffer, extractFolder) {
//     var JSZip = require("jszip");
//     let zip = await JSZip.loadAsync(zipArrayBuffer);
//
//     var fileNames = [];
//     Object.entries(zip.files).forEach((entry) => {
//         fileNames.push({ name: entry[1].name, dir: entry[1].dir });
//     });
//
//     let i;
//     let j;
//     let fileData;
//     let nameParts;
//     let newName;
//     let folderExist;
//     let currentFolder = extractFolder;
//     let fileCount = fileNames.length;
//     for (i in fileNames) {
//         commonService.Log("Extracting file " + (parseInt(i) + 1) + " of " + fileCount);
//         if (!fileNames[i].dir) {
//             nameParts = fileNames[i].name.split("/");
//             currentFolder = extractFolder;
//             for (j = 0; j < nameParts.length - 1; j++) {
//                 folderExist = await fileService.childFolderExist(currentFolder, nameParts[j]);
//                 if (!folderExist) {
//                     await fileService.createFolder(currentFolder, nameParts[j]);
//                 }
//                 currentFolder = await fileService.selectChildFolder(currentFolder, nameParts[j]);
//             }
//             newName = nameParts[nameParts.length - 1];
//             fileData = await zip.file(fileNames[i].name).async("uint8array"); // string
//             await fileService.createOrUpdateFile(currentFolder, newName, fileData);
//         }
//     }
// }

async function extractToFolder(zipArrayBuffer, baseFolder, extractFileCallback, checkAndUpdateCourse, isImage) {
    var JSZip = require("jszip");
    let zip = await JSZip.loadAsync(zipArrayBuffer);

    var fileNames = [];
    Object.entries(zip.files).forEach((entry) => {
        fileNames.push({ name: entry[1].name, dir: entry[1].dir });
    });

    await updateFileService.extractFolderTreeFiles(baseFolder, fileNames, async function(filePath) {
        return await zip.file(filePath).async("uint8array");
    }, checkAndUpdateCourse, extractFileCallback, isImage);
}

function isLargerVersion(checkVersion, newVersion) {
    let isLarge = false;
    commonService.Log("Version check | Version : " + checkVersion + ", New version : " + newVersion);
    if (versionCompare(checkVersion, newVersion) < 0) {
        isLarge = true;
    }
    if (isLarge) {
        commonService.Log("Version check | Need update");
    } else {
        commonService.Log("Version check | No update needed");
    }
    return isLarge;
}

// result < 0 = v1 is smaller
// result > 0 = v2 is smaller
// result = 0 = both are equal
export function versionCompare(v1, v2) {
    var vnum1 = 0, vnum2 = 0;
    for (var i = 0, j = 0; (i < v1.length || j < v2.length);)
    {
        while (i < v1.length && v1[i] !== '.')
        {
            vnum1 = vnum1 * 10 + (v1[i] - '0');
            i++;
        }
        // storing numeric part of version 2 in vnum2
        while (j < v2.length && v2[j] !== '.')
        {
            vnum2 = vnum2 * 10 + (v2[j] - '0');
            j++;
        }
        if (vnum1 > vnum2)
            return 1;
        if (vnum2 > vnum1)
            return -1;
        // if equal, reset variables and go for next numeric part
        vnum1 = vnum2 = 0;
        i++;
        j++;
    }
    return 0;
}

export async function downloadSaveFontFile(baseFolder) {
    commonService.Log("Download and save Font file");
    const fontFileData = await updateDownloadService.downloadFontFile();
    await updateFileService.updateFontFile(baseFolder, fontFileData);
}

export async function updateCourcePackageData(checkVersionData){
    let isHighEnd = isIonElite(checkVersionData.deviceName);
    let packageText = await updateDownloadService.downloadCourseVersionPackageData(checkVersionData.course.localVersion, isHighEnd);
    checkVersionData.packageData = JSON.parse(packageText);
    checkVersionData.course.serverVersion = checkVersionData.packageData.MapDataVer;
    checkVersionData.approxUpdateTime = getApproxUpdateTime(checkVersionData);
    return checkVersionData;
}