import React from "react";
import UpdateProcess from "./UpdateProcess";

class UpdateHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = { nextForm: props.nextForm };
        this.nextForm = this.nextForm.bind(this);
    }
    nextForm(mode) {
        this.state.nextForm(mode);
    }
    render() {
        return (
            <div className="UpdateHome">
                <UpdateProcess nextForm={this.nextForm} />
            </div>
        );
    }
}

export default UpdateHome;
