import * as commonService from "./commonService";
import * as fileService from "./fileService";
import * as langService from "./langService";

const Name_FolderUpdate = "update";
const Name_FolderDownload = "download";
const Name_FolderFirmware = "fota";
const Name_FolderCourse = "map";
const Name_FolderCourseData = "MapDatabase";
const Name_FolderCourseImage = "MapImage";
const Name_FolderFont = "font";

const Name_FileVersionCourse = "mapDataVer.txt";
const Name_FileVersionFirmware = "sysVer.txt";
const Name_FileCourseFile = "CourseList.txt";
const Name_FileCourseListUpdate = "CourseListUpdate.txt";
const Name_FileFirmwareImage = "image.bin";
const Name_FileFirmwareImageDelta = "image.delta";
const Name_FileFirmwareReboot = "reboot.pcf";
const Name_FileMetadata = "metadata.inf";
const Name_FileFont = "simsum_u16.bin";

// Root Folder

export async function selectFolder() {
    commonService.Log("Show folder picker");
    let baseFolder = await fileService.selectFolderPicker();
    //if (!baseFolder) throw new Error(langService.t("UpdateService_FolderSelectionFailed"));
    if (baseFolder) {
        await fileService.getReadWritePermission(baseFolder);
        await validateFolder(baseFolder);
    }
    return baseFolder;
}

async function validateFolder(baseFolder) {
    commonService.Log("Checking selected folder");
    let exist = await fileService.fileExist(baseFolder, Name_FileVersionFirmware);
    if (!exist) throw new Error(langService.t("UpdateService_SystemVersionFileMissing"));
    exist = await fileService.fileExist(baseFolder, Name_FileMetadata);
    if (!exist) throw new Error(langService.t("UpdateService_MetadataFileMissing"));
    exist = await fileService.childFolderExist(baseFolder, Name_FolderCourse);
    if (!exist) throw new Error(langService.t("UpdateService_CourseFolderMissing"));
    let courseFolder = await fileService.selectChildFolder(baseFolder, Name_FolderCourse);
    exist = await fileService.fileExist(courseFolder, Name_FileVersionCourse);
    if (!exist) throw new Error(langService.t("UpdateService_CourseVersionFileMissing"));
    exist = await fileService.fileExist(courseFolder, Name_FileCourseFile);
    if (!exist) throw new Error(langService.t("UpdateService_CourseListFileMissing"));

    commonService.Log("Selecting update folder");
    let updateFolder = await fileService.selectChildFolder(baseFolder, Name_FolderUpdate);
    if (!updateFolder) {
        await fileService.createFolder(baseFolder, Name_FolderUpdate);
        updateFolder = await fileService.selectChildFolder(baseFolder, Name_FolderUpdate);
    }

    commonService.Log("Selecting download folder");
    let downloadFolder = await fileService.selectChildFolder(updateFolder, Name_FolderDownload);
    if (downloadFolder) await fileService.deleteFolder(updateFolder, Name_FolderDownload);
    await fileService.createFolder(updateFolder, Name_FolderDownload);
}

// Root Folder
// Folders

async function getCourseFolder(baseFolder) {
    commonService.Log("Selecting course folder");
    let courseFolder = await fileService.selectChildFolder(baseFolder, Name_FolderCourse);
    if (!courseFolder) throw new Error(langService.t("UpdateService_CourseFolderSelectionFailed"));
    return courseFolder;
}

async function getFirmwareFolder(baseFolder) {
    commonService.Log("Selecting firmware folder");
    let firmwareFolder = await fileService.selectChildFolder(baseFolder, Name_FolderFirmware);
    if (!firmwareFolder) throw new Error(langService.t("UpdateService_FirmwareFolderSelectionFailed"));
    return firmwareFolder;
}

async function getCourseDataFolder(baseFolder) {
    commonService.Log("Selecting course data folder");
    let courseDataFolder = await fileService.selectChildFolder(baseFolder, Name_FolderCourseData);
    if (!courseDataFolder) throw new Error(langService.t("UpdateService_CourseFolderSelectionFailed"));
    return courseDataFolder;
}

async function getCourseImageFolder(baseFolder) {
    commonService.Log("Selecting course image folder");
    let courseDataFolder = await fileService.selectChildFolder(baseFolder, Name_FolderCourseImage);
    if (!courseDataFolder) throw new Error(langService.t("UpdateService_CourseImageFolderSelectionFailed"));
    return courseDataFolder;
}

// Folders
// Metadata

export async function readMetadataFile(baseFolder) {
    commonService.Log("Reading metadata");
    let metadata = await fileService.readFileData_ArrayBuffer(baseFolder, Name_FileMetadata);
    return metadata;
}

// Metadata
// Firmware

export async function readFirmwareVersionFile(baseFolder) {
    commonService.Log("Read version data");
    let sysVerData = await fileService.readFileData_Text(baseFolder, Name_FileVersionFirmware);
    return sysVerData;
}

export async function updateFirmware(baseFolder, imageData, rebootData, isBinFile) {
    let firmwareFolder = await getFirmwareFolder(baseFolder);
    if (!firmwareFolder) throw new Error(langService.t("UpdateService_FirmwareFolderSelectionFailed"));

    commonService.Log("Updating image file");
    if(isBinFile)
        await fileService.createOrUpdateFile(firmwareFolder, Name_FileFirmwareImage, imageData);
    else
        await fileService.createOrUpdateFile(firmwareFolder, Name_FileFirmwareImageDelta, imageData);

    commonService.Log("Updating reboot file");
    await fileService.createOrUpdateFile(firmwareFolder, Name_FileFirmwareReboot, rebootData);
}

// Firmware
// Misc

export async function deleteDownloadFolder(baseFolder) {
    let updateFolder = await fileService.selectChildFolder(baseFolder, Name_FolderUpdate);
    if (updateFolder) {
        await fileService.deleteFolder(updateFolder, Name_FolderDownload);
    }
}

export async function extractFolderTreeFiles(baseFolder, fileNames, extractData, checkAndUpdateCourse, extractFileCallback, isCourseImage) {
    let i, j;
    let newName;
    let nameParts;
    let folderExist;
    let courseDataFolder;
    if (isCourseImage) {
        courseDataFolder = await getCourseImageFolder(baseFolder);
    } else { 
        courseDataFolder = await getCourseDataFolder(baseFolder);
    }
    let currentFolder = courseDataFolder;
    let fileData;
    let fileCount = fileNames.length;
    for (i in fileNames) {
        commonService.Log("Extracting file " + (parseInt(i) + 1) + " of " + fileCount);
        currentFolder = courseDataFolder;
        if (!fileNames[i].dir) {
            if(checkAndUpdateCourse(fileNames[i].name)){
                nameParts = fileNames[i].name.split("/");
                for (j = 0; j < nameParts.length - 1; j++) {
                    folderExist = await fileService.childFolderExist(currentFolder, nameParts[j]);
                    if (!folderExist) {
                        await fileService.createFolder(currentFolder, nameParts[j]);
                    }
                    currentFolder = await fileService.selectChildFolder(currentFolder, nameParts[j]);
                }
                newName = nameParts[nameParts.length - 1];
                fileData = await extractData(fileNames[i].name);
                await fileService.createOrUpdateFile(currentFolder, newName, fileData);
                extractFileCallback(i);
            }
        }
    }
}

// export async function extractFolderTreeFile(baseFolder, fileTree, data) {
//     let i;
//     let newName;
//     let nameParts;
//     let folderExist;
//     let courseDataFolder = await getCourseDataFolder(baseFolder);
//     let currentFolder = courseDataFolder;
//     nameParts = fileTree.split("/");
//     for (i = 0; i < nameParts.length - 1; i++) {
//         folderExist = await fileService.childFolderExist(currentFolder, nameParts[i]);
//         if (!folderExist) {
//             await fileService.createFolder(currentFolder, nameParts[i]);
//         }
//         currentFolder = await fileService.selectChildFolder(currentFolder, nameParts[i]);
//     }
//     newName = nameParts[nameParts.length - 1];
//     await fileService.createOrUpdateFile(currentFolder, newName, data);
// }

// Misc
// Course File

export async function readCourseFile(baseFolder) {
    let courseFolder = await getCourseFolder(baseFolder);
    commonService.Log("Reading course file");
    let txtData = await fileService.readFileData_Text(courseFolder, Name_FileCourseFile);
    return txtData;
}

export async function updateCourseFile(baseFolder, data) {
    let courseFolder = await getCourseFolder(baseFolder);
    commonService.Log("Updating course file");
    await fileService.rewriteFile(courseFolder, Name_FileCourseFile, data);
}

// Course File
// Course Version File

export async function readCourseVersionFile(baseFolder) {
    let courseFolder = await getCourseFolder(baseFolder);
    commonService.Log("Reading course version");
    let localCourseVersion = await fileService.readFileData_Text(courseFolder, Name_FileVersionCourse);
    return localCourseVersion;
}

export async function updateCourseVersionFile(baseFolder, dataVer) {
    let courseFolder = await getCourseFolder(baseFolder);
    commonService.Log("Updating course version file");
    await fileService.rewriteFile(courseFolder, Name_FileVersionCourse, dataVer);
}

// Course Version File
// Course Update File

export async function existCourseUpdateFile(baseFolder) {
    let courseFolder = await getCourseFolder(baseFolder);
    let exist = await fileService.fileExist(courseFolder, Name_FileCourseListUpdate);
    return exist;
}

export async function readCourseUpdateFile(baseFolder) {
    let courseFolder = await getCourseFolder(baseFolder);
    commonService.Log("Reading course update file");
    let localCourseUpdateData = await fileService.readFileData_Text(courseFolder, Name_FileCourseListUpdate);
    return localCourseUpdateData;
}

export async function updateCourseUpdateFile(baseFolder, data) {
    let courseFolder = await getCourseFolder(baseFolder);
    commonService.Log("Updating course update file");
    await fileService.createOrUpdateFile(courseFolder, Name_FileCourseListUpdate, data);
}

export async function deleteCourseUpdateFile(baseFolder) {
    let courseFolder = await getCourseFolder(baseFolder);
    let exist = await fileService.fileExist(courseFolder, Name_FileCourseListUpdate);
    if (exist) {
        commonService.Log("Deleting course update file");
        await fileService.deleteFile(courseFolder, Name_FileCourseListUpdate);
    }
}

// Course Update File
// Course Data File

export async function saveCourseDataFile(baseFolder, fileId, folderId, data) {
    let courseDataFolder = await getCourseDataFolder(baseFolder);
    let exist = await fileService.childFolderExist(courseDataFolder, folderId);
    if (!exist) {
        commonService.Log("Create course data sub folder");
        await fileService.createFolder(courseDataFolder, folderId);
    }
    commonService.Log("Selecting course data sub folder");
    let dataFolder = await fileService.selectChildFolder(courseDataFolder, folderId);
    commonService.Log("Create course data file");
    await fileService.createOrUpdateFile(dataFolder, fileId + ".dat", data);
}

export async function saveCourseImageFile(baseFolder, fileId, folderId, data) {
    let courseImageFolder = await getCourseImageFolder(baseFolder);
    let exist = await fileService.childFolderExist(courseImageFolder, folderId);
    if (!exist) {
        commonService.Log("Create course image sub folder");
        await fileService.createFolder(courseImageFolder, folderId);
    }
    commonService.Log("Selecting course image sub folder");
    let imageFolder = await fileService.selectChildFolder(courseImageFolder, folderId);
    commonService.Log("Create course image file");
    await fileService.createOrUpdateFile(imageFolder, fileId + ".tar", data);
}

function removeMACTempFiles(unusedFileList) {
    let filterData = unusedFileList.filter(function (el) {
        return (el.ID.startsWith("._") === false);
    });
    return filterData;
}

export async function deleteCourseDataFiles(baseFolder, unusedFileList, hasImage) {
    let i;
    let fileId, folderId;
    commonService.Log("Removing MAC temp files");
    unusedFileList = removeMACTempFiles(unusedFileList);
    commonService.Log("Deleting unused course data files " + unusedFileList.length);
    let courseDataFolder = await getCourseDataFolder(baseFolder);
    let dataFolder;
    for (i = 0; i < unusedFileList.length; i += 1) {
        commonService.Log("Deleting data file : " + (parseInt(i) + 1) + " of " + unusedFileList.length);
        fileId = unusedFileList[i].ID;
        folderId = unusedFileList[i].Folder;
        dataFolder = await fileService.selectChildFolder(courseDataFolder, folderId);
        await fileService.deleteFile(dataFolder, fileId);
    }
    if (hasImage) {
        commonService.Log("Deleting unused course image files " + unusedFileList.length);
        const courseImageFolder = await getCourseImageFolder(baseFolder);
        for (i = 0; i < unusedFileList.length; i += 1) {
            commonService.Log("Deleting image file : " + (parseInt(i) + 1) + " of " + unusedFileList.length);
            fileId = unusedFileList[i].ID.replace('dat', 'tar');
            folderId = unusedFileList[i].Folder;
            dataFolder = await fileService.selectChildFolder(courseImageFolder, folderId);
            await fileService.deleteFile(dataFolder, fileId);
        }
    }
}

export async function getCourseDataFileContents(baseFolder) {
    let i, j;
    let fileList = [];
    let subFolder;
    let subFolderContents;
    commonService.Log("Reading course data files");
    let courseDataFolder = await getCourseDataFolder(baseFolder);
    let folderContents = await fileService.getFolderContents(courseDataFolder);
    for (i = 0; i < folderContents.directories.length; i += 1) {
        subFolder = await fileService.selectChildFolder(courseDataFolder, folderContents.directories[i]);
        subFolderContents = await fileService.getFolderContents(subFolder);
        for (j = 0; j < subFolderContents.files.length; j += 1) {
            fileList.push({ ID: subFolderContents.files[j], Folder: folderContents.directories[i] });
        }
    }
    return fileList;
}

// Course Data File
// Font File
export async function isFontFileExist(baseFolder) {
    commonService.Log("Check font file exist");
    let isFileExist = false;
    const fontFolder = await fileService.selectChildFolder(baseFolder, Name_FolderFont);
    if (fontFolder) {
        isFileExist = await fileService.fileExist(fontFolder, Name_FileFont);
    }
    return isFileExist;
}

async function getFontFolder(baseFolder) {
    commonService.Log("Selecting font folder");
    let fontFolder = await fileService.selectChildFolder(baseFolder, Name_FolderFont);
    if (!fontFolder) {
        await fileService.createFolder(baseFolder, Name_FolderFont);
        fontFolder = await fileService.selectChildFolder(baseFolder, Name_FolderFont);
    }
    return fontFolder;
}

export async function updateFontFile(baseFolder, fontFileData) {
    const fontFolder = await getFontFolder(baseFolder);
    if (!fontFolder) throw new Error(langService.t("UpdateService_FontFolderSelectionFailed"));
    commonService.Log("Updating Font file");
    await fileService.createOrUpdateFile(fontFolder, Name_FileFont, fontFileData);
}
// Font File