import React from "react";

import * as commonService from "../services/commonService.js";
import * as langService from '../services/langService';
import * as configDataService from "../services/configDataService";
import * as updateManager from "../services/updateManager.js";
import * as bushnellAPI from "../services/bushnellAPIService.js";

class UpdateStep3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextForm: props.nextForm,
            deviceData: null,
            connectStatus: 0,
            isRestoreDevice: false,
            popupUpdateRefresh : false
        };
    }

    handleNext = () => {
        window.$("#updatepopup").modal("hide");
        let deviceData = updateManager.getVersionData();
        if (deviceData.coursePartial.enabled) {
            this.state.nextForm("CourseSelect");
        } else {
            this.state.nextForm("UpdateStep3");
        }
    };

    handleNoUpdate = () => {
        this.state.nextForm("NoChange");
    };

    connectPopupClose = async () => {
        if (this.state.connectStatus === 1) {
            await this.registerUpdateProcess();
        } else if (this.state.connectStatus === 2) {
            this.updateOnlyProcess();
        } else if (this.state.connectStatus === 3) {
            this.restoreDeviceProcess();
        }
        this.setState({ connectStatus: 0 });
    };

    showUpdatePopup() {
        this.setState(
            {
                popupUpdateRefresh: !this.state.popupUpdateRefresh
            }, () => { window.$("#updatepopup").modal("show") });
    }

    registerUpdate = () => {
        this.setState({
            connectStatus: 1,
            isRestoreDevice: false
        });
        window.$("#connectedsuccess").modal("hide");
        window.$("#connectedsuccess").on("hidden.bs.modal", async () => { await this.connectPopupClose(); });
    };

    registerUpdateProcess = async () => {
        try {
            let deviceInfo = await updateManager.getMetadataInfo();
            let deviceData = updateManager.getVersionData();
            let deviceFirmware = deviceData.firmware.localVersion.replace(/(\r\n|\n|\r)/gm, "");
            let courseFirmware = deviceData.course.localVersion.replace(/(\r\n|\n|\r)/gm, "");
            await bushnellAPI.RegisterUSBToolBushnellDevicePOST(deviceInfo, deviceFirmware, courseFirmware).then((response) => {
                if (!response.errorCode) {
                    let deviceData = updateManager.getVersionData();
                    if (deviceData.needUpdate) {
                        this.showUpdatePopup();
                    } else {
                        this.handleNoUpdate();
                    }
                } else {
                    commonService.Log(langService.t("UpdateProcess_RegistrationFailed"));
                    commonService.showMessage(response.status);
                }
            });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    };

    updateOnly = () => {
        this.setState({
            connectStatus: 2,
            isRestoreDevice: false
        });
        window.$("#connectedsuccess").modal("hide");
        window.$("#connectedsuccess").on("hidden.bs.modal", async () => { await this.connectPopupClose(); });
    };

    updateOnlyProcess() {
        let deviceData = updateManager.getVersionData();
        if (deviceData.needUpdate) {
            this.showUpdatePopup();
        } else {
            this.handleNoUpdate();
        }
    };

    restoreDevice = () => {
        this.setState({
            connectStatus: 3,
            isRestoreDevice: true
        });
        window.$("#connectedsuccess").modal("hide");
        window.$("#connectedsuccess").on("hidden.bs.modal", async () => { await this.connectPopupClose(); });
    };

    restoreDeviceProcess = async () => {
        try {
            await updateManager.setRestoreDevice();
            let deviceData = updateManager.getVersionData();
            this.setState({ deviceData: deviceData }, () => { this.showUpdatePopup(); });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    };

    selectFolder = () => {
        commonService.showLoader(true);
        updateManager.selectFolder()
        .then(responseData => {
            if (responseData) {
                this.setState({ deviceData: responseData });
                commonService.showLoader(false);
                window.$("#connectedsuccess").modal("show");
            } else {
                window.$(".btn").trigger("blur");
                commonService.showLoader(false);
            }
        })
        .catch(err => {
            commonService.showLoader(false);
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        });
    };

    render() {
        return (
            <div className="container-fluid mt-4">
                <div className="co-bx form-cstm">
                    <h1 className="text-center">
                        <img alt="usb_icon" src={process.env.PUBLIC_URL + "/images/usb_icon.svg"} />
                        <span>{langService.t("UpdateProcess_ConnectBushnellDevice")}</span>
                    </h1>
                    <div className="list-wrap">
                        <ul className="list">
                            <li>{langService.t("UpdateProcess_TurnOFFConnectBrowse")}</li>
                        </ul>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="graph">
                                    <img className="img-fluid" alt="usb_cable" src={process.env.PUBLIC_URL + "/images/usb_cable.png"} />
                                </div>
                            </div>
                        </div>
                        <ul className="list">
                            <li>{langService.t("UpdateProcess_SelectFolder")}</li>
                        </ul>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="graph">
                                    <img className="img-fluid" alt="device_drive" src={process.env.PUBLIC_URL + "/images/device_drive.png"} />
                                </div>
                            </div>
                        </div>
                        <ul className="list">
                            <li>
                                {langService.t("UpdateProcess_GrantPermissionViewDetails")}
                            </li>
                        </ul>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="graph">
                                    <img className="img-fluid" alt="popup" src={process.env.PUBLIC_URL + "/images/popup.png"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="indicators text-center">
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                    </ul>
                    <div className="button-wrap text-center mb-2">
                        <button type="button" className="btn btn-primary" onClick={() => this.selectFolder()}>
                            {langService.t("Btn_Browse")}
                        </button>
                    </div>
                </div>
                { this.state.deviceData ? (
                    <div>
                        <PopupConnected
                            registerUpdate={this.registerUpdate}
                            updateOnly={this.updateOnly}
                            restoreDevice={this.restoreDevice}
                            deviceData={this.state.deviceData}
                            />
                        <PopupUpdate
                            handleUpdate={this.handleNext}
                            deviceData={this.state.deviceData}
                            isRestoreDevice={this.state.isRestoreDevice}
                            popupRefresh={this.state.popupUpdateRefresh}
                            />
                    </div>
                ) : "" }
            </div>
        );
    }
}

class PopupConnected extends React.Component {

    getDeviceImagePath() {
        let deviceId = commonService.getDeviceId(this.props.deviceData.deviceName);
        let imagePath = commonService.getDeviceImagePath("bd_" + deviceId, this.props.deviceData.deviceSKU);
        return imagePath;
    }

    render() {
        let canRegister = bushnellAPI.canRegisterDevice(this.props.deviceData.macID, this.props.deviceData.deviceSKU);
        let canShowRestoreDevice = configDataService.getConfigData().Flags.EnableRestoreDevice === "1";
        // const browserProps = commonService.getBrowserProps();
        // const canShowRestoreDevice = browserProps.isOSWindows;
        return (
            <div className="modal custom-pop fade" id="connectedsuccess" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered model-450" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{langService.t("UpdateProcess_DeviceConnected")}</h5>
                            <button type="button" className="close" aria-label="Close" data-dismiss="modal">
                                <span aria-hidden="true">
                                    <img alt="close" src={process.env.PUBLIC_URL + "/images/close_icon.svg"} />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body p-4">
                            {(canRegister === true) ? (
                                <div className="info mb-3">
                                    <p>
                                        {langService.t("UpdateProcess_DeviceRegisterNow")}
                                    </p>
                                </div>
                            ) : (
                                ""
                            )}
                            <ul className="list-unstyled list_pro1">
                                <li className="media">
                                    <div className="img-holder mr-3">
                                        <img alt="device" src={process.env.PUBLIC_URL + "/" + this.getDeviceImagePath()} />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h1 id="deviceName"> {this.props.deviceData.deviceFullName} </h1>
                                        {this.props.deviceData.deviceSN ? <h2 id="deviceSN">SN #: {this.props.deviceData.deviceSN}</h2> : ""}
                                        {this.props.deviceData.deviceSKU ? <h3 id="deviceSKU">SKU #: {this.props.deviceData.deviceSKU}</h3> : ""}
                                    </div>
                                </li>
                            </ul>
                            <div className="btn-grp d-flex justify-content-lg-between btns-two">
                                {(canRegister === true) ? (
                                    <button type="button" className="btn btn-secondary mr-2" onClick={() => this.props.registerUpdate()} >
                                        {langService.t("Btn_RegisterAndUpdate")}
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-secondary mr-2" disabled>
                                        {langService.t("Btn_RegisterAndUpdate")}
                                    </button>
                                )}
                                <button id="update" type="button" className="btn btn-primary ml-2" onClick={() => this.props.updateOnly()}>
                                    {langService.t("Btn_UpdateAnyway")}
                                </button>
                                { canShowRestoreDevice ?
                                <button id="forcedUpdate" type="button" className="btn btn-primary ml-2" onClick={() => this.props.restoreDevice()}>
                                    {langService.t("Btn_RestoreDevice")}
                                </button>
                                : "" }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class PopupUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updateCourse: this.props.deviceData.course.newVersion,
            updateFirmware: this.props.deviceData.firmware.newVersion,
            updateEnabled: (this.props.deviceData.course.newVersion || this.props.deviceData.firmware.newVersion),
            isShowKRJPNote: (this.props.deviceData.isKRJPLangSupported && this.props.deviceData.firmware.newVersion)
        };
    }
    canShowCustomCourse() {
        let canShowCC = configDataService.getConfigData().Flags.ShowCustomCourseUpdate === "1";
        if (canShowCC) {
            canShowCC = !this.props.isRestoreDevice;
        }
        return canShowCC;
    }
    getDisabledStatus() {
        let status = {
            updateCourseDisabled: this.isUpdateEnabled(this.props.deviceData.course) ? "" : "disabled",
            updateFirmwareDisabled: this.isUpdateEnabled(this.props.deviceData.firmware) ? "" : "disabled"
        };
        return status;
    }
    isUpdateEnabled(obj) {
        let result = false;
        if (obj.newVersion) {
            if (!obj.forcedUpdate) {
                result = true;
            }
        }
        return result;
    }
    getRemainingTime(obj, approxUpdateTime) {
        let result = "";
        if (obj.newVersion) {
            result = langService.t("UpdateProcess_ApproxTime").replace("{time}", approxUpdateTime);
        }
        return result;
    }
    handleUpdateCourse = (e) => {
        if (this.props.deviceData.course.newVersion) {
            this.setState({
                updateCourse: e.target.checked,
                updateEnabled: (e.target.checked || this.state.updateFirmware)
            });
        }
    }
    handleUpdateFirmware = (e) => {
        if (this.props.deviceData.firmware.newVersion) {
            this.setState({
                updateFirmware: e.target.checked,
                updateEnabled: (e.target.checked || this.state.updateCourse),
            });
            if(this.props.deviceData.isKRJPLangSupported){
                this.setState({
                    isShowKRJPNote: e.target.checked
                });
            }
        }
    }
    handleUpdate = async () => {
        const updateCourseCustom = true;
        await updateManager.setUpdateFlags(this.state.updateCourse, this.state.updateFirmware, updateCourseCustom);
        this.props.handleUpdate();
    }

    getDeviceImagePath() {
        let deviceId = commonService.getDeviceId(this.props.deviceData.deviceName);
        let imagePath = commonService.getDeviceImagePath("bd_" + deviceId, this.props.deviceData.deviceSKU);
        return imagePath;
    }

    render() {
        let disabledStatus = this.getDisabledStatus();
        const firmwareUpdationTime = this.state.isShowKRJPNote ? 10 : 2;
        return (
            <div className="modal custom-pop fade" id="updatepopup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered model-450" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{langService.t("UpdateProcess_UpdateYourDevice")}</h5>
                            <button type="button" className="close" aria-label="Close" data-dismiss="modal">
                                <span aria-hidden="true">
                                    <img alt="close" src={process.env.PUBLIC_URL + "/images/close_icon.svg"} />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body p-4">
                            <div className="update">
                                <ul className="list-unstyled list_pro2">
                                    <li className="media">
                                        <div className="img-holder mr-3">
                                            <img alt="device" src={process.env.PUBLIC_URL + "/" + this.getDeviceImagePath()} />
                                        </div>
                                        <div className="media-body align-self-center">
                                            <h1>{this.props.deviceData.deviceFullName}</h1>
                                            <h2>SN #: {this.props.deviceData.deviceSN}</h2>
                                            <h3>{langService.t("UpdateProcess_CourseVersion")}: {this.props.deviceData.course.localVersion}</h3>
                                            <h4>{langService.t("UpdateProcess_FirmwareVersion")}: {this.props.deviceData.firmware.localVersion}</h4>
                                        </div>
                                    </li>
                                </ul>
                                <hr />
                                <p>{langService.t("UpdateProcess_SelectNewFileVersionsToUpdate")}</p>
                                <h2>{langService.t("UpdateProcess_NewVersionsAvailable")}</h2>
                                <p></p>
                                <h3>{langService.t("UpdateProcess_SelectFilesToUpdate")}</h3>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck24" onChange={this.handleUpdateCourse} checked={this.state.updateCourse} disabled={disabledStatus.updateCourseDisabled} />
                                    <label className="custom-control-label" htmlFor="customCheck24">
                                        {langService.t("UpdateProcess_CourseFilesUpdate")}
                                    </label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck25" onChange={this.handleUpdateFirmware} checked={this.state.updateFirmware} disabled={disabledStatus.updateFirmwareDisabled} />
                                    <label className="custom-control-label" htmlFor="customCheck25">
                                        {langService.t("UpdateProcess_FirmwareVersionUpdate")}: {this.props.deviceData.firmware.serverVersion} {this.getRemainingTime(this.props.deviceData.firmware, firmwareUpdationTime)}
                                    </label>
                                </div>
                                {this.state.isShowKRJPNote ? <p> {langService.t("UpdateProcess_Phantom2FirmwareUpdateKRJP")}</p> : ""}
                                <div className="btn-grp d-flex justify-content-lg-between btns-two">
                                    <button type="button" data-dismiss="modal" className="btn btn-secondary mr-2">
                                        {langService.t("Btn_Cancel")}
                                    </button>
                                    { this.state.updateEnabled ? (
                                    <button type="button" className="btn btn-primary ml-2" onClick={async () => {await this.handleUpdate();}}>
                                        {langService.t("Btn_Update")}
                                    </button>
                                    ) : (
                                    <button type="button" className="btn btn-secondary ml-2" disabled>
                                        {langService.t("Btn_Update")}
                                    </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UpdateStep3;
