import React from 'react';

class Alerts extends React.Component 
{

    constructor() 
    {
      super();
      this.state = {courseData:null, getData: false};
    }

    render()
    {
        let alertType = (this.props.alertType==='success' ? 'alert alert-success' : 'alert alert-danger')
        return(

            <div className="col-lg-12 req-space">
                <div className="row">
                    <div className={alertType} role="alert" style={{width:'100%', textAlign:'center'}}>
                        {this.props.alertMessage}
                    </div>
                </div>
            </div>

        )
    }

}

export default Alerts;