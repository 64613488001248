import React from "react";
import * as langService from "../services/langService";
import * as configDataService from "../services/configDataService";

class ContactSupport extends React.Component {

    render() {
        return (
            <div className="form-main contact_sprt">
                <h1 className="mb-3">{langService.t("UserAccount_ContactSupport")}</h1>
                <div className="form-group">
                    <h6>{langService.t("UserAccount_ProductSupport")}</h6>
                </div>
                <div className="form-group">
                    <label className="clr-1">{langService.t("UserAccount_WarrantyIssues")}</label>
                </div>
                <div className="form-group">
                    {langService.t("UserAccount_Phone")}
                </div>
                <div className="form-group">
                    <a target="_blank" rel="noreferrer" href={configDataService.getConfigData().ExternalLink.ContactSupport}>
                        {langService.t("UserAccount_Website")}
                    </a>
                </div>
            </div>
        );
    }
}

export default ContactSupport;
