import React from 'react';
import Loader from "react-loader-spinner";

class PageLoader extends React.Component {

    render() 
    {

        return (
            <>
                <div className="loaderOverlay">
                    <div className="loader">
                        <Loader
                            type="TailSpin"
                            color="#fe5000"
                            height={50}
                            width={50}
                        />
                    </div>
                </div>

            </>
        );
    }
    
}

export default PageLoader;