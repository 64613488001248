import React from "react";
import * as langService from '../services/langService';
import * as bushnellAPI from "../services/bushnellAPIService.js";
import * as commonService from "../services/commonService.js";
import SwitchChannel from "./SwitchChannel";
import Countdown from "react-countdown";
import * as configService from "../services/configDataService";

class ForgotOTP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: props.email,
            countryCode: props.countryCode,
            phone: props.phone,
            verificationCode: "",
            password: "",
            nextForm: props.nextForm,
            isOTPError: false,
            btnClicked: false,
            isSubmitted: false,
            sendType: props.sendType,
            emailAttemptCount: props.emailAttemptCount,
            phoneAttemptCount: props.phoneAttemptCount,
            sid: props.sid,
            captchaActionResend: 'PasswordResendOTP',
            captchaActionSwitchChannel: 'PasswordSwitchChannel',
        };
        this.counterTimer = this.handleCounterTimer();
        this.countdownApi = null;
        this.inputVerificationCode = React.createRef();
        this.inputPassword = React.createRef();
    }

    componentDidMount() {
        this.inputVerificationCode.current.focus();
    }

    componentDidUpdate() {
        this.counterTimer = this.handleCounterTimer();
    }

    handleCounterTimer = () => {
        let inSeconds = 0;
        let emailAttemptCount = this.state.emailAttemptCount;
        let phoneAttemptCount = this.state.phoneAttemptCount;
        let sendType = this.state.sendType;
        if (sendType === 1) {
            if (emailAttemptCount === 3) {
                inSeconds = 120000;
            } else if (emailAttemptCount === 2) {
                inSeconds = 60000;
            } else {
                inSeconds = 30000;
            }
        } else {
            if (phoneAttemptCount === 3) {
                inSeconds = 120000;
            } else if (phoneAttemptCount === 2) {
                inSeconds = 60000;
            } else {
                inSeconds = 30000;
            }
        }
        return Date.now() + inSeconds;
    };

    handleBack() {
        this.state.nextForm("LogoutDone");
    }

    togglePassword(pswd) {
        var txtPass = document.getElementById(pswd);
        var iconPass = window.$(".toggle-password");
        if (txtPass.type === "password") {
            txtPass.type = "text";
            iconPass.removeClass("fa-eye");
            iconPass.addClass("fa-eye-slash");
        } else {
            txtPass.type = "password";
            iconPass.removeClass("fa-eye-slash");
            iconPass.addClass("fa-eye");
        }
    }

    handlePassword = (value) => {
        this.setState({ password: value });
    };

    validateFormData() {
        if (this.state.verificationCode === "" || this.state.verificationCode.length !== 6) {
            this.inputVerificationCode.current.focus();
        } else if (this.state.password === "" || this.state.password.length < 8) {
            this.inputPassword.current.focus();
        }
        return this.state.verificationCode === "" || this.state.verificationCode.length !== 6 || this.state.password === "" || this.state.password.length < 8 ? true : false;
    }

    handleOTPVerification = async () => {
        try {
            this.setState({ isOTPError: false, btnClicked: true, isSubmitted: true });
            this.props.showLoader(true);
            if (!this.validateFormData()) {
                if (this.state.verificationCode) {
                    let email = this.props.email;
                    let otp = this.state.verificationCode;
                    let newPassword = this.state.password;
                    let sid = this.state.sid;
                    let result = await bushnellAPI.resetPassword(this.state.sendType, email, otp, newPassword, sid);
                    if (result.errorCode === 0) {
                        this.state.nextForm("LogoutDone");
                        this.setState({ isOTPError: false });
                        commonService.showMessage(langService.t("UserAccount_PasswordChanged"));
                    } else {
                        this.setState({ isOTPError: true });
                        commonService.Log(result.status);
                    }
                }
            }
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        } catch (err) {
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    };

    handleClickedOk = async (captchaToken) => {
        try {
            window.$("#verificationPopupModal").modal("hide");
            await this.setState({ sendType: this.state.sendType === 1 ? 2 : 1 });
            if (this.state.sendType) {
                let to = this.state.email;
                await bushnellAPI.SendVerificationCode(to, this.state.sendType, captchaToken, this.state.captchaActionSwitchChannel)
                .then(async (response) => {
                    let jsonData = await response.json();
                    if (jsonData.gateWayErrorCode === 0) {
                        this.handleStartClick();
                        await this.setState({
                            sid: jsonData.sid,
                        });
                        if (this.state.sendType === 1) {
                            await this.setState({
                                emailAttemptCount: this.state.emailAttemptCount + 1,
                            });
                        } else {
                            await this.setState({
                                phoneAttemptCount: this.state.phoneAttemptCount + 1,
                            });
                        }
                    } else if (jsonData.gateWayErrorCode) {
                        commonService.Log(jsonData.gateWayErrorCode);
                        commonService.showMessage(langService.tErrorMsg(jsonData.gateWayMessage));
                        return false;
                    } else if (jsonData.errorCode) {
                        if (commonService.checkIpBlockingError(jsonData.errorCode)){
                            commonService.Log(`${jsonData.errorCode} - ${langService.tErrorMsg("OTP_IP_Blocking")} `);
                            commonService.showMessage(langService.tErrorMsg("OTP_IP_Blocking"));
                        } else {
                            commonService.Log(`${jsonData.errorCode} - ${langService.tErrorMsg("UserAccount_OTPSentFailed")} `);
                            commonService.showMessage(langService.tErrorMsg("UserAccount_OTPSentFailed"));
                        }
                        return false;
                    } else {
                        commonService.Log(jsonData);
                        commonService.showMessage(langService.tErrorMsg("UserAccount_OTPSentFailed"));
                        return false;
                    }
                });
            }
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        }
    };

    handleSwitchChannel = async () => {
        try {
          const configData = configService.getConfigData();
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(configData.Google_ReCaptcha_SiteKey, { action: this.state.captchaActionSwitchChannel })
            .then(token => {
                this.handleClickedOk(token);
            });
          });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    };

    handleIsCompleted = () => {
        this.forceUpdate();
    };

    isCompleted = () => {
        return !!(this.countdownApi && this.countdownApi.isCompleted());
    };

    setRef = (countdown) => {
        if (countdown) {
            this.countdownApi = countdown.getApi();
        }
    };

    rendererCounter = ({ hours, minutes, seconds, completed }) => {
        if (!completed) {
            // Render a countdown
            let rTime = langService.t("UserAccount_OTP_RemainingTime");
            if (minutes > 0) {
                rTime = rTime.replace("{minute}", minutes);
                rTime = rTime.replace("{second}", seconds);
            }
            else {
                rTime = rTime.replace("{minute} minute {second}", seconds);
            }
            return <span className="display_none">{rTime}</span>;
        } else {
            return <span> </span>;
        }
    };

    handleStartClick = () => {
        this.countdownApi && this.countdownApi.start();
        this.handleIsCompleted();
    };

    handleSaveVerification = async (captchaToken) => {
        try {
            this.setState({ btnClicked: true });
            this.props.showLoader(true);
            if (this.state.sendType) {
                let to = this.state.email;
                await bushnellAPI.SendVerificationCode(to, this.state.sendType, captchaToken, this.state.captchaActionResend)
                .then(async (response) => {
                    let jsonData = await response.json();
                    if (jsonData.gateWayErrorCode === 0) {
                        this.handleStartClick();
                        await this.setState({
                            sid: jsonData.sid,
                        });
                        if (this.state.sendType === 1) {
                            await this.setState({
                                emailAttemptCount: this.state.emailAttemptCount + 1,
                            });
                        } else {
                            await this.setState({
                                phoneAttemptCount: this.state.phoneAttemptCount + 1,
                            });
                        }
                        if (this.state.emailAttemptCount > 3 && this.state.phoneAttemptCount > 3) {
                            this.props.nextForm("contactSupport");
                        }
                    } else if (jsonData.gateWayErrorCode) {
                        commonService.Log(jsonData.gateWayErrorCode);
                        commonService.showMessage(langService.tErrorMsg(jsonData.gateWayMessage));
                        return false;
                    } else if (jsonData.errorCode) {
                        if (commonService.checkIpBlockingError(jsonData.errorCode)){
                            commonService.Log(`${jsonData.errorCode} - ${langService.tErrorMsg("OTP_IP_Blocking")} `);
                            commonService.showMessage(langService.tErrorMsg("OTP_IP_Blocking"));
                        } else {
                            commonService.Log(`${jsonData.errorCode} - ${langService.tErrorMsg("UserAccount_OTPSentFailed")} `);
                            commonService.showMessage(langService.tErrorMsg("UserAccount_OTPSentFailed"));
                        }
                        return false;
                    } else {
                        commonService.Log(jsonData);
                        commonService.showMessage(langService.tErrorMsg("UserAccount_OTPSentFailed"));
                        return false;
                    }
                });
            }
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        }
    };

    handleResend = async (event) => {
        try {
          event.preventDefault();
          const configData = configService.getConfigData();
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(configData.Google_ReCaptcha_SiteKey, { action: this.state.captchaActionResend })
            .then(token => {
                this.handleSaveVerification(token);
            });
          });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    };

    render() {
        return (
            <div>
                <div className="form-main">
                    <h1>{langService.t("UserAccount_EnterOTPAndPassword")}</h1>
                    <small>{langService.t("UserAccount_EnterOTP").replace("{sendType}", this.state.sendType === 1 ? "Email Address" : "Phone") }</small>
                    <form className="mt-4">
                        <div className="form-row">
                            <div className="form-group col-6">
                                <input
                                    type="text"
                                    name="otp_1"
                                    className="form-control"
                                    maxLength="6"
                                    onChange={(e) => this.setState({ verificationCode: e.target.value })}
                                    value={this.state.verificationCode}
                                    ref={this.inputVerificationCode}
                                />
                            </div>
                        </div>
                        {(this.state.isSubmitted && !this.state.verificationCode) || (this.state.isSubmitted && this.state.verificationCode.length !== 6) ? (
                            <div className="field-errors mt-10">{langService.t("UserAccount_NoOTP")}</div>
                        ) : this.state.isSubmitted && this.state.isOTPError ? (
                            <div className="field-errors mb-3 mt-10">{langService.t("UserAccount_InvalidOTP")}</div>
                        ) : (
                            ""
                        )}
                    </form>
                    <Countdown date={this.counterTimer} renderer={this.rendererCounter} ref={this.setRef} onComplete={this.handleIsCompleted} onStart={this.handleIsCompleted} />
                    <br></br>
                    {!this.isCompleted() ? (
                        <a disabled={true} href="# ">{langService.t("UserAccount_FailedOTP")}</a>
                    ) : (
                        <a onClick={this.handleResend} href="# ">
                            {langService.t("UserAccount_FailedOTP")}
                        </a>
                    )}
                    <div className="form-group mt-3 position-relative">
                        <label htmlFor="exampleInputEmail1">{langService.t("UserAccount_NewPassword")}</label>
                        <input
                            type="password"
                            name="pwd"
                            id="input-pwd"
                            className="form-control validate pr-4"
                            required
                            onChange={(e) => this.handlePassword(e.target.value)}
                            maxLength="50"
                            value={this.state.password}
                            placeholder={langService.t("UserAccount_EnterNewPassword")}
                            ref={this.inputPassword}
                        />
                        <span onClick={(e) => this.togglePassword("input-pwd")}>
                            <span className="fa fa-fw fa-eye field-icon toggle-password"></span>
                        </span>
                        {this.state.isSubmitted && this.state.password === "" ? (
                            <div className="field-errors mt10">{langService.t("UserAccount_NoNewPassword")}</div>
                        ) : this.state.isSubmitted && this.state.password.length < 8 ? (
                            <div className="field-errors mt10">{langService.t("UserAccount_InvalidLengthPassword")}</div>
                        ) : (
                            ""
                        )}
                    </div>
                    <button type="submit" className="btn btn-primary btn-block" onClick={this.handleOTPVerification}>
                        {langService.t("Btn_ResetPassword")}
                    </button>
                </div>

                {(this.state.emailAttemptCount > 3 || this.state.phoneAttemptCount > 3) && !(this.state.emailAttemptCount >= 3 && this.state.phoneAttemptCount >= 3) ? (
                    <SwitchChannel clickedOk={this.handleSwitchChannel} sendType={this.state.sendType} />
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default ForgotOTP;
