import React from "react";
import * as commonService from "../services/commonService.js";
import * as bushnellAPI from "../services/bushnellAPIService.js";
import * as sessionService from "../services/sessionService";
import * as langService from '../services/langService';

class UpdateStep1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: "",
        };
    }

    componentDidMount() {
        try {
            let profile = bushnellAPI.getUserProfile();
            let uName = profile.userProfile.firstName;
            if (uName) {
                uName += " " + profile.userProfile.lastName;
            } else {
                uName = profile.userProfile.lastName;
            }
            this.setState({ userName: uName });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    }

    handleNext() {
        this.props.nextForm("UpdateStep1");
    }

    render() {
        return (
            <div className="container-fluid mt-4">
                <div className="co-bx form-cstm">
                    <div className="content-banner text-center">
                        <div className="logo mt-2 mb-3">
                            <img alt="logo" src={process.env.PUBLIC_URL + "/images/blogo.svg"} />
                        </div>
                        <h1>{langService.t("UpdateProcess_WelcomeUser").replace("{userName}", this.state.userName)}</h1>
                        <h2>{langService.t("UpdateProcess_BGUpdateManager")}</h2>
                        <h5>{langService.t("UpdateProcess_UseToolToUpdate")}</h5>
                        <p>
                            {langService.t("UpdateProcess_ToolWorkOnBGDevices")} (
                            <a data-toggle="modal" href="#supporteddevices">
                                {langService.t("UpdateProcess_ViewSupportedDevices")}
                            </a>
                            ). <br />
                            {langService.t("UpdateProcess_NoSupportAndroidTabChromeOS")}
                        </p>
                        <div className="button-arrow">
                            <ul>
                                <li className="arrow">
                                    {langService.t("UpdateProcess_ClickToView")}
                                    <br />
                                    {langService.t("UpdateProcess_RegisteredDevices")}
                                </li>
                                <li>
                                    <a data-toggle="modal" href="#registereddevices">
                                        {langService.t("UpdateProcess_RegisteredDevices")}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <h3>{langService.t("UpdateProcess_UpdateThreeSteps")}</h3>
                    </div>
                    <div className="steps">
                        <ul>
                            <li>
                                <span className="pwr-icon">{langService.t("UpdateProcess_TurnOFFDevice")}</span>
                            </li>
                            <li>
                                <span className="usb-icon">
                                    {langService.t("UpdateProcess_ConnectDevice")}
                                    <br /> {langService.t("UpdateProcess_viaUSB")}
                                </span>
                            </li>
                            <li>
                                <span className="update-icon">{langService.t("UpdateProcess_UpdateLatest")}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="button-wrap text-center mb-2">
                        <button type="button" className="btn btn-primary" onClick={() => this.handleNext()}>
                            {langService.t("UpdateProcess_GetStarted")}
                        </button>
                    </div>
                </div>
                <SupportedDevices />
                <RegisteredDevices nextForm={this.props.nextForm} />
            </div>
        );
    }
}

class SupportedDevices extends React.Component {
    render() {
        return (
            <div className="modal custom-pop fade" id="supporteddevices" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered model-450" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{langService.t("UpdateProcess_SupportedDevices")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <img alt="close_icon" src={process.env.PUBLIC_URL + "/images/close_icon.svg"} />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body p-4">
                            <ul className="list-unstyled list_pro">
                                <li className="media">
                                    <div className="img-holder mr-3">
                                        <img alt="BushnellPhantom2" src={process.env.PUBLIC_URL + "/images/Bushnell Phantom 2.png"} />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h1>Bushnell Phantom 2</h1>
                                        <h2>GPS Device</h2>
                                    </div>
                                </li>
                                <li className="media">
                                    <div className="img-holder mr-3">
                                        <img alt="IonEdge" src={process.env.PUBLIC_URL + "images/Ion_Edge.png"} />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h1>Bushnell Ion Edge</h1>
                                        <h2>GPS Device</h2>
                                    </div>
                                </li>
                                <li className="media">
                                    <div className="img-holder mr-3">
                                        <img alt="BushnellPhantom2Slope" src={process.env.PUBLIC_URL + "images/phantom2_Slope.png"} />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h1>Bushnell Phantom 2 Slope</h1>
                                        <h2>GPS Device</h2>
                                    </div>
                                </li>
                                <li className="media">
                                    <div className="img-holder mr-3">
                                        <img alt="BushnellIonElite" src={process.env.PUBLIC_URL + "images/IonElite.png"} />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h1>Bushnell Ion Elite</h1>
                                        <h2>GPS Device</h2>
                                    </div>
                                </li>
                                <li className="media">
                                    <div className="img-holder mr-3">
                                        <img alt="BushnellPhantom3" src={process.env.PUBLIC_URL + "/images/Bushnell_Phantom_3.png"} />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h1>Bushnell Phantom 3</h1>
                                        <h2>GPS Device</h2>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class RegisteredDevices extends React.Component {
    handleNext() {
        window.$("#registereddevices").modal("hide");
        this.props.nextForm("UpdateStep1");
    }
    render() {
        let devices = sessionService.getUserProfile() ? sessionService.getUserProfile().userProfile.devices : null;
        return (
            <div className="modal custom-pop fade" id="registereddevices" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered model-450" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{langService.t("UpdateProcess_RegisteredDevices")}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <img alt="close" src={process.env.PUBLIC_URL + "images/close_icon.svg"} />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body p-4">
                            <div className="scroll-3pr">
                                <DevicesList dataList={devices} />
                            </div>
                            <div className="btn-grp d-flex justify-content-center">
                                <button type="button" className="btn btn-primary w-200" onClick={() => this.handleNext()}>
                                    {langService.t("UpdateProcess_AddDevice")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class DevicesList extends React.Component {
    getImagePath(item) {
        return commonService.getDeviceImagePath(item.imageIdentifier, item.sku);
    }
    render() {
        return (
            <ul className="list-unstyled list_pro1">
                {this.props.dataList
                    ? this.props.dataList.map((item) => {
                          return (
                              <li className="media" key={item.userDeviceID}>
                                  <div className="img-holder mr-3">
                                      <img alt="device" src={process.env.PUBLIC_URL + this.getImagePath(item)} />
                                  </div>
                                  <div className="media-body align-self-center">
                                      <h1>{item.name}</h1>
                                      {item.serialNo ? <h2>SN #: {item.serialNo}</h2> : ""}
                                      {item.sku ? <h3>SKU #: {item.sku}</h3> : ""}
                                  </div>
                              </li>
                          );
                      })
                    : ""}
            </ul>
        );
    }
}

export default UpdateStep1;
