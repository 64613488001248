import React from "react";
import * as commonService from "../services/commonService.js";
import * as bushnellAPI from "../services/bushnellAPIService.js";
import * as sessionService from "../services/sessionService.js";
import * as langService from '../services/langService';
import LogoutPopup from "./LogoutPopup";

class NavTop extends React.Component {
    //_isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            nextForm: props.nextForm,
            userName: "",
            clickedLogout: false,
        };
        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount() {
        try {
            let profile = bushnellAPI.getUserProfile();
            let uName = profile.userProfile.firstName;
            if (uName) {
                uName += " " + profile.userProfile.lastName;
            } else {
                uName = profile.userProfile.lastName;
            }
            this.setState({ userName: uName });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    }

    handleLogout(data) {
        if (data) {
            sessionService.logout();
            this.state.nextForm("LogoutDone");
            this.setState({ clickedLogout: false });
            window.$("#logoutPopupModal").modal("hide");
        }
    }

    handleClickedLogout = (data) => {
        if (data) {
            this.setState({ clickedLogout: true });
            window.$("#logoutPopupModal").modal("show");
        }
    };

    render() {
        return (
            <div>
                <nav className="sb-topnav navbar navbar-expand">
                    <a className="navbar-brand dsp-md" href={process.env.PUBLIC_URL + "/"}>
                        <img alt="logo" src={process.env.PUBLIC_URL + "/images/bushnell_logo_blk.svg"} />
                    </a>
                    <a className="navbar-brand dsp-sm" href={process.env.PUBLIC_URL + "/"}>
                        <img alt="logo" src={process.env.PUBLIC_URL + "/images/bushnell_logo_xl.svg"} />
                    </a>
                    {/* Navbar Search */}
                    <form className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
                        <div className="input-group"></div>
                    </form>
                    {/* Navbar Search */}
                    <ul className="navbar-nav ml-auto ml-md-0">
                        <li className="nav-item user-det">
                            <a href="# " data-toggle="modal">
                                <label>{this.state.userName}</label>
                            </a>
                        </li>
                        <div className="topbar-divider"></div>
                        <li className="nav-item" onClick={this.handleClickedLogout}>
                            <a className="nav-link logout" href="# ">
                                <img alt="logout" src={process.env.PUBLIC_URL + "/images/logout.svg"} />
                            </a>
                        </li>
                    </ul>
                </nav>
                {this.state.clickedLogout ? <LogoutPopup logoutClicked={this.handleLogout} /> : ""}
            </div>
        );
    }
}

export default NavTop;
