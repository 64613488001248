import React from 'react';

class LoaderDisplay extends React.Component {
    render() {
        return (
            <div id="loaderDisplay" style={{display:'none'}}>
                <div className="loader-screen">
                    <div className="loadingio-spinner-pulse-yd5dl03y9fi">
                        <div className="ldio-v55tnqe6ie">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoaderDisplay;
