import React from "react";
import * as langService from '../services/langService';
import * as bushnellAPI from "../services/bushnellAPIService";
import * as commonService from "../services/commonService.js";

class Forgot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextForm: props.nextForm,
            email: "",
            isEmailExists: true,
            isSubmitted: false,
        };
        this.inputEmail = React.createRef();
    }

    componentDidMount() {
        this.inputEmail.current.focus();
    }

    handleBack() {
        this.state.nextForm("LogoutDone");
    }

    handleChangeEmail(value) {
        this.setState({
            email: value,
        });
    }

    validateFormData() {
        if (this.state.email === "" || this.state.email === undefined) {
            this.inputEmail.current.focus();
        }
        return this.state.email === "" || this.state.email === undefined ? true : false;
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        try {
            this.setState({ btnClicked: true, isSubmitted: true });
            if (!this.validateFormData() && this.validateEmailAddress(this.state.email)) {
                this.props.setForgotEmail(this.state.email);
                this.props.showLoader(true);
                let validateEmail = await bushnellAPI.ValidateEmailAndPhone(this.state.email, "", "", 1);
                if (validateEmail.errorCode === 409) {
                    this.props.showLoader(false);
                    this.props.nextForm("ForgotOptions");
                    this.setState({ isEmailExists: true });
                } else if (validateEmail.errorCode === 412) {
                    this.props.showLoader(false);
                    commonService.Log(validateEmail.errorCode);
                    commonService.showMessage(validateEmail.status);
                } else {
                    this.setState({ isEmailExists: false });
                }
            }
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        } catch (err) {
            this.props.showLoader(false);
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    };

    validateEmailAddress = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <div className="form-main">
                <h1>{langService.t("UserAccount_ForgotPassword")}</h1>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">{langService.t("Txt_EmailAddress")}</label>
                        <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={this.state.email}
                            onChange={(e) => this.handleChangeEmail(e.target.value)}
                            maxLength="200"
                            ref={this.inputEmail}
                        />
                        {this.state.isSubmitted && this.state.email === "" ? (
                            <span className="field-errors">{langService.t("UserAccount_NoEmail")}</span>
                        ) : this.state.isSubmitted && !this.validateEmailAddress(this.state.email) ? (
                            <span className="field-errors">{langService.t("UserAccount_InvalidEmail")}</span>
                        ) : this.state.isSubmitted && !this.state.isEmailExists ? (
                            <span className="field-errors">{langService.t("UserAccount_MissingEmail")}</span>
                        ) : (
                            ""
                        )}
                    </div>
                    <button type="submit" className="btn btn-primary btn-block">
                        {langService.t("Btn_Submit")}
                    </button>
                    <button type="button" className="btn btn-secondary btn-block" onClick={() => this.handleBack()}>
                        {langService.t("Btn_BackToLogin")}
                    </button>
                </form>
            </div>
        );
    }
}

export default Forgot;
