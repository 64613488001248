import * as commonService from "./commonService.js";
import * as sessionService from "./sessionService.js";
import * as langService from '../services/langService';
import * as configDataService from "../services/configDataService";
import * as downloadService from "../services/downloadService";
import * as azureBlobService from "../services/azureBlobService";

const URL_UserLogin = "/User/Login";
const URL_UserProfile = "/User/UserProfile";
const URL_UpdateUserAddress = "/User/UpdateUserAddress";
const URL_ValidateEmailAndPhone = "/User/ValidateEmailAndPhone";
const URL_CreateUser = "/User/CreateUser";
const URL_SendVerificationCode = "/User/SendVerificationCode";
const URL_RefreshToken = "/User/RefreshToken";
const URL_ResetPassword = "/User/ResetPasswordFromUSBTool";
const URL_GetVerifiedChannels = "/User/GetVerifiedChannels";
//const URL_DecryptDeviceInfo = "/User/DecryptDeviceInfo";
//const URL_RegisterUserBushnellDevice = "/User/RegisterUserBushnellDevice";
const URL_ValidatePhoneNumber = "/User/ValidatePhoneNumber";
const URL_RegisterUSBToolBushnellDevice = "/User/RegisterUSBToolBushnellDevice";
const URL_AddCourseRequest = "/User/AddCourseRequest";
const URL_GetSASTokenForChromeTool = "/User/GetSASTokenForChromeTool";

const Data_AppVersion = "1.0";
const Data_DeviceType = "101";
const Data_InstallId = 1;

export function getBaseURL() {
    return configDataService.getConfigData().URL_BushnellAPI;
}

function getHeaders(APIKey) {
    let APIHeaders;
    if (APIKey) {
        APIHeaders = {
            accept: "application/json",
            "Content-Type": "application/json",
            appVersion: Data_AppVersion,
            deviceType: Data_DeviceType,
            Authorization: "Bearer " + APIKey,
        };
    } else {
        APIHeaders = {
            accept: "application/json",
            "Content-Type": "application/json",
            appVersion: Data_AppVersion,
            deviceType: Data_DeviceType,
        };
    }
    return APIHeaders;
}

async function downloadDataBase(dataURL, isPost, postData) {
    let APIKey = sessionService.getValidAccessToken();
    let APIHeaders = getHeaders(APIKey);
    let resultObject = await downloadService.downloadData_Bushnell(dataURL, APIHeaders, isPost, postData);
    return resultObject;
}

async function downloadData(dataURL, isPost, postData) {
    commonService.Log("needTokenRefresh : " + sessionService.needTokenRefresh());
    if (sessionService.needTokenRefresh()) {
        commonService.Log("Need token refresh");
        let token = sessionService.getToken();
        let postData = {
            accessToken: token.accessToken,
            refreshToken: token.refreshToken,
        };
        let tokenResultObject = await downloadDataBase(getBaseURL() + URL_RefreshToken, true, postData);
        if (tokenResultObject.status === 200) {
            token = await tokenResultObject.json();
            sessionService.setToken(token);
            commonService.Log("New token set");
        } else {
            commonService.Log("Failed to get new token");
            throw new Error(langService.t("BAPIService_ServiceLoginFailed"));
        }
    }
    let resultObject = await downloadDataBase(dataURL, isPost, postData);
    return resultObject;
}

export async function downloadData_GET(dataURL) {
    let resultObject = await downloadData(dataURL, false, null);
    return resultObject;
}

export async function downloadData_POST(dataURL, postData) {
    let resultObject = await downloadData(dataURL, true, postData);
    return resultObject;
}

async function downloadUserProfile() {
    let userData = null;
    let resultObject = await downloadData_GET(getBaseURL() + URL_UserProfile);
    if (resultObject.status === 200) {
        userData = await resultObject.json();
    }
    return { status: resultObject.status, profile: userData };
}

export async function Login(email, password) {
    let postData = {
        email: email,
        password: password,
        installId: Data_InstallId,
    };
    let returnCode = 0;
    let returnMsg = "";
    let resultObject = await downloadData_POST(getBaseURL() + URL_UserLogin, postData);
    if (resultObject.status === 200) {
        let userData = await resultObject.json();
        sessionService.setToken(userData);
        resultObject = await downloadUserProfile();
        if (resultObject.status === 200) {
            sessionService.setUserProfile(resultObject.profile);
        } else {
            sessionService.logout();
            returnCode = resultObject.status;
            returnMsg = langService.t("BAPIService_InvalidCredentials");
        }
    } else if (resultObject.status === 401) {
        returnCode = resultObject.status;
        returnMsg = langService.t("BAPIService_InvalidCredentials");
    } else if (resultObject.status === 412) {
        returnCode = resultObject.status;
        returnMsg = langService.t("BAPIService_AccountDeactivated");
    } else {
        returnCode = resultObject.status;
        returnMsg = langService.t("BAPIService_InvalidCredentials");
    }
    return { errorCode: returnCode, status: returnMsg };
}

export async function Logout() {
    sessionService.logout();
}

export async function GetVerifiedChannels(email) {
    let postData = { email: email };
    let data = await downloadData_POST(getBaseURL() + URL_GetVerifiedChannels, postData);
    return data;
}

export async function SendVerificationCode(to, channel, captchaResponse, captchaActionPage) {
    let postData = { 
        to: to,
        channel: channel,
        CaptchaResponse: captchaResponse,
        CaptchaActionPage: captchaActionPage
    };
    let data = await downloadData_POST(getBaseURL() + URL_SendVerificationCode, postData);
    return data;
}

export async function CreateUser(firstName, lastName, email, countryCode, phoneNumber, password, verificationCode, sendType, language, sid) {
    let postData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        countryCode: countryCode,
        phoneNumber: phoneNumber,
        password: password,
        LanguageCode: language,
        Sid: sid
    };

    if (sendType === 2) {
        postData.isPhoneVerified = true;
        postData.isEmailVerified = false;
    } else {
        postData.isPhoneVerified = false;
        postData.isEmailVerified = true;
    }
    if (verificationCode) {
        postData.verificationCode = verificationCode;
    }
    let response = await downloadData_POST(getBaseURL() + URL_CreateUser, postData);
    if (response.status === 200) {
        sessionService.setToken(await response.json());
        return true;
    }
    else if (response.status === 401) {
        return { errorCode: 401, status: langService.t("BAPIService_InvalidVerificationCode") };
    }
    else {
        return {
            errorCode: 500,
            status: langService.t("BAPIService_UnexpectedError"),
        };
    }
}

export async function ValidateEmailAndPhone(email, countryCode, phoneNumber, type) {
    let postData = {
        email: email,
        countryCode: countryCode,
        phoneNumber: phoneNumber,
        type: type,
    };
    let response = await downloadData_POST(getBaseURL() + URL_ValidateEmailAndPhone, postData);
    if (response.status === 200) {
        return await response.json();
    }
    else if (response.status === 409) {
        return { errorCode: 409, status: langService.t("BAPIService_EmailExists") };
    }
    else if (response.status === 412) {
        return {
            errorCode: 412,
            status: langService.t("BAPIService_AccountDeactivated"),
        };
    }
    else if (response.status === 504) {
        return {
            errorCode: response.status,
            status: langService.t("BAPIService_EmailPhone"),
        };
    }
    else if (response.status === 400) {
        return {
            errorCode: 400,
            status: langService.t("BAPIService_ErrorValidatePhone"),
        };
    }
    else {
        return {
            errorCode: 500,
            status: langService.t("BAPIService_UnexpectedError"),
        };
    }
}

export async function ValidatePhoneNumber(phoneNumber) {
    let postData = {
        phoneNumber,
    };
    let response = await downloadData_POST(getBaseURL() + URL_ValidatePhoneNumber, postData);
    if (response.status === 200) {
        return await response.json();
    } else {
        return {
            errorCode: response.status,
            status: langService.t("BAPIService_ErrorValidatePhone"),
        };
    }
}

// export async function DecryptDeviceInfo(deviceInfo) {
//     let postData = {
//         deviceInfo,
//     };
//     let response = await downloadData_POST(getBaseURL() + URL_DecryptDeviceInfo, postData);
//     if (response.status === 200) {
//         return await response.json();
//     } else {
//         return { errorCode: 409, status: langService.t("BAPIService_ErrorDecryptDeviceInfo") };
//     }
// }

export async function RegisterUSBToolBushnellDeviceGET(deviceInfo) {
    deviceInfo = encodeURIComponent(deviceInfo);
    let response = await downloadData_GET(getBaseURL() + URL_RegisterUSBToolBushnellDevice + "?DeviceInfo=" + deviceInfo);
    if (response.status === 200) {
        return await response.json();
    } else {
        return { errorCode: 409, status: langService.t("BAPIService_ErrorDecryptDeviceInfo") };
    }
}

export async function RegisterUSBToolBushnellDevicePOST(deviceInfo, deviceFirmwareVersion, courseDBVersion) {
    let postData = {
        deviceInfo,
        deviceFirmwareVersion,
        courseDBVersion,
    };
    let response = await downloadData_POST(getBaseURL() + URL_RegisterUSBToolBushnellDevice, postData);
    if (response.status === 200) {
        let profileResponse = await downloadUserProfile();
        sessionService.setUserProfile(profileResponse.profile);
        return await response.json();
    } else {
        return {
            errorCode: 409,
            status: langService.t("BAPIService_ErrorRegisterDevice"),
        };
    }
}

export async function isRegisteredDevice(macId) {
    let isSame = false;
    let profile = sessionService.getUserProfile();
    if (profile) {
        for (let device of profile.userProfile.devices) {
            if (device.macId === macId) {
                isSame = true;
                break;
            }
        }
    } else {
        throw new Error(langService.t("BAPIService_UserProfileNotFound"));
    }
    return isSame;
}

export async function canRegisterDevice(macId, sku) {
    let canRegister = true;
    if (isRegisteredDevice(macId)) {
        canRegister = false;
    }
    else {
        // Code to stop registration if a regeistered phantom/IonEdge device exist. Ask Jomy JK for more details
        let profile = sessionService.getUserProfile();
        let supportedDevices = commonService.getSupportedDevices();
        for (let selectedSKUList of supportedDevices) {
            if (selectedSKUList.SKUs.includes(sku)) {
                for (let device of profile.userProfile.devices) {
                    if (selectedSKUList.SKUs.includes(device.sku)) {
                        canRegister = false;
                        break;
                    }
                }
            }
        }
    }
    return canRegister;
}

export function validateSKU(deviceSKU) {
    let isValid = false;
    let supportedDevices = commonService.getSupportedDevices();
    for (let selectedSKUList of supportedDevices) {
        if (selectedSKUList.SKUs.includes(deviceSKU)) {
            isValid = true;
            break;
        }
    }
    if (!isValid) {
        throw new Error(langService.t("BAPIService_DeviceNotSupported"));
    }
}

export function getUserProfile() {
    return sessionService.getUserProfile();
}

export async function updateUserAddress(addressData) {
    let returnCode = 0;
    let returnMsg = "";
    let postData = {
        StreetName: addressData.StreetName,
        City: addressData.City,
        State: addressData.State,
        Country: addressData.Country,
        Zip: addressData.Zip,
    };
    let response = await downloadData_POST(getBaseURL() + URL_UpdateUserAddress, postData);
    if (response.status !== 200) {
        returnCode = response.status;
        returnMsg = langService.t("BAPIService_AddressUpdateFailed");
    }
    return { errorCode: returnCode, status: returnMsg };
}

export async function resetPassword(sendType, email, OTP, newPassword, sid) {
    let returnCode = 0;
    let returnMsg = "";
    let postData = {
        Email: email,
        Password: newPassword,
        VerificationCode: OTP,
        ChannelType: sendType,
        Sid: sid,
    };
    let response = await downloadData_POST(getBaseURL() + URL_ResetPassword, postData);
    if (response.status !== 200) {
        returnCode = response.status;
        returnMsg = langService.t("BAPIService_PasswordResetFailed");
    }
    return { errorCode: returnCode, status: returnMsg };
}

export async function addCourseRequest(courseData) {
    let returnCode = 0;
    let returnMsg = "";
    let postData = {
        Email: courseData.Email,
        CourseName: courseData.CourseName,
        Country: courseData.Country,
        State: courseData.State,
        City: courseData.City,
        AdditionalNotes: courseData.AdditionalNotes,
        ScoreCardPhotos: courseData.ScoreCardPhotos,
        CaptchaResponse: courseData.CaptchaResponse,
        CaptchaActionPage: courseData.CaptchaActionPage
    };
    let response = await downloadData_POST(getBaseURL() + URL_AddCourseRequest, postData);
    if (response.status !== 200) {
        returnCode = response.status;
        returnMsg = langService.t("BAPIService_CourseRequestFailed");
    }
    return { errorCode: returnCode, status: returnMsg };
}

async function GetFileUploadToken() {
    let response = await downloadData_GET(getBaseURL() + URL_GetSASTokenForChromeTool);
    if (response.status === 200) {
        return await response.json();
    } else {
        return { errorCode: response.status, status: langService.t("BAPIService_FileTokenRequestFailed") };
    }
}

export async function UploadScoreCardImage(file1, file2, fileName1, fileName2) {
    let returnCode = 0;
    let returnMsg = "";
    let isStateValid = true;
    let token;
    let response;
    if (file1 !== null || file2 !== null) {
        response = await GetFileUploadToken();
        if (response.errorCode) {
            isStateValid = false;
            returnCode = response.status;
            returnMsg = langService.t("BAPIService_FileTokenRequestFailed");
            commonService.Log("Failed to get file upload token");
        } else {
            token = response.token;
            commonService.Log("Adding file upload token");
        }
        let configData = configDataService.getConfigData();
        let urlSAS = configData.URL_Blob_Scorecard.replace("{SASToken}", token);

        if (isStateValid && file1 !== null) {
            commonService.Log("Uploading File 1");
            try {
                response = await azureBlobService.uploadFileToBlob(urlSAS, "scorecardimages", file1, fileName1);
            }
            catch {
                isStateValid = false;
                returnCode = response.status;
                returnMsg = langService.t("BAPIService_FileUploadFailed");
                commonService.Log("Failed to upload File 1");
            }
        }
        if (isStateValid && file2 !== null) {
            commonService.Log("Uploading File 2");
            try {
                response = await azureBlobService.uploadFileToBlob(urlSAS, "scorecardimages", file2, fileName2);
            }
            catch {
                isStateValid = false;
                returnCode = response.status;
                returnMsg = langService.t("BAPIService_FileUploadFailed");
                commonService.Log("Failed to upload File 2");
            }
        }
    }
    return { errorCode: returnCode, status: returnMsg };
}
