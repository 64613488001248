import * as commonService from "./commonService";
import * as langService from '../services/langService';
import * as configDataService from "../services/configDataService";
import * as downloadService from "../services/downloadService";

const Data_HighEnd = "hedata";
const Data_LowEnd = "ledata";

export async function downloadData(url) {
    return await downloadService.downloadData(url);
}

export async function downloadCourseVersionPackageData(localCourseVersion, isHighEnd) {
    commonService.Log("Downloading course package data for " + localCourseVersion);
    localCourseVersion = encodeURI(localCourseVersion);
    let configData = configDataService.getConfigData();
    let url;
    if (isHighEnd) {
        url = configData.URL_CoursePackage.replace("{data}", Data_HighEnd).replace("{version}", localCourseVersion);
    } else {
        url = configData.URL_CoursePackage.replace("{data}", Data_LowEnd).replace("{version}", localCourseVersion);
    }
    let fileObject = await downloadService.downloadData_AWS(url, configData.AWS_APIKey);
    if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    let data = await fileObject.text();
    return data;
}

export async function downloadCourseData(fileId, folderId, isHighEnd) {
    commonService.Log("Downloading course data file " + fileId);
    let configData = configDataService.getConfigData();
    let url;
    if (isHighEnd) {
        url = configData.URL_CourseDataFile.replace("{data}", Data_HighEnd).replace("{fileId}", fileId).replace("{folderId}", folderId);
    } else {
        url = configData.URL_CourseDataFile.replace("{data}", Data_LowEnd).replace("{fileId}", fileId).replace("{folderId}", folderId);
    }
    let fileObject = await downloadService.downloadData_AWS(url, configData.AWS_APIKey);
    if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    let data = await fileObject.arrayBuffer();
    return data;
}

export async function downloadCourseImage(fileId, folderId) {
    commonService.Log("Downloading course Image file " + fileId);
    const isTar = true;
    let configData = configDataService.getConfigData();
    let url = configData.URL_CourseImageFileHighEnd.replace("{fileId}", fileId).replace("{folderId}", folderId);
    let fileObject = await downloadService.downloadData_AWS(url, configData.AWS_APIKey, isTar);
    if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    let data = await fileObject.arrayBuffer();
    return data;
}

export async function downloadFileReboot(deviceName, isSlope) {
    let data = null;
    let rebootURL;
    let fileObject;
    commonService.Log("Downloading firmware reboot file for " + deviceName);
    let configData = configDataService.getConfigData();
    if (isSlope) {
        rebootURL = configData.FirmwareURL.FotaSlope_Reboot;
    } else {
        rebootURL = configData.FirmwareURL.Fota_Reboot;
    }
    rebootURL = rebootURL.replace("{deviceName}", deviceName.toLowerCase());
    fileObject = await downloadService.downloadData(rebootURL);
    //if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    if (fileObject.status === 200) {
        data = await fileObject.text();
    }
    return data;
}

export async function downloadFileImage(deviceName, isSlope) {
    let firmwareURL;
    let fileObject;
    commonService.Log("Downloading firmware image file for " + deviceName);
    let configData = configDataService.getConfigData();
    if (isSlope) {
        firmwareURL = configData.FirmwareURL.FotaSlope_Image;
    } else {
        firmwareURL = configData.FirmwareURL.Fota_Image;
    }
    firmwareURL = firmwareURL.replace("{deviceName}", deviceName.toLowerCase());
    fileObject = await downloadService.downloadData(firmwareURL);
    if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    let data = await fileObject.arrayBuffer();
    return data;
}

export async function downloadCoursePipeBugFixData() {
    commonService.Log("Downloading pipe bug fix data file");
    let configData = configDataService.getConfigData();
    let URL_CourseData = configData.URL_CourseData_PipeBugFix;
    let fileObject = await downloadService.downloadData(URL_CourseData);
    if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    let data = await fileObject.text();
    return data;
};

export async function downloadFirmwareVersions() {
    commonService.Log("Downloading expired firmware versions file");
    let configData = configDataService.getConfigData();
    let URL_FirmwareVersions = configData.FirmwareURL.Firmware_Versions;
    let fileObject = await downloadService.downloadData(URL_FirmwareVersions);
    if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    let data = await fileObject.text();
    return data;
}

export async function downloadFirmwareImageVersionsFile(deviceName) {
    commonService.Log("Downloading firmware image versions file");
    let configData = configDataService.getConfigData();
    let URL_FirmwareImageVersions = configData.FirmwareURL.FotaImage_Versions;
    URL_FirmwareImageVersions = URL_FirmwareImageVersions.replace("{deviceName}", deviceName.toLowerCase());
    let fileObject = await downloadService.downloadData(URL_FirmwareImageVersions);
    if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    let data = await fileObject.json();
    return data;
}

export async function downloadFirmwareImageFile(pathPart) {
    commonService.Log("Downloading firmware image file");
    let configData = configDataService.getConfigData();
    let URL_FirmwareImage = configData.FirmwareURL.FotaImage_Path;
    URL_FirmwareImage = URL_FirmwareImage.replace("{path}", pathPart);
    let fileObject = await downloadService.downloadData(URL_FirmwareImage);
    if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    let data = await fileObject.arrayBuffer();
    return data;
}

export async function downloadFirmwareRebootFile(pathPart) {
    commonService.Log("Downloading firmware reboot file");
    let configData = configDataService.getConfigData();
    let URL_FirmwareReboot = configData.FirmwareURL.FotaReboot_Path;
    URL_FirmwareReboot = URL_FirmwareReboot.replace("{path}", pathPart);
    let fileObject = await downloadService.downloadData(URL_FirmwareReboot);
    if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    let data = await fileObject.text();
    return data;
}

export async function downloadFontFile() {
    commonService.Log("Downloading Font file");
    const configData = configDataService.getConfigData();
    const url = configData.FirmwareURL.Font_File;
    const fileObject = await downloadService.downloadData(url);
    if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    let data = await fileObject.arrayBuffer();
    return data;
}
