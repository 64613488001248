import React, { createRef } from 'react';
import * as langService from '../../services/langService';
import * as commonService from "../../services/commonService.js";
import * as configService from "../../services/configDataService";
import * as downloadService from "../../services/downloadService";
import * as bushnellAPI from "../../services/bushnellAPIService";
import SelectList from '../selectList/SelectList';
import Alerts from '../requestCourse/alerts';
import PageLoader from '../Loader/Loader';
import { Link, Redirect } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

class RequestForm extends React.Component {
    constructor() {
        super();

        this.emailRef = createRef();
        this.courseNameRef = createRef();
        this.courseCityRef = createRef();
        this.courseNoteRef = createRef();
        this.file1UploadRef = createRef();
        this.file2UploadRef = createRef();
        this.captchaRef = createRef();

        this.state = {
            countryData: null,
            stateData: null,
            country: '',
            stateName: '',
            courseName: '',
            city: '',
            notes: '',
            alertBox: false,
            alertType: '',
            alertMessage: '',
            email: '',
            emailErrorMsg: '',
            emptyCountry: false,
            emptyState: false,
            emptyCourseName: false,
            emptyCity: false,
            emptyNotes: false,
            selectedFile1: null,
            selectedFile2: null,
            pageLoader: true,
            captcha: '',
            isValidCaptcha: false,
            inValidCaptcha: false,
            successRequest: false,
            captchaAction: 'RequestCourse',
        }

        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);

    }

    componentDidMount = async () => {
        try {
            this.setState({ pageLoader: false });
            commonService.loadRecaptchaScript();
            await this.storeCountryData();
        } catch (err) {
            commonService.showMessage("Error: " + err.message);
        }
    };

    handleChangeCountry(value) {
        this.setState({ stateName: "" });
        this.setState({ country: value });
    }

    handleChangeState(value) {
        this.setState({ state: value, stateName: value });
    }

    changeInput = (event) => {

        if (event.target.name === 'courseName') {
            this.setState({ courseName: event.target.value, emptyCourseName: false });
        }
        else if (event.target.name === 'city') {
            this.setState({ city: event.target.value, emptyCity: false });
        }
        else if (event.target.name === 'notes') {
            this.setState({ notes: event.target.value, emptyNotes: false });
        }
        else if (event.target.name === 'email') {
            this.setState({ email: event.target.value, emailErrorMsg: '' });
        }

    }

    onBrowseClick = event => {
        if (this.state.selectedFile1 === null) {
            this.file1UploadRef.current.click();
        } else if (this.state.selectedFile2 === null) {
            this.file2UploadRef.current.click();
        }
    }

    isValidFile(file) {
        let validFlag = true;
        if (!file) {
            validFlag = false;
        } else if (file.size > (5 * 1024 * 1024)) {
            // If size > 5 MB
            validFlag = false;
        } else if (!file.type.match(/.(jpg|jpeg|png)$/i)) {
            validFlag = false;
        }
        return validFlag;
    }

    onFile1Change = event => {
        if (this.isValidFile(event.target.files[0])) {
            this.setState({ selectedFile1: event.target.files[0] });
        }
    };

    onFile2Change = event => {
        if (this.isValidFile(event.target.files[0])) {
            this.setState({ selectedFile2: event.target.files[0] });
        }
    };

    onRemoveFile1 = event => {
        this.setState({ selectedFile1: null });
    };

    onRemoveFile2 = event => {
        this.setState({ selectedFile2: null });
    };


    downloadDataFile = async (dataURL) => {
        let fileObject = await downloadService.downloadData(dataURL);
        if (fileObject.status !== 200) throw new Error(langService.t("UserAccount_FileRequestFailed"));
        return fileObject.json();
    };

    downloadData_Country = async () => {
        let URL_Country = "/testData/data/country.json";
        let countryData = await this.downloadDataFile(URL_Country);
        return countryData;
    };

    downloadData_State = async () => {
        let URL_State = "/testData/data/state.json";
        let stateData = await this.downloadDataFile(URL_State);
        return stateData;
    };

    storeCountryData = async () => {

        let data = this.state.countryData;
        if (!data) {
            let configData = configService.getConfigData();
            let URL_Country = '../' + configData.LocalURL.CountryList;
            let URL_State = '../' + configData.LocalURL.StateList;

            let cData = await this.downloadDataFile(URL_Country);
            let sData = await this.downloadDataFile(URL_State);
            this.setState({
                countryData: cData,
                stateData: sData
            });
        }
    };

    validateData = () => {
        let success = true;
        this.clearFormValidation();
        let focus = 0;

        if ((this.state.email === null) || (this.state.email === "")) {
            this.setState({ emailErrorMsg: langService.t('UserAccount_NoEmail') });
            this.emailRef.current.focus();
            focus = 1;
            success = false;
        }
        else {
            let validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
            if (!validEmail.test(this.state.email)) {
                this.setState({ emailErrorMsg: langService.t('UserAccount_InvalidEmail') });
                this.emailRef.current.focus();
                focus = 1;
                success = false;
            }
        }

        if ((this.state.country === null) || (this.state.country === "")) {
            this.setState({ emptyCountry: true });
            success = false;
        }

        if ((this.state.courseName === null) || (this.state.courseName === "")) {
            if (focus === 0) {
                this.courseNameRef.current.focus();
                focus = 1;
            }
            this.setState({ emptyCourseName: true });
            success = false;
        }

        if ((this.state.city === null) || (this.state.city === "")) {
            if (focus === 0) {
                this.courseCityRef.current.focus();
                focus = 1;
            }
            this.setState({ emptyCity: true });
            success = false;
        }


        return success;
    }

    sendData = async (captchaToken) => {
        let isValid = this.validateData();
        if (isValid) {
            this.setState({ pageLoader: true });
            let fileName1;
            let fileName2;
            if (this.state.selectedFile1 !== null) {
                fileName1 = uuidv4() + "." + this.state.selectedFile1.name.split('.').pop();
            }
            if (this.state.selectedFile2 !== null) {
                fileName2 = uuidv4() + "." + this.state.selectedFile2.name.split('.').pop();
            }
            let responseData = await bushnellAPI.UploadScoreCardImage(this.state.selectedFile1, this.state.selectedFile2, fileName1, fileName2);
            if (responseData.errorCode === 0) {
                let courseData = {
                    Email: this.state.email,
                    CourseName: this.state.courseName,
                    Country: this.state.country,
                    State: this.state.stateName,
                    City: this.state.city,
                    AdditionalNotes: this.state.notes,
                    ScoreCardPhotos: [],
                    CaptchaResponse: captchaToken,
                    CaptchaActionPage: this.state.captchaAction
                };
                if (this.state.selectedFile1 !== null && this.state.selectedFile2 !== null) {
                    courseData.ScoreCardPhotos = [ { "fileName": fileName1 }, { "fileName": fileName2 } ];
                } else if (this.state.selectedFile1 !== null) {
                    courseData.ScoreCardPhotos = [ { "fileName": fileName1 } ];
                } else if (this.state.selectedFile2 !== null) {
                    courseData.ScoreCardPhotos = [ { "fileName": fileName2 } ];
                }
                responseData = await bushnellAPI.addCourseRequest(courseData);
                if (responseData.errorCode === 0) {
                    this.setState({ alertType: 'success', alertMessage: langService.t("CourseSearch_CourseRequestSuccess") }, () => {
                        this.clearForm();
                        this.setState({ alertBox: true, pageLoader: false });
                    });
                    this.setState({ successRequest: true });
                    this.hideAlert();
                } else {
                    this.setState({ alertType: 'danger', alertMessage: responseData.status }, () => {
                        this.setState({ alertBox: true, pageLoader: false });
                    });
                    this.hideAlert();
                }
            } else {
                this.setState({ alertType: 'danger', alertMessage: responseData.status }, () => {
                    this.setState({ alertBox: true, pageLoader: false });
                });
                this.hideAlert();
            }
        }
    }

    handleSubmit = async () => {
        try {
            const configData = configService.getConfigData();
            window.grecaptcha.ready(() => {
              window.grecaptcha.execute(configData.Google_ReCaptcha_SiteKey, { action: this.state.captchaAction })
              .then(token => {
                  this.sendData(token);
              });
            });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    }

    hideAlert = () => {

        setTimeout(() => {
            this.setState({ alertBox: false, alertType: '', alertMessage: '' });
        }, 3000);

    }

    clearForm = () => {
        this.setState({ country: '', stateName: '', courseName: '', city: '', notes: '', captcha: '' }, async () => {
            await this.storeCountryData();
        });
    }

    clearFormValidation = () => {

        this.setState({ emptyCountry: false, emptyState: false, emptyCourseName: false, emptyCity: false, emptyNotes: false, emailErrorMsg: '', isValidCaptcha: false, inValidCaptcha: false });

    }

    render() {

        let selectCountry = "";
        let cData = this.state.countryData;
        if (cData) {
            selectCountry = (
                <SelectList
                    handleChange={this.handleChangeCountry}
                    value={this.state.country}
                    dataList={cData.countries}
                />
            );
        }
        else {
            selectCountry = "";
        }

        let selectState = "";
        let sData = this.state.stateData;
        let countryCode = this.state.country;
        if (sData) {
            var filterData = sData.states.filter(function (el) {
                return el.countryCode === countryCode;
            });
            if (filterData.length === 0) {
                selectState = (
                    <input
                        type="text"
                        className="w-100"
                        name="stateName"
                        value={this.state.stateName}
                        placeholder={langService.t("UserAccount_State")}
                        onChange={(e) => this.handleChangeState(e.target.value)}
                    />
                );
            } else {
                selectState = (
                    <SelectList
                        handleChange={this.handleChangeState}
                        value={this.state.stateName}
                        dataList={filterData}
                    />
                );
            }
        } else {
            selectState = "";
        }

        return (
            <>
                {(this.state.successRequest === true) ? <Redirect
                    to={{
                        pathname: "course",
                        state: { requestSuccess: true }
                    }}
                /> : null}
                <div>

                    <div className="bn_g_custom-container">

                        {(this.state.alertBox === true ? <Alerts alertType={this.state.alertType} alertMessage={this.state.alertMessage} /> : null)}
                        <div className="col-lg-12 req-space">
                            <div className="row">
                                <div className="loc-name col-5 col-sm-2 col-xl-2 ">{langService.t("UserAccount_Email")}<span className='mandatoryStar'> *</span></div>
                                <div className="col-7 col-sm-6  col-xl-6">
                                    <input onChange={this.changeInput} type="text" placeholder={langService.t("UserAccount_Email")} className="w-100" name="email" value={this.state.email} autoFocus ref={this.emailRef} />
                                    {(this.state.emailErrorMsg !== '') ? <span className="errorMsg">{this.state.emailErrorMsg}</span> : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 req-space">
                            <div className="row">
                                <div className="loc-name col-5 col-sm-2 col-xl-2 ">{langService.t("CourseSearch_CourseName")}<span className='mandatoryStar'> *</span></div>
                                <div className="col-7 col-sm-6  col-xl-6">
                                    <input onChange={this.changeInput} type="text" placeholder={langService.t("CourseSearch_CourseName")} className="w-100" name="courseName" value={this.state.courseName} ref={this.courseNameRef} />
                                    {(this.state.emptyCourseName === true) ? <span className="errorMsg">{langService.t("CourseSearch_EnterCourseName")}</span> : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 req-space">
                            <div className="row">
                                <div className="loc-name col-5 col-sm-2 col-xl-2">{langService.t("CourseSearch_Country")}<span className='mandatoryStar'> *</span></div>
                                <div className="col-7 col-sm-3  col-xl-3">
                                    <div className="dropdown">
                                        {selectCountry}
                                    </div>
                                    {(this.state.emptyCountry === true) ? <span className="errorMsg">{langService.t("CourseSearch_SelectCountry")}</span> : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg req-space">
                            <div className="row">
                                <div className="loc-name col-5 col-sm-2 col-xl-2">{langService.t("CourseSearch_State")}</div>
                                <div className="col-7 col-sm-3  col-xl-3">
                                    <div className="dropdown">
                                        {selectState}
                                    </div>
                                    {(this.state.emptyState === true) ? <span className="errorMsg">
                                        {(this.state.country === 'US' || this.state.country === 'CA') ? langService.t("CourseSearch_SelectState") : langService.t("CourseSearch_EnterState")}
                                    </span> : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg req-space">
                            <div className="row">
                                <div className="loc-name col-5 col-sm-2 col-xl-2">{langService.t("CourseSearch_City")}<span className='mandatoryStar'> *</span></div>
                                <div className="col-7 col-sm-3  col-xl-3">
                                    <input ref={this.courseCityRef} onChange={this.changeInput} name="city" type="text" placeholder={langService.t("CourseSearch_City")} className="w-100" value={this.state.city} />
                                    {(this.state.emptyCity === true) ? <span className="errorMsg">{langService.t("CourseSearch_EnterCity")}</span> : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg req-space">
                            <div className="row">
                                <div className="loc-name col-5 col-sm-2 col-xl-2">{langService.t("CourseSearch_AdditionalNotes")}</div>
                                <div className="col-7 col-sm-6  col-xl-6">
                                    <textarea ref={this.courseNoteRef} placeholder={langService.t("CourseSearch_AdditionalNotes")} onChange={this.changeInput} name="notes" className="w-100" value={this.state.notes}></textarea>
                                    {(this.state.emptyNotes === true) ? <span className="errorMsg">{langService.t("CourseSearch_EnterAdditionalNotes")}</span> : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 req-space">
                            <div className="row">
                                <div className="loc-name col-5 col-sm-2 col-xl-2 ">{langService.t("CourseSearch_UploadImages")}</div>
                                <div className="col-7 col-sm-6  col-xl-6">
                                    { this.state.selectedFile1 !== null ?
                                    <div>
                                        <span>{this.state.selectedFile1.name}  </span>
                                        <a className="link-td" href="# " onClick={(e) => { e.preventDefault(); this.onRemoveFile1(); }}><i className="far fa-trash-alt"></i></a>
                                    </div>
                                    : null }
                                    { this.state.selectedFile2 !== null ?
                                    <div className='req-space'>
                                        <span>{this.state.selectedFile2.name}  </span>
                                        <a className="link-td" href="# " onClick={(e) => { e.preventDefault(); this.onRemoveFile2(); }}><i className="far fa-trash-alt"></i></a>
                                    </div>
                                    : null }
                                    { this.state.selectedFile1 === null || this.state.selectedFile2 === null ?
                                    <div className='req-space'>
                                        <input onChange={this.onFile1Change} type="file" className="hideControl" ref={this.file1UploadRef} />
                                        <input onChange={this.onFile2Change} type="file" className="hideControl" ref={this.file2UploadRef} />
                                        <input onClick={this.onBrowseClick} type="button" value='Browse' className="btn-primary" />
                                    </div>
                                    : null }
                                    <span className="errorMsg">{langService.t("CourseSearch_UploadImageLimits")}</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg req-space">
                            <div className="row">
                                <div className="loc-name col-5 col-sm-2 col-xl-2">{langService.t("CourseSearch_Captcha")}<span className='mandatoryStar'> *</span></div>
                                <div className="col-7 col-sm-3  col-xl-3">
                                    <CaptchaTest captchaText={this.state.captcha} isValidCaptcha={this.state.isValidCaptcha} setValidCaptcha={this.setValidCaptcha} />
                                    <input ref={this.captchaRef} onChange={this.changeInput} name="captcha" type="text" placeholder={langService.t("CourseSearch_Captcha")} className="w-100" value={this.state.captcha} />
                                    {(this.state.inValidCaptcha === true) ? <span className="errorMsg">{langService.t("CourseSearch_InvalidCaptcha")}</span> : null}
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg req-space">
                            <div className="row">
                                <div className="col-lg-8 submit-btn">
                                    <input onClick={this.handleSubmit} type="button" value={langService.t("Btn_Submit")} className="btn-primary float-right searchBtn" />
                                    <Link to="course" className="btn-back float-right">{langService.t("Btn_Back")}</Link>
                                </div>
                            </div>
                        </div>

                        {(this.state.pageLoader === true ? <PageLoader /> : null)}

                    </div>

                </div>

            </>
        );
    }
}

export default RequestForm;