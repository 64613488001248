import React from "react";
import * as langService from '../services/langService';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisconnected: false,
        };
    }

    componentDidMount() {
        // Internet connection lost
        // https://medium.com/backticks-tildes/a-guide-to-handling-internet-disconnection-in-react-applications-5fd02eccb2bb
        this.handleConnectionChange();
        window.addEventListener("online", this.handleConnectionChange);
        window.addEventListener("offline", this.handleConnectionChange);
    }

    componentWillUnmount() {
        // Internet connection lost
        window.removeEventListener("online", this.handleConnectionChange);
        window.removeEventListener("offline", this.handleConnectionChange);
    }

    handleConnectionChange = () => {
        // Internet connection lost
        const condition = navigator.onLine ? "online" : "offline";
        if (condition === "online") {
            const webPing = setInterval(() => {
                fetch("//google.com", {
                    mode: "no-cors",
                })
                    .then(() => {
                        this.setState({ isDisconnected: false }, () => {
                            window.$("#noNetworkPopupModal").modal("hide");
                            return clearInterval(webPing);
                        });
                    })
                    .catch(() => {
                        this.setState({ isDisconnected: true });
                    });
            }, 2000);
            return;
        } else {
            window.$("#noNetworkPopupModal").modal("show");
        }

        //return this.setState({ isDisconnected: true });
    };

    render() {
        return (
            <footer className="py-4 mt-auto">
                <div className="container-fluid">
                    <div className="text-center">
                        <div className="text-footer">{langService.t("Copyright_Message")}</div>
                    </div>
                </div>

                {/* Internet connection lost POPUP  */}
                <div className="modal custom-pop fade" id="noNetworkPopupModal" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="btn-grp d-flex ">
                                            <label className="form-control-label" id="messagePopupLabel">
                                                {langService.t("NoInternet_Message")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
