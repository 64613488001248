import React from "react";
import * as langService from "../services/langService";
import * as bushnellAPI from "../services/bushnellAPIService.js";
import * as commonService from "../services/commonService.js";
import * as configService from "../services/configDataService";

class Verification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sendType: 1,
            btnClicked: false,
            nextForm: props.nextForm,
            emailMaxAttempts: false,
            phoneMaxAttempts: false,
            emailAttemptCount: 0,
            phoneAttemptCount: 0,
            gateWayErrorCode: 0,
            isMaxAttempts: false,
            formData: {
                firstName: props.formData.firstName,
                lastName: props.formData.lastName,
                countryCode: props.formData.countryCode,
                phoneNumber: props.formData.phoneNumber,
                email: props.formData.email,
                password: props.formData.password,
            },
            sid: '',
            captchaAction: 'Signup',
        };
    }

    handleVerificationType = (type) => {
        let canSetState = true;
        if (type === 2) {
            let cCode = this.state.formData.countryCode;
            if (cCode === "86" || cCode === "086" || cCode === "+86") {
                canSetState = false;
                commonService.showMessage(langService.t("UserAccount_CantUseMobileVerification"));
            }
        }
        if (canSetState) {
            this.setState({ sendType: type });
        }
    };

    handleSaveVerification = async (captchaToken) => {
        try {
            this.setState({ btnClicked: true });
            this.props.showLoader(true);
            if (this.state.sendType) {
                let to = "";
                if (this.state.sendType === 1) {
                    to = this.state.formData.email;
                } else if (this.state.sendType === 2) {
                    if (this.state.formData.countryCode.startsWith("0")) {
                        to = this.state.formData.countryCode.substr(1);
                        to = to + this.state.formData.phoneNumber;
                    }
                    else {
                        to = this.state.formData.countryCode + this.state.formData.phoneNumber;
                    }
                }

                await bushnellAPI.SendVerificationCode(to, this.state.sendType, captchaToken, this.state.captchaAction)
                .then(async (response) => {
                    let jsonData = await response.json();
                    if (jsonData.gateWayErrorCode === 0) {
                        await this.setState({
                            sid: jsonData.sid,
                        });
                        this.props.sendType(this.state.sendType);
                        if (jsonData.send_code_attempts.length > 3) {
                            this.props.sendType(this.state.sendType === 1 ? 2 : 1);
                        }
                        if (this.state.sendType === 1) {
                            await this.setState({
                                emailAttemptCount: jsonData.send_code_attempts.length,
                            });
                        } else {
                            await this.setState({
                                phoneAttemptCount: jsonData.send_code_attempts.length,
                            });
                        }
                        if (this.state.sendType === 1) {
                            this.props.emailAttemptCount(this.state.emailAttemptCount);
                        } else {
                            this.props.phoneAttemptCount(this.state.phoneAttemptCount);
                        }
                        this.props.sid(this.state.sid);
                        this.state.nextForm("Verification");
                    } else if (jsonData.gateWayErrorCode) {
                        this.setState({
                            gateWayErrorCode: jsonData.gateWayErrorCode,
                        });
                        commonService.Log(jsonData.gateWayErrorCode);
                        commonService.showMessage(langService.tErrorMsg(jsonData.gateWayMessage));
                        return false;
                    } else if (jsonData.errorCode) {
                        if (commonService.checkIpBlockingError(jsonData.errorCode)){
                            commonService.Log(`${jsonData.errorCode} - ${langService.tErrorMsg("OTP_IP_Blocking")} `);
                            commonService.showMessage(langService.tErrorMsg("OTP_IP_Blocking"));
                        } else {
                            commonService.Log(`${jsonData.errorCode} - ${langService.tErrorMsg("UserAccount_OTPSentFailed")} `);
                            commonService.showMessage(langService.tErrorMsg("UserAccount_OTPSentFailed"));
                        }
                        return false;
                    } else {
                        commonService.Log(jsonData);
                        commonService.showMessage(langService.tErrorMsg("UserAccount_OTPSentFailed"));
                        return false;
                    }
                });
            }
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        }
    };

    handleSubmit = async (event) => {
        try {
          event.preventDefault();
          const configData = configService.getConfigData();
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(configData.Google_ReCaptcha_SiteKey, { action: this.state.captchaAction })
            .then(token => {
                this.handleSaveVerification(token);
            });
          });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
      };

    handleSendType = () => {
        this.setState({ sendType: this.state.sendType === 1 ? 2 : 1 });
    };

    render() {
        return (
            <div className="form-main verify">
                <h1>{langService.t("UserAccount_Verification")}</h1>
                <p>{langService.t("UserAccount_VerificationHowTo")}</p>
                <p className="content">{langService.t("UserAccount_VerificationWhy")}</p>
                <form className="mt-4">
                    <h4>{langService.t("UserAccount_VerificationSendAs")}</h4>
                    <div className="form-group cstm-radio">
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                value="option1"
                                onChange={() => this.handleVerificationType(1)}
                                checked={this.state.sendType === 1 ? true : false}
                            />
                            <label className="form-check-label pr-3" htmlFor="inlineRadio1">
                                {langService.t("UserAccount_Email")}
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                value="option2"
                                onChange={() => this.handleVerificationType(2)}
                                checked={this.state.sendType === 2 ? true : false}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio2">
                                {langService.t("UserAccount_TextMessage")}
                            </label>
                        </div>
                    </div>
                    {this.state.sendType === "" ? <div className="field-errors">{langService.t("UserAccount_VerificationSelectOne")}</div> : ""}
                </form>
                <button type="submit" className="btn btn-primary btn-block" onClick={this.handleSubmit} disabled={this.state.btnClicked}>
                    {langService.t("Btn_Send")}
                </button>
            </div>
        );
    }
}

export default Verification;
