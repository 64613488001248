import React from 'react';
import RequestForm from './RequestForm';

class RequestCourse extends React.Component {

    render() 
    {
        return (
            <>

                <div className="container-fluid bn_g_page-bg req-course  bn_g mtb50">
                    <RequestForm/>
                </div>

            </>
        );
    }
    
}

export default RequestCourse;