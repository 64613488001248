import React from 'react';
import {Link} from "react-router-dom";
import * as langService from '../../services/langService'

class RequestCourseSection extends React.Component {

    render() 
    {
        return (
            <>

                <div className="bn_g_custom-container">
                    <div className="request-course-btn">
                        <span className="requestText">{langService.t("CourseSearch_RefineSearch")}</span>
                        <Link to="request">
                            <input type="button" value={langService.t("CourseSearch_RequestCourse")} className="btn-primary"/>
                        </Link>
                    </div>
                </div>

            </>
        );
    }
}

export default RequestCourseSection;