import React from "react";
import * as langService from '../services/langService';

class UpdateStep2 extends React.Component {
    handleNext() {
        this.props.nextForm("UpdateStep2");
    }
    render() {
        return (
            <div className="container-fluid mt-4">
                <div className="co-bx form-cstm">
                    <h1 className="text-center">
                        <img alt="power icon" src={process.env.PUBLIC_URL + "/images/power_icon.svg"} />
                        <span>{langService.t("UpdateProcess_PrepareDevice")}</span>
                    </h1>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="device-sec text-center">
                                <img className="img-fluid" alt="device" src={process.env.PUBLIC_URL + "/images/device.png"} />
                            </div>
                        </div>
                    </div>
                    <p className="content-txt">{langService.t("UpdateProcess_TurnOFFDeviceHoldPowerButton")}</p>
                    <ul className="indicators text-center">
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <div className="button-wrap text-center mb-2">
                        <button type="button" className="btn btn-primary" onClick={() => this.handleNext()}>
                            {langService.t("UpdateProcess_GetStarted")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default UpdateStep2;
