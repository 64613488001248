import React from "react";

import NavTop from "./NavTop";
import Footer from "./Footer";

import UpdateStep1 from "./UpdateStep1";
import UpdateStep2 from "./UpdateStep2";
import UpdateStep3 from "./UpdateStep3";
import UpdateStep4 from "./UpdateStep4";
import UpdateSuccess from "./UpdateSuccess";
import UpdateNoChange from "./UpdateNoChange";
import CourseSearch from "./CourseSearch";

class UpdateProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextForm: props.nextForm,
            currentMode: "step1",
        };
    }

    componentDidMount() {
        document.body.classList.remove("bg-login");
    }

    // returns the corresponding Form based on currentMode
    getForm(currentMode) {
        const forms = {
            step1: <UpdateStep1 nextForm={this.nextForm} />,
            step2: <UpdateStep2 nextForm={this.nextForm} />,
            step3: <UpdateStep3 nextForm={this.nextForm} />,
            step4: <UpdateStep4 nextForm={this.nextForm} />,
            stepSuccess: <UpdateSuccess nextForm={this.nextForm} />,
            stepNoChange: <UpdateNoChange nextForm={this.nextForm} />,
            courseSearch: <CourseSearch nextForm={this.nextForm} />,
        };
        return forms[currentMode];
    }

    // update currentMode triggers the callback
    nextForm = (mode) => {
        if (mode === "UpdateStep0") mode = "step1";
        else if (mode === "UpdateStep1") mode = "step2";
        else if (mode === "UpdateStep2") mode = "step3";
        else if (mode === "UpdateStep3") mode = "step4";
        else if (mode === "CourseSelect") mode = "courseSearch";
        else if (mode === "Success") mode = "stepSuccess";
        else if (mode === "NoChange") mode = "stepNoChange";
        else if (mode === "LogoutDone") this.state.nextForm("LogoutDone");
        this.setState((state) => ({ currentMode: mode }));
    };

    render() {
        return (
            <div>
                <NavTop nextForm={(mode) => this.nextForm(mode)} />
                <div id="layoutSidenav">
                    <div id="layoutSidenav_content">{this.getForm(this.state.currentMode)}</div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default UpdateProcess;
