import React from 'react';
import * as langService from '../../services/langService'

class SelectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: props.value };
    }
    handleChangeValue(selectedValue) {
        this.setState({ value: selectedValue });
        this.props.handleChange(selectedValue);
    }

    componentWillReceiveProps=(nextProps)=>
    {
        this.setState({value:nextProps.value});
    }

    render() {
        return (
          
            <select className="dropdown-toggle" value={this.state.value} onChange={(e) => this.handleChangeValue(e.target.value)}>
                <option key={null} value=''>{langService.t("CourseSearch_DropDownDefault")}</option>
                {this.props.dataList.map(function (item) {
                    return (
                        <option key={item.code} value={item.code}>
                            {item.name}
                        </option>
                    );
                })}
            </select>

        );
    }
}

export default SelectList;