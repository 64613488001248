import React from "react";

import * as updateManager from "../services/updateManager.js";
import * as commonService from "../services/commonService.js";
import * as langService from '../services/langService';
import ProgressList from "./ProgressList";

class UpdateStep4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seconds: 0,
            isKRJPUpdate : false,
            isFontUpdateRequired : false
        };
        updateManager.init();
    }
    tick() {
        updateManager.refreshProgress();
        this.setState((state) => ({
            seconds: state.seconds + 1,
        }));
        if (this.state.seconds > 100) {
            this.setState((state) => ({
                seconds: 0,
            }));
        }
    }
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        try {
            let deviceData = updateManager.getVersionData();
            this.setState({isFontUpdateRequired: deviceData.font.updateRequired})
            this.setState({isKRJPUpdate: deviceData.isKRJPLangSupported})

            this.interval = setInterval(() => this.tick(), 1000);
            let flag = await updateManager.startUpdate();
            this.handleNext(flag);
        } catch (err) {
            clearInterval(this.interval);
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
            await commonService.ScreenWakeLock_Disable();
            this.props.nextForm("UpdateStep0");
        }
    };
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleNext = (flag) => {
        if (flag) {
            this.props.nextForm("Success");
        } else {
            this.props.nextForm("NoChange");
        }
    };
    render() {
        return (
            <div className="container-fluid mt-4">
                <div className="co-bx form-cstm">
                    <h1 className="text-center">
                        <img alt="update_icon" src={process.env.PUBLIC_URL + "/images/update_icon.svg"} />
                        <span>{langService.t("UpdateProcess_UpdatingFiles")}</span>
                    </h1>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 upld mt-3">
                            <ProgressList 
                                isKRJPUpdate = { this.state.isKRJPUpdate }
                                isFontUpdateRequired = { this.state.isFontUpdateRequired }
                            />
                        </div>
                    </div>
                    <ul className="indicators text-center">
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                    </ul>
                    <div className="button-wrap text-center mb-2">
                        <button type="button" className="btn btn-primary">
                            {langService.t("UpdateProcess_Updating")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default UpdateStep4;
