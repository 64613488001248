import React from "react";
import * as langService from "../services/langService";
import * as bushnellAPI from "../services/bushnellAPIService.js";
import * as commonService from "../services/commonService.js";
import SwitchChannel from "./SwitchChannel";
import Countdown from "react-countdown";
import * as configService from "../services/configDataService";

class VerifyCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            verificationCode: "",
            nextForm: props.nextForm,
            sendType: props.sendType,
            isOTPError: false,
            errorMsg: "",
            btnClicked: false,
            isSubmitted: false,
            emailAttemptCount: props.emailAttemptCount ? props.emailAttemptCount : 0,
            phoneAttemptCount: props.phoneAttemptCount ? props.phoneAttemptCount : 0,
            otp_1: "",
            otp_2: "",
            otp_3: "",
            otp_4: "",
            otp_5: "",
            otp_6: "",
            formData: {
                firstName: props.formData.firstName.toString(),
                lastName: props.formData.lastName,
                countryCode: props.formData.countryCode,
                phoneNumber: props.formData.phoneNumber,
                email: props.formData.email,
                password: props.formData.password,
            },
            sid: props.sid,
            captchaActionResend: 'SignupResendOTP',
            captchaActionSwitchChannel: 'SignupSwitchChannel',
        };
        this.counterTimer = this.handleCounterTimer();
        this.countdownApi = null;
        this.inputVerificationCode = React.createRef();
    }

    async componentDidMount() {
        this.inputVerificationCode.current.focus();
    }

    componentDidUpdate() {
        this.counterTimer = this.handleCounterTimer();
    }

    handleCounterTimer = () => {
        let inSeconds = 0;
        let emailAttemptCount = this.state.emailAttemptCount ? this.state.emailAttemptCount : this.props.emailAttemptCount;
        let phoneAttemptCount = this.state.phoneAttemptCount ? this.state.phoneAttemptCount : this.props.phoneAttemptCount;
        let sendType = this.state.sendType ? this.state.sendType : this.props.sendType;

        if (sendType === 1) {
            if (emailAttemptCount === 3) {
                inSeconds = 120000;
            } else if (emailAttemptCount === 2) {
                inSeconds = 60000;
            } else {
                inSeconds = 30000;
            }
        } else {
            if (phoneAttemptCount === 3) {
                inSeconds = 120000;
            } else if (phoneAttemptCount === 2) {
                inSeconds = 60000;
            } else {
                inSeconds = 30000;
            }
        }
        return Date.now() + inSeconds;
    };

    validateFormData() {
        if (this.state.verificationCode === "" || this.state.verificationCode.length !== 6) {
            this.inputVerificationCode.current.focus();
        }
        return this.state.verificationCode === "" || this.state.verificationCode.length !== 6 ? true : false;
    }

    handleOTPVerification = async () => {
        try {
            this.setState({ isOTPError: false, errorMsg: "", btnClicked: true, isSubmitted: true });
            this.props.showLoader(true);
            if (!this.validateFormData()) {
                //if (this.state.verificationCode) {
                let language = langService.getLanguage();
                await bushnellAPI
                    .CreateUser(
                        this.state.formData.firstName,
                        this.state.formData.lastName,
                        this.state.formData.email,
                        this.state.formData.countryCode,
                        this.state.formData.phoneNumber,
                        this.state.formData.password,
                        this.state.verificationCode,
                        this.state.sendType,
                        language,
                        this.state.sid
                    )
                    .then((status) => {
                        if (!status.errorCode) {
                            this.state.nextForm("VerifyCode");
                        } else {
                            this.setState({ isOTPError: true, errorMsg: status.status });
                        }
                    });
            }
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        }
    };

    handleStartClick = () => {
        this.countdownApi && this.countdownApi.start();
        this.handleIsCompleted();
    };

    handleSendType = () => {
        this.setState({ sendType: this.state.sendType === 1 ? 2 : 1 });
    };

    handleSaveVerification = async (captchaToken) => {
        try {
            this.setState({ btnClicked: true });
            this.props.showLoader(true);
            if (this.state.sendType) {
                let to = "";
                if (this.state.sendType === 1) {
                    to = this.state.formData.email;
                } else if (this.state.sendType === 2) {
                    to = this.state.formData.countryCode + this.state.formData.phoneNumber;
                }
                await bushnellAPI.SendVerificationCode(to, this.state.sendType, captchaToken, this.state.captchaActionResend)
                .then(async (response) => {
                    let jsonData = await response.json();
                    if (jsonData.gateWayErrorCode === 0) {
                        this.handleStartClick();
                        await this.setState({
                            sid: jsonData.sid,
                        });
                        if (this.state.sendType === 1) {
                            await this.setState({
                                emailAttemptCount: this.state.emailAttemptCount + 1,
                            });
                        } else {
                            await this.setState({
                                phoneAttemptCount: this.state.phoneAttemptCount + 1,
                            });
                        }
                        if (this.state.emailAttemptCount > 3 && this.state.phoneAttemptCount > 3) {
                            this.props.nextForm("contactSupport");
                        }
                    } else if (jsonData.gateWayErrorCode) {
                        commonService.Log(jsonData.gateWayErrorCode);
                        commonService.showMessage(langService.tErrorMsg(jsonData.gateWayMessage));
                        return false;
                    } else if (jsonData.errorCode) {
                        if (commonService.checkIpBlockingError(jsonData.errorCode)){
                            commonService.Log(`${jsonData.errorCode} - ${langService.tErrorMsg("OTP_IP_Blocking")} `);
                            commonService.showMessage(langService.tErrorMsg("OTP_IP_Blocking"));
                        } else {
                            commonService.Log(`${jsonData.errorCode} - ${langService.tErrorMsg("UserAccount_OTPSentFailed")} `);
                            commonService.showMessage(langService.tErrorMsg("UserAccount_OTPSentFailed"));
                        }
                        return false;
                    } else {
                        commonService.Log(jsonData);
                        commonService.showMessage(langService.tErrorMsg("UserAccount_OTPSentFailed"));
                        return false;
                    }
                });
            }
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        }
    };

    handleResend = async (event) => {
        event.preventDefault();
        try {
          const configData = configService.getConfigData();
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(configData.Google_ReCaptcha_SiteKey, { action: this.state.captchaActionResend })
            .then(token => {
                this.handleSaveVerification(token);
            });
          });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    };

    handleClickedOk = async (captchaToken) => {
        try {
            window.$("#verificationPopupModal").modal("hide");
            await this.setState({ sendType: this.state.sendType === 1 ? 2 : 1 });
            if (this.state.sendType) {
                let to = "";
                if (this.state.sendType === 1) {
                    to = this.state.formData.email;
                } else if (this.state.sendType === 2) {
                    to = this.state.formData.countryCode + this.state.formData.phoneNumber;
                }

                await bushnellAPI.SendVerificationCode(to, this.state.sendType, captchaToken, this.state.captchaActionSwitchChannel)
                .then(async (response) => {
                    let jsonData = await response.json();
                    if (jsonData.gateWayErrorCode === 0) {
                        this.handleStartClick();
                        await this.setState({
                            sid: jsonData.sid,
                        });
                        if (this.state.sendType === 1) {
                            await this.setState({
                                emailAttemptCount: this.state.emailAttemptCount + 1,
                            });
                        } else {
                            await this.setState({
                                phoneAttemptCount: this.state.phoneAttemptCount + 1,
                            });
                        }
                    } else if (jsonData.gateWayErrorCode) {
                        commonService.Log(jsonData.gateWayErrorCode);
                        commonService.showMessage(langService.tErrorMsg(jsonData.gateWayMessage));
                        return false;
                    } else if (jsonData.errorCode) {
                        if (commonService.checkIpBlockingError(jsonData.errorCode)){
                            commonService.Log(`${jsonData.errorCode} - ${langService.tErrorMsg("OTP_IP_Blocking")} `);
                            commonService.showMessage(langService.tErrorMsg("OTP_IP_Blocking"));
                        } else {
                            commonService.Log(`${jsonData.errorCode} - ${langService.tErrorMsg("UserAccount_OTPSentFailed")} `);
                            commonService.showMessage(langService.tErrorMsg("UserAccount_OTPSentFailed"));
                        }
                        return false;
                    } else {
                        commonService.Log(jsonData);
                        commonService.showMessage(langService.tErrorMsg("UserAccount_OTPSentFailed"));
                        return false;
                    }
                });
            }
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        }
    };

    handleSwitchChannel = async () => {
        try {
          const configData = configService.getConfigData();
          window.grecaptcha.ready(() => {
            window.grecaptcha.execute(configData.Google_ReCaptcha_SiteKey, { action: this.state.captchaActionSwitchChannel })
            .then(token => {
                this.handleClickedOk(token);
            });
          });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    };

    handleIsCompleted = () => {
        this.forceUpdate();
    };

    isCompleted = () => {
        return !!(this.countdownApi && this.countdownApi.isCompleted());
    };

    setRef = (countdown) => {
        if (countdown) {
            this.countdownApi = countdown.getApi();
        }
    };

    rendererCounter = ({ hours, minutes, seconds, completed }) => {
        if (!completed) {
            // Render a countdown
            let rTime = langService.t("UserAccount_OTP_RemainingTime");
            if (minutes > 0) {
                rTime = rTime.replace("{minute}", minutes);
                rTime = rTime.replace("{second}", seconds);
            }
            else {
                rTime = rTime.replace("{minute} minute {second}", seconds);
            }
            return <span className="display_none">{rTime}</span>;
        } else {
            return <span> </span>;
        }
    };

    render() {
        return (
            <div className="form-main verify">
                <h1>{langService.t("UserAccount_Verification")}</h1>
                <p className="content">{langService.t("UserAccount_VerificationEnter")}</p>
                <p>{this.state.sendType === 1 ? this.state.formData.email : this.state.formData.countryCode + this.state.formData.phoneNumber}</p>
                <form className="mt-4">
                    <div className="form-row">
                        <div className="form-group col-6">
                            <input
                                type="text"
                                name="otp_1"
                                className="form-control"
                                maxLength="6"
                                onChange={(e) => this.setState({ verificationCode: e.target.value })}
                                value={this.state.verificationCode}
                                ref={this.inputVerificationCode}
                            />
                        </div>
                    </div>
                    {(this.state.isSubmitted && !this.state.verificationCode) || (this.state.isSubmitted && this.state.verificationCode.length !== 6) ? (
                        <div className="field-errors mt-10">{langService.t("UserAccount_NoOTP")}</div>
                    ) : this.state.isOTPError ? (
                        <div className="field-errors mt-10">{this.state.errorMsg}</div>
                    ) : (
                        ""
                    )}
                    <br></br>
                    <Countdown date={this.counterTimer} renderer={this.rendererCounter} ref={this.setRef} onComplete={this.handleIsCompleted} onStart={this.handleIsCompleted} />
                    <p className="message">
                        {langService.t("UserAccount_FailedOTP")} &nbsp;
                        {!this.isCompleted() ? (
                            <a disabled={true} href="# ">{langService.t("Btn_Resend")}</a>
                        ) : (
                            <a onClick={this.handleResend} href="# ">
                                {langService.t("Btn_Resend")}
                            </a>
                        )}
                    </p>
                </form>
                <button type="submit" className="btn btn-primary btn-block" onClick={this.handleOTPVerification}>
                    {langService.t("Btn_VerifyAndProceed")}
                </button>
                {(this.state.emailAttemptCount > 3 || this.state.phoneAttemptCount > 3) && !(this.state.emailAttemptCount >= 3 && this.state.phoneAttemptCount >= 3) ? (
                    <SwitchChannel clickedOk={this.handleSwitchChannel} sendType={this.state.sendType} />
                ) : (
                    ""
                )}
            </div>
        );
    }
}

export default VerifyCode;
