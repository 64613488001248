import * as updateService from "./updateService";
import * as configService from "./configDataService"

const Log_Version = "v0.27";
const DeviceName_Phantom2 = 'Phantom2';
const NonLangVersion_Phantom2 = '1.1.04';
const DeviceName_IonEdge = 'IonEdge';
const NonLangVersion_IonEdge = '1.2.2';
const ScriptId = "recaptcha-key";

var Enable_Debug_Log = true;

export function setFlags(configData) {
    if (configData.Flags.EnableDebugLog === "1") {
        Enable_Debug_Log = true;
    } else {
        Enable_Debug_Log = false;
    }
}

export function Log(message) {
    if (Enable_Debug_Log) {
        console.log(Log_Version, message);
    }
}

export function LogData(message, data) {
    if (Enable_Debug_Log) {
        console.log(Log_Version, message, data);
    }
}

export function showMessage(message) {
    window.$("#msgPopupLabel").html(message);
    window.$("#messagePopupModal").modal("show");
}

export function showLoader(flag) {
    if (flag) {
        window.$("#loaderDisplay").css("display", "block");
    }
    else {
        window.$("#loaderDisplay").css("display", "none");
    }
}

export function logTime(message) {
    var d = new Date();
    var n = d.toLocaleTimeString();
    LogData(message, n);
}

export function getBrowserProps() {
    const platform = window.navigator.userAgent.toLowerCase();
    let props = {
        isBrowserChrome: /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
        isOSMac: (platform.indexOf("mac") !== -1),
        isOSWindows: (platform.indexOf("windows") !== -1),
        isOSLinux: (platform.indexOf("linux") !== -1),
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    };
    return props;
}

// Wake lock

let wakeLock = null;
export async function ScreenWakeLock_Enable() {
    if ('wakeLock' in navigator) {
        try {
            wakeLock = await navigator.wakeLock.request('screen');
            wakeLock.addEventListener('release', () => {
                Log('Wake Lock released');
            });
            document.addEventListener('visibilitychange', handleVisibilityChange);
            document.addEventListener('fullscreenchange', handleVisibilityChange);
            Log('Wake Lock is active');
        } catch (err) {
            Log(`${err.name}, ${err.message}`);
        }
    } else {
        Log('Wake Lock is not supported');
    }
}
export async function ScreenWakeLock_Disable() {
    if (!wakeLock) {
        return;
    }
    try {
        await wakeLock.release();
        wakeLock = null;
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        document.removeEventListener('fullscreenchange', handleVisibilityChange); 
    } catch (err) {
        Log(`${err.name}, ${err.message}`);
    }
}
export function handleVisibilityChange() {    
    if (wakeLock !== null && document.visibilityState === 'visible') {
        ScreenWakeLock_Enable();
    }
}

// Wake lock

export function replaceCensorWord(str) {
    return str[0] + "*".repeat(str.length - 1);
}

export function replaceCensorEmail(email) {
    var arr = email.split("@");
    return replaceCensorWord(arr[0]) + "@" + "*".repeat(arr[1].length - 1);
}

export function isValidVersionNumber(str) {
    let patt = new RegExp("^(\\d+\\.)(\\d+\\.)?(\\*|\\d+)$");
    let isValid = patt.test(str);
    return isValid;
}

export function getDeviceImagePath(imageIdentifier, sku) {
    let path = "product-images/";
    if (sku === "") {
        path += "LegacyDevices/";
        path += imageIdentifier;
    } else {
        path += "Devices/";
        path += imageIdentifier + "_" + sku;
    }
    path += "_thumb.imageset/";
    path += "1x.png";
    return path;
}

export function getDeviceId(deviceName) {
    let deviceArray = [
        { deviceID: 2001, name: "ProXE" },
        { deviceID: 2002, name: "TourV5" },
        { deviceID: 2004, name: "Tour V5 International" },          // bd_2002_201911NT, bd_2002_201901D, bd_2004_201911NT
        { deviceID: 2002, name: "Tour V5 off course demo" },        // bd_2002_201901DEMO, bd_2002_201911DEMO, bd_2004_201911DEMO
        { deviceID: 2002, name: "Tour V5 SMU" },
        { deviceID: 2002, name: "Tour V5 patriot pack" },
        { deviceID: 2003, name: "Launch Pro" },
        { deviceID: 2004, name: "Tour V5 Shift" },                  // bd_2002_201911, bd_2004_201910, bd_2004_201911
        { deviceID: 2004, name: "Tour V5 Shift International" },    // bd_2002_201911D, bd_2004_201911D
        { deviceID: 2004, name: "Tour V5 Shift Patriot Pack" },     // bd_2002_201911P, bd_2004_201911P
        { deviceID: 2005, name: "Tour V6" },
        { deviceID: 2005, name: "Tour V6 Patriot Pack" },
        { deviceID: 2006, name: "Tour V6 Shift" },
        { deviceID: 2006, name: "Tour V6 Shift Patriot Pack" },
        { deviceID: 2007, name: "Tour VA" },
        { deviceID: 2008, name: "A1 Slope" },
        { deviceID: 3001, name: "Wingman" },
        { deviceID: 3002, name: "Wingman View" },
        { deviceID: 3003, name: "Wingman View Remote" },
        { deviceID: 3004, name: "Wingman View Speaker" },
        { deviceID: 3005, name: "Wingman Mini" },
        { deviceID: 3006, name: "Wingman 2" },
        { deviceID: 3007, name: "Wingman 2 Remote" },
        { deviceID: 3008, name: "Wingman 2 Speaker" },
        { deviceID: 4001, name: "Phantom 2" },
        { deviceID: 4002, name: "Ion Edge" },
        { deviceID: 4003, name: "Ion Elite" },
        { deviceID: 4004, name: "Phantom3" },
        { deviceID: 5001, name: "Pro X3" },
        { deviceID: 5002, name: "Pro X3+" },
        { deviceID: 1000, name: "Legacy Products" },
        { deviceID: 1001, name: "Phantom" },
        { deviceID: 1002, name: "Ion2 Watch" },
        { deviceID: 1003, name: "Excel Watch" },
        { deviceID: 1004, name: "Hybrid" },
        { deviceID: 1005, name: "Pro X2" },
        { deviceID: 1006, name: "Tour V4" },
        { deviceID: 1006, name: "Tour V4 Shift" },
        { deviceID: 1007, name: "L7" },
        { deviceID: 1009, name: "Other" }
    ];
    // let selectedItems = deviceArray.find((item) => item.name === deviceName); // Can't use since "Phantom 2" in doc show up as "Phantom2"
    let selectedItems = deviceArray.find((item) => item.name.replace(/\s+/g, '') === deviceName.replace(/\s+/g, ''));
    if (selectedItems) { return selectedItems.deviceID; }
    else { return ""; }
}

export function getSupportedDevices() {
    let SKUs = [
        { name: "Phantom 2", SKUs: ["362110", "362111", "362112", "362113", "362170A", "362171A", "362172A", "362173A"] },
        { name: "Phantom 3", SKUs: ["362401", "362402", "362403", "362404"] },
        { name: "Ion Edge", SKUs: ["362130", "362131", "362190A", "362191A"] },
        { name: "Ion Elite", SKUs: ["362150", "362151"] }
    ];
    return SKUs;
}

export function isLanguageSupportRequiredKRJP(deviceName, currentFirmwareVer) {
    // Device should be 'Phantom2' and curent device firmware version should <= KRJPVersion
    let isUpdateRequired = false;
    if (DeviceName_Phantom2 === deviceName && updateService.versionCompare(currentFirmwareVer, NonLangVersion_Phantom2) <= 0){
        isUpdateRequired = true;
        Log(`${deviceName} device; Korean and Japanees courses needed to update.`);
    }
    // else if (DeviceName_IonEdge === deviceName && updateService.versionCompare(currentFirmwareVer, NonLangVersion_IonEdge) <= 0){
    //     isUpdateRequired = true;
    //     Log(`${deviceName} device; Korean and Japanees courses needed to update.`);
    // }
    else {
        Log(`${deviceName} device; Korean and Japanees courses need not update.`);
    }
    return isUpdateRequired;
}

export function checkNonLanguageSupportedDevice(deviceName, firmwareVer) {
    let isLngCourseFileRequired = true;
    if (DeviceName_Phantom2 === deviceName && updateService.versionCompare(firmwareVer, NonLangVersion_Phantom2) <= 0){
        isLngCourseFileRequired = false;
    }
    // else if (DeviceName_IonEdge === deviceName && updateService.versionCompare(firmwareVer, NonLangVersion_IonEdge) <= 0){
    //     isLngCourseFileRequired = false;
    // }
    Log(`${deviceName} device; firmware version: ${firmwareVer}, course language file required : ${isLngCourseFileRequired}`);
    return isLngCourseFileRequired;
}

export function checkForceUpdateRequired(deviceName, localfirmwareVer) {
    let isForceUpdateRequired = false;
    if (DeviceName_Phantom2 === deviceName && updateService.versionCompare(localfirmwareVer, NonLangVersion_Phantom2) <= 0){
        isForceUpdateRequired = true;
    }
    // else if (DeviceName_IonEdge === deviceName && updateService.versionCompare(localfirmwareVer, NonLangVersion_IonEdge) <= 0){
    //     isForceUpdateRequired = true;
    // }
    Log(`${deviceName} device; firmware version: ${localfirmwareVer}, force update required : ${isForceUpdateRequired}`);
    return isForceUpdateRequired;
}

export function loadRecaptchaScript() {
    const configData = configService.getConfigData();
    const url = configData.Google_ReCaptcha_URL.replace("{GOOGLE_RECAPTCHA_SITE_KEY}", configData.Google_ReCaptcha_SiteKey) 
    const isScriptExist = document.getElementById(ScriptId);

    if (!isScriptExist) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = ScriptId;
      script.onload = function () {
        Log("ReCaptcha Script loaded!");
      };

      document.body.appendChild(script);
    } else {
        Log("ReCaptcha Script exist!");
    }
    
}

export function removeRecaptchaScript() {
    const recaptchaScript = document.getElementById(ScriptId);
    if (recaptchaScript) {
        recaptchaScript.remove();
        Log('ReCaptcha script tag removed.')
    }   
}

export function checkIpBlockingError(errorCode) {
    const ipBlockingErrorCodes = [1501, 1502, 1503, 1504]
    if (ipBlockingErrorCodes.includes(errorCode))
        return true
    else 
        return false
}