
var configData = {
    URL_BushnellAPI: "",
    AWS_APIKey: "",
    URL_CoursePackage: "",
    URL_CourseDataFile: "",
    URL_CourseImageFileHighEnd: "",
    URL_CourseData_PipeBugFix: "",
    URL_Blob_Scorecard: "",
    Google_ReCaptcha_SiteKey: "",
    Google_ReCaptcha_URL: "",
    FirmwareURL: {
        Firmware_Versions: "",
        FotaImage_Versions: "",
        FotaImage_Path: "",
        FotaReboot_Path: "",
        Fota_Image: "",
        Fota_Reboot: "",
        FotaSlope_Image: "",
        FotaSlope_Reboot: "",
        Font_File:""
    },
    ExternalLink: {
        PrivacyPolicy: "",
        TAndC: "",
        Newsletter: "",
        ContactSupport: ""
    },
    Flags: {
        ShowLanguageSelector: "",
        ShowCustomCourseUpdate: "",
        UpdateDeleteUnusedDataFiles: "",
        EnableDebugLog: "",
        EnableRestoreDevice: ""
    },
    LocalURL: {
        CountryList: "",
        StateList: "",
        CourseData_PipeBugFix: ""
    },
    CourseSearchURL:{
        SearchURL:"",
        RequestURL:""
    }
}

export function getConfigData() {
    return configData;
}

export function init() {
    let data = getConfigData();
    data.URL_BushnellAPI = process.env.REACT_APP_URL_BUSHNELLAPI;
    data.AWS_APIKey = process.env.REACT_APP_URL_AWSKEY;
    data.URL_CoursePackage = process.env.REACT_APP_URL_COURSEPACKAGE;
    data.URL_CourseDataFile = process.env.REACT_APP_URL_COURSEDATAFILE;
    data.URL_CourseImageFileHighEnd = process.env.REACT_APP_URL_COURSEIMAGEFILE_HIGHEND;
    data.URL_CourseData_PipeBugFix = process.env.REACT_APP_URL_CourseData_PipeBugFix;
    data.URL_Blob_Scorecard = process.env.REACT_APP_URL_Blob_Scorecard;

    data.Google_ReCaptcha_SiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
    data.Google_ReCaptcha_URL = process.env.REACT_APP_GOOGLE_RECAPTCHA_URL

    data.FirmwareURL.Firmware_Versions = process.env.REACT_APP_URL_FIRMWARE_VERSIONS;
    data.FirmwareURL.FotaImage_Versions = process.env.REACT_APP_URL_FIRMWARE_FOTAIMAGE_VERSIONS;
    data.FirmwareURL.FotaImage_Path = process.env.REACT_APP_URL_FIRMWARE_FOTAIMAGE_PATH;
    data.FirmwareURL.FotaReboot_Path = process.env.REACT_APP_URL_FIRMWARE_FOTAREBOOT_PATH;
    data.FirmwareURL.Fota_Image = process.env.REACT_APP_URL_FIRMWARE_FOTA_IMAGE;
    data.FirmwareURL.Fota_Reboot = process.env.REACT_APP_URL_FIRMWARE_FOTA_REBOOT;
    data.FirmwareURL.FotaSlope_Image = process.env.REACT_APP_URL_FIRMWARE_FOTASLOPE_IMAGE;
    data.FirmwareURL.FotaSlope_Reboot = process.env.REACT_APP_URL_FIRMWARE_FOTASLOPE_REBOOT;
    data.FirmwareURL.Font_File = process.env.REACT_APP_URL_FIRMWARE_FONT_FILE;

    data.ExternalLink.PrivacyPolicy = process.env.REACT_APP_URL_PrivacyPolicy;
    data.ExternalLink.TAndC = process.env.REACT_APP_URL_TAndC;
    data.ExternalLink.Newsletter = process.env.REACT_APP_URL_Newsletter;
    data.ExternalLink.ContactSupport = process.env.REACT_APP_URL_ContactSupport;

    data.Flags.ShowLanguageSelector = process.env.REACT_APP_FLAG_ShowLanguageSelector;
    data.Flags.ShowCustomCourseUpdate = process.env.REACT_APP_FLAG_ShowCustomCourseUpdate;
    data.Flags.UpdateDeleteUnusedDataFiles = process.env.REACT_APP_FLAG_UpdateDeleteUnusedDataFiles;
    data.Flags.EnableDebugLog = process.env.REACT_APP_FLAG_EnableDebugLog;
    data.Flags.EnableRestoreDevice = process.env.REACT_APP_FLAG_EnableRestoreDevice;

    data.LocalURL.CountryList = process.env.REACT_APP_URL_Local_CountryList;
    data.LocalURL.StateList = process.env.REACT_APP_URL_Local_StateList;

    data.CourseSearchURL.SearchURL = process.env.REACT_APP_COURSE_SEARCH_URL;
    data.CourseSearchURL.RequestURL = process.env.REACT_APP_COURSE_REQUEST_URL;

    configData = data;
}
