import React from 'react';
import * as langService from './services/langService';

class MessagePopup extends React.Component {
    render() {
        return (
            <div className="modal custom-pop fade" id="messagePopupModal" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group text-center">
                                        <label className="form-control-label" id="msgPopupLabel"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="btn-grp d-flex justify-content-center mt-0">
                                        <button type="button" className="btn_filld" data-dismiss="modal">{langService.t("Btn_OK")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MessagePopup;
