import React from "react";
import * as langService from '../services/langService';

class LogoutPopup extends React.Component {

    componentDidMount() {
        window.$("#logoutPopupModal").modal("show");
    }

    handleLogoutClicked = () => {
        this.props.logoutClicked(true);
    };

    render() {
        return (
            <div className="modal custom-pop fade" id="logoutPopupModal" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="btn-grp d-flex ">
                                        <label className="form-control-label" id="messagePopupLabel">
                                            {langService.t("Logout_Message")}
                                        </label>
                                    </div>
                                    <div className="btn-grp d-flex justify-content-end">
                                        <button type="button" className="btn btn-primary" onClick={this.handleLogoutClicked}>
                                            {langService.t("Btn_Logout")}
                                        </button>{" "}
                                        &nbsp;&nbsp;&nbsp;
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                            {langService.t("Btn_Cancel")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LogoutPopup;
