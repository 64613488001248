import React from "react";
import * as langService from '../services/langService';

class SwitchChannel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sendType: props.sendType,
        };
    }

    componentDidMount() {
        window.$("#verificationPopupModal").modal("show");
    }

    handleClickedOk = () => {
        this.props.clickedOk(true);
    };

    render() {
        return (
            <div className="modal custom-pop fade" id="verificationPopupModal" role="dialog" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="btn-grp d-flex ">
                                        <label className="form-control-label" id="messagePopupLabel">
                                            {
                                                langService.t("UserAccount_OTPLimit")
                                                .replace("{sendType1}", this.state.sendType === 1 ? "email address" : " phone number")
                                                .replace("{sendType2}", this.state.sendType === 1 ? "phone number" : " email address")
                                            }
                                        </label>
                                    </div>
                                    <div className="btn-grp d-flex justify-content-end">
                                        <button type="button" className="btn btn-primary" onClick={this.handleClickedOk}>
                                            {langService.t("Btn_OK")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SwitchChannel;
