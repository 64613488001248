import React from "react";
//import * as langService from './services/langService';

import UserHome from "./userAccount/UserHome";
import UpdateHome from "./updateProcess/UpdateHome";
import * as commonService from "./services/commonService";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentMode: "userHome", showLoader: false };
        this.nextForm = this.nextForm.bind(this);
    }

    componentDidMount = async () => {
        try {
            commonService.loadRecaptchaScript();
        } catch (err) {
            commonService.Log(err)
        }
    }

    handleShowLoader = (data) => {
        this.setState({ showLoader: data });
    };

    // returns the corresponding Form based on currentMode
    getForm(currentMode) {
        const forms = {
            userHome: <UserHome nextForm={this.nextForm} showLoader={this.handleShowLoader} />,
            updateHome: <UpdateHome nextForm={this.nextForm} />,
        };
        return forms[currentMode];
    }

    // update currentMode triggers the callback
    nextForm(mode) {
        if (mode === "LoginDone") mode = "updateHome";
        else if (mode === "LogoutDone") mode = "userHome";
        this.setState((state) => ({ currentMode: mode }));
    }

    render() {
        return (
            <div>
                {this.state.showLoader ? (
                    <div className="loader-screen">
                        <div className="loadingio-spinner-pulse-yd5dl03y9fi">
                            <div className="ldio-v55tnqe6ie">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {this.getForm(this.state.currentMode)}
            </div>
        );
    }
}

export default Home;
