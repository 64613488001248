import React from "react";
import * as langService from '../services/langService';

function ProgressList(props) {
    let isCourseAnimated = (window.updateStatus.course.progress > 0 && window.updateStatus.course.progress < 100);
    return (
        <ul>
            <li>
                <ProgressItem imageName="document_icon" text={langService.t("ProgressList_Verification")} status={window.updateStatus.download} isAnimated={false} />
            </li>
            {(props.isKRJPUpdate) ? (
                <>
                {(props.isFontUpdateRequired) ? (
                    <li>
                        <ProgressItem imageName="document_icon" text={langService.t("ProgressList_UpdateFontFile")} status={window.updateStatus.font} isAnimated={false} />
                    </li>
                ): (<></>) }
                <li>
                    <ProgressItem imageName="document_icon" text={langService.t("ProgressList_UpdateFirmware")} status={window.updateStatus.firmware} isAnimated={false} />
                </li>
                <li>
                    <ProgressItem imageName="document_icon" text={langService.t("ProgressList_UpdateCourse")} status={window.updateStatus.course} isAnimated={isCourseAnimated} />
                </li>
                </>
            ) : (
                <>
                {(props.isFontUpdateRequired) ? (
                    <li>
                        <ProgressItem imageName="document_icon" text={langService.t("ProgressList_UpdateFontFile")} status={window.updateStatus.font} isAnimated={false} />
                    </li>
                ): (<></>) }
                <li>
                    <ProgressItem imageName="document_icon" text={langService.t("ProgressList_UpdateCourse")} status={window.updateStatus.course} isAnimated={isCourseAnimated} />
                </li>
                <li>
                    <ProgressItem imageName="document_icon" text={langService.t("ProgressList_UpdateFirmware")} status={window.updateStatus.firmware} isAnimated={false} />
                </li>
                </>
            ) }
            <li>
                <ProgressItem imageName="document_icon" text={langService.t("ProgressList_CleanUp")} status={window.updateStatus.device} isAnimated={false} />
            </li>
        </ul>
    );
}

function ProgressItem(props) {
    let text = props.text;
    if (props.status.details) {
        text = text + " " + props.status.details;
    }
    return (
        <div className="uload-wrap">
            <div className="icon p-2 flex-shrink-1">
                <img alt={props.imageName} src={process.env.PUBLIC_URL + "/images/" + props.imageName + ".svg"} />
            </div>
            {
                props.isAnimated ?
                <ProgressBarAnimated text={text} progress={props.status.progress} /> :
                <ProgressBar text={text} progress={props.status.progress} />
            }
        </div>
    );
}

function ProgressBar(props) {
    return (
        <div className="progress-wrap p-2 w-100">
            <div className="d-flex justify-content-between">
                <p>{props.text}</p>
                <span>{props.progress}%</span>
            </div>
            <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: props.progress + "%" }} aria-valuenow={props.progress} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    );
}

function ProgressBarAnimated(props) {
    return (
        <div className="progress-wrap p-2 w-100">
            <div className="d-flex justify-content-between">
                <p>{props.text}</p>
                <span>{props.progress}%</span>
            </div>
            <div className="progress-animated">
                <div className="color"></div>
            </div>
        </div>
    );
}

export default ProgressList;
