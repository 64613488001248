import React from "react";

import Login from "./Login";
import Signup from "./Signup";
import Address from "./Address";
import Verification from "./Verification";
import VerifyCode from "./VerifyCode";
import Forgot from "./Forgot";
import ForgotOTP from "./ForgotOTP";
import ForgotOptions from "./ForgotOptions";
import ContactSupport from "./ContactSupport";

class UserProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextForm: props.nextForm,
            currentMode: "login",
            formData: props.formData,
            sendType: "",
            forgotEmail: "",
            showLoader: false,
            emailAttemptCount: 0,
            phoneAttemptCount: 0,
            sid: '',
        };
    }

    handleShowLoader = async (data) => {
        await this.setState({ showLoader: data });
        this.props.showLoader(data);
    };

    // returns the corresponding Form based on currentMode
    getForm(currentMode) {
        const forms = {
            login: <Login nextForm={this.nextForm} />,
            signup: <Signup nextForm={this.nextForm} formData={this.formData} showLoader={this.handleShowLoader} />,
            address: <Address nextForm={this.nextForm} formData={this.state.formData} showLoader={this.handleShowLoader} />,
            verification: (
                <Verification
                    nextForm={this.nextForm}
                    formData={this.state.formData}
                    sendType={this.sendTypeData}
                    showLoader={this.handleShowLoader}
                    allFormData={this.formData}
                    emailAttemptCount={this.handleEmailAttemptCount}
                    phoneAttemptCount={this.handlePhoneAttemptCount}
                    sid={this.handleSid}
                />
            ),
            verifyCode: (
                <VerifyCode
                    nextForm={this.nextForm}
                    formData={this.state.formData}
                    sendType={this.state.sendType}
                    showLoader={this.handleShowLoader}
                    emailAttemptCount={this.state.emailAttemptCount}
                    phoneAttemptCount={this.state.phoneAttemptCount}
                    sid={this.state.sid}
                />
            ),
            forgot: <Forgot nextForm={this.nextForm} setForgotEmail={this.setForgotEmail} showLoader={this.handleShowLoader} />,
            forgotOptions: (
                <ForgotOptions
                    nextForm={this.nextForm}
                    email={this.state.forgotEmail}
                    setForgotPhone={this.setForgotPhone}
                    showLoader={this.handleShowLoader}
                    sendType={this.sendTypeData}
                    emailAttemptCount={this.handleEmailAttemptCount}
                    phoneAttemptCount={this.handlePhoneAttemptCount}
                    sid={this.handleSid}
                />
            ),
            forgotOTP: (
                <ForgotOTP
                    nextForm={this.nextForm}
                    email={this.state.forgotEmail}
                    phone={this.state.forgotPhone}
                    showLoader={this.handleShowLoader}
                    sendType={this.state.sendType}
                    emailAttemptCount={this.state.emailAttemptCount}
                    phoneAttemptCount={this.state.phoneAttemptCount}
                    sid={this.state.sid}
                />
            ),
            contactSupport: <ContactSupport nextForm={this.nextForm} showLoader={this.handleShowLoader} />,
        };
        return forms[currentMode];
    }

    formData = (data) => {
        this.setState({ formData: data });
    };

    sendTypeData = async (data) => {
        await this.setState({ sendType: data });
    };

    handleEmailAttemptCount = async (data) => {
        await this.setState({ emailAttemptCount: data });
    };

    handlePhoneAttemptCount = async (data) => {
        await this.setState({ phoneAttemptCount: data });
    };

    setForgotEmail = (email) => {
        this.setState({ forgotEmail: email });
    };

    setForgotPhone = (phone) => {
        this.setState({ forgotPhone: phone });
    };

    handleSid = async (data) => {
        await this.setState({ sid: data });
    };

    // update currentMode triggers the callback
    nextForm = (mode) => {
        if (mode === "LoginDone") this.state.nextForm("LoginDone");
        else {
            if (mode === "NoAccount") mode = "signup";
            else if (mode === "Signup") mode = "verification";
            else if (mode === "Verification") mode = "verifyCode";
            else if (mode === "VerifyCode") mode = "address";
            else if (mode === "Address") mode = "login";
            else if (mode === "LogoutDone") mode = "login";
            else if (mode === "Forgot") mode = "forgot";
            else if (mode === "ForgotOptions") mode = "forgotOptions";
            else if (mode === "ForgotSubmit") mode = "forgotOTP";
            else if (mode === "contactSupport") mode = "contactSupport";
            this.setState((state) => ({ currentMode: mode }));
        }
    };

    render() {
        return <div>{this.getForm(this.state.currentMode)}</div>;
    }
}

export default UserProcess;
