import React from "react";
import * as langService from "../services/langService";
import * as bushnellAPI from "../services/bushnellAPIService.js";
import * as commonService from "../services/commonService.js";
import * as configDataService from "../services/configDataService";

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            countryCode: "",
            phoneNumber: "",
            email: "",
            password: "",
            privacyPolicy: false,
            newsletter: false,
            isEmailDuplicate: false,
            isPhoneDuplicate: false,
            invalidPhone: false,
            btnClicked: false,
            isSubmitted: false,
            nextForm: props.nextForm,
            formData: {
                firstName: "",
                lastName: "",
                countryCode: "",
                phoneNumber: "",
                email: "",
                password: "",
            },
        };
        this.inputFirstName = React.createRef();
        this.inputLastName = React.createRef();
        this.inputCountryCode = React.createRef();
        this.inputPhoneNumber = React.createRef();
        this.inputEmail = React.createRef();
        this.inputPassword = React.createRef();
        this.inputPrivacyPolicy = React.createRef();
    }

    componentDidMount() {
        this.inputFirstName.current.focus();
    }

    validateCountryCode = (countryCode) => {
        var regex = /(\+[1-9]\d*)/;
        var match = countryCode.match(regex);
        return match;
    };

    validateFormData() {
        if (this.state.firstName.trim() === "") {
            this.inputFirstName.current.focus();
        } else if (this.state.lastName.trim() === "") {
            this.inputLastName.current.focus();
        } else if (this.state.countryCode.trim() === "" || !this.validateCountryCode(this.state.countryCode)) {
            this.inputCountryCode.current.focus();
        } else if (this.state.phoneNumber.trim() === "") {
            this.inputPhoneNumber.current.focus();
        } else if (this.state.email.trim() === "") {
            this.inputEmail.current.focus();
        } else if (this.state.password.trim() === "" || this.state.password.length < 8) {
            this.inputPassword.current.focus();
        } else if (!this.state.privacyPolicy) {
            this.inputPrivacyPolicy.current.focus();
        }

        return this.state.firstName.trim() === "" ||
            this.state.lastName.trim() === "" ||
            this.state.countryCode.trim() === "" ||
            !this.validateCountryCode(this.state.countryCode) ||
            this.state.phoneNumber.trim() === "" ||
            this.state.email.trim() === "" ||
            this.state.password === "" ||
            this.state.password.length < 8 ||
            !this.state.privacyPolicy
            ? true
            : false;
    }

    handleFirstName = (value) => {
        this.setState({ firstName: value });
    };

    handleLastName = (value) => {
        this.setState({ lastName: value });
    };

    handleCountryCode = (value) => {
        this.setState({ countryCode: value });
    };

    handlePhoneNumber = (value) => {
        this.setState({ phoneNumber: value });
    };

    handleEmail = (value) => {
        this.setState({ email: value });
    };

    handlePassword = (value) => {
        this.setState({ password: value });
    };

    isValidCarrier(type) {
        let isValid = false;
        if (type == null) {
            isValid = true;
        }
        else if (type === "") {
            isValid = true;
        }
        else if (type === "mobile") {
            isValid = true;
        }
        return isValid;
    }

    handleValidateEmailAndPhone = async (type) => {
        try {
            this.setState({ btnClicked: true });
            let email = type === 1 ? this.state.email : "";
            let countryCode = type === 2 ? this.state.countryCode : "";
            let phoneNumber = type === 2 ? this.state.phoneNumber : "";
            await bushnellAPI.ValidateEmailAndPhone(email, countryCode, phoneNumber, type).then((status) => {
                if (!status.errorCode) {
                    // Stop if phoneNumber is not a mobile number
                    if (type === 2 && status.carrier && !this.isValidCarrier(status.carrier.type)) {
                        this.setState({ invalidPhone: true });
                        return false;
                    }
                    else {
                        this.setState({ btnClicked: false });
                        return true;
                    }
                } else if (status.errorCode === 400 && type === 2) {
                    this.setState({
                        invalidPhone: true,
                        btnClicked: false,
                    });
                    return false;
                } else {
                    let emailDupli = type === 1 ? true : false;
                    let phoneDupli = type === 2 ? true : false;
                    this.setState({
                        isEmailDuplicate: emailDupli,
                        isPhoneDuplicate: phoneDupli,
                        btnClicked: false,
                    });
                    return false;
                }
            });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        }
    };

    handleValidatePhoneNumber = async (type) => {
        try {
            this.setState({ btnClicked: true });
            let phoneNumber = this.state.countryCode + this.state.phoneNumber;
            await bushnellAPI.ValidatePhoneNumber(phoneNumber).then((status) => {
                if (status.errorCode) {
                    this.setState({ invalidPhone: true });
                } else {
                    this.setState({ invalidPhone: false });
                }
            });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        }
    };

    handleSubmitSignUp = async (event) => {
        event.preventDefault();
        try {
            this.setState({
                isEmailDuplicate: false,
                isPhoneDuplicate: false,
                btnClicked: true,
                isSubmitted: true,
            });
            this.props.showLoader(true);
            if (!this.validateFormData() && this.validateEmailAddress(this.state.email)) {
                await this.handleValidatePhoneNumber();
                if (!this.state.invalidPhone) {
                    try {
                        await this.handleValidateEmailAndPhone(1);
                        await this.handleValidateEmailAndPhone(2);
                        if (!this.state.isEmailDuplicate && !this.state.isPhoneDuplicate && !this.state.invalidPhone) {
                            this.props.formData({
                                firstName: this.state.firstName.trim(),
                                lastName: this.state.lastName.trim(),
                                countryCode: this.state.countryCode.trim(),
                                phoneNumber: this.state.phoneNumber.trim(),
                                email: this.state.email.trim(),
                                password: this.state.password,
                            });
                            this.props.nextForm("Signup");
                        }
                    } catch (err) {
                        commonService.Log(err);
                        commonService.showMessage(langService.tErrorMsg(err.message));
                        this.setState({ btnClicked: false });
                        this.props.showLoader(false);
                    }
                }
            }
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
            this.setState({ btnClicked: false });
            this.props.showLoader(false);
            return false;
        }
    };

    togglePassword() {
        var txtPass = document.getElementById("input-pwd");
        var iconPass = window.$(".toggle-password");
        if (txtPass.type === "password") {
            txtPass.type = "text";
            iconPass.removeClass("fa-eye");
            iconPass.addClass("fa-eye-slash");
        } else {
            txtPass.type = "password";
            iconPass.removeClass("fa-eye-slash");
            iconPass.addClass("fa-eye");
        }
    }

    validateEmailAddress = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return true;
        }
        return false;
    };

    backToLogin = () => {
        this.props.nextForm("LogoutDone");
    };

    render() {
        let eLinks = configDataService.getConfigData().ExternalLink;
        return (
            <div className="form-main">
                <div className="row">
                    <div className="col">
                        <h1>{langService.t("Txt_SignUp")}</h1>
                    </div>
                    <div className="col-auto text-right">
                        <a className="back_link" href="# " onClick={() => this.backToLogin()}>
                            <img alt="back" src="images/back.svg" />
                            {langService.t("Btn_Back")}
                        </a>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4">{langService.t("UserAccount_FirstName")}</label>
                        <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            onChange={(e) => this.handleFirstName(e.target.value)}
                            maxLength="50"
                            value={this.state.firstName}
                            ref={this.inputFirstName}
                        />
                        {this.state.isSubmitted && this.state.firstName.trim() === "" ? <span className="field-errors">{langService.t("UserAccount_NoFirstName")}</span> : ""}
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4">{langService.t("UserAccount_LastName")}</label>
                        <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            onChange={(e) => this.handleLastName(e.target.value)}
                            maxLength="50"
                            value={this.state.lastName}
                            ref={this.inputLastName}
                        />
                        {this.state.isSubmitted && this.state.lastName.trim() === "" ? <span className="field-errors">{langService.t("UserAccount_NoLastName")}</span> : ""}
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="inputEmail4">{langService.t("UserAccount_CountryCode")}</label>
                        <input
                            type="text"
                            name="countryCode"
                            className="form-control"
                            onChange={(e) => this.handleCountryCode(e.target.value)}
                            maxLength="5"
                            value={this.state.countryCode}
                            ref={this.inputCountryCode}
                        />
                        {this.state.isSubmitted && (this.state.countryCode.trim() === "" || !this.validateCountryCode(this.state.countryCode)) ? (
                            <span className="field-errors">{langService.t("UserAccount_NoCountryCode")}</span>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="inputEmail4">{langService.t("UserAccount_PhoneNumber")}</label>
                        <input
                            type="text"
                            name="phoneNumber"
                            className="form-control"
                            onChange={(e) => this.handlePhoneNumber(e.target.value)}
                            maxLength="15"
                            value={this.state.phoneNumber}
                            ref={this.inputPhoneNumber}
                        />
                        {this.state.isSubmitted && this.state.phoneNumber.trim() === "" ? (
                            <span className="field-errors">{langService.t("UserAccount_NoPhoneNumber")}</span>
                        ) : this.state.isSubmitted && this.state.isPhoneDuplicate ? (
                            <span className="field-errors">{langService.t("UserAccount_ExistPhone")}</span>
                        ) : this.state.isSubmitted && this.state.invalidPhone ? (
                            <span className="field-errors">{langService.t("UserAccount_InvalidPhone")}</span>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4">{langService.t("Txt_EmailAddress")}</label>
                        <input
                            type="text"
                            name="email"
                            className="form-control"
                            onChange={(e) => this.handleEmail(e.target.value)}
                            maxLength="200"
                            value={this.state.email}
                            ref={this.inputEmail}
                        />
                        {this.state.isSubmitted && this.state.email.trim() === "" ? (
                            <span className="field-errors">{langService.t("UserAccount_NoEmail")}</span>
                        ) : this.state.isSubmitted && this.state.isEmailDuplicate ? (
                            <span className="field-errors">{langService.t("UserAccount_ExistEmail")}</span>
                        ) : this.state.isSubmitted && !this.validateEmailAddress(this.state.email) ? (
                            <span className="field-errors">{langService.t("UserAccount_InvalidEmail")}</span>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="form-group position-relative">
                    <label htmlFor="exampleInputEmail1">{langService.t("Txt_Password")}</label>
                    <input
                        type="password"
                        name="pwd"
                        id="input-pwd"
                        className="form-control validate pr-4"
                        required
                        onChange={(e) => this.handlePassword(e.target.value)}
                        maxLength="50"
                        value={this.state.password}
                        ref={this.inputPassword}
                    />
                    <span onClick={(e) => this.togglePassword()}>
                        <span className="fa fa-fw fa-eye field-icon toggle-password"></span>
                    </span>
                    {this.state.isSubmitted && this.state.password === "" ? (
                        <span className="field-errors">{langService.t("UserAccount_NoPassword")}</span>
                    ) : this.state.isSubmitted && this.state.password.length < 8 ? (
                        <span className="field-errors">{langService.t("UserAccount_InvalidLengthPassword")}</span>
                    ) : (
                        ""
                    )}
                </div>
                <button className="btn btn-primary btn-block" onClick={this.handleSubmitSignUp} disabled={this.state.btnClicked}>
                    {langService.t("Btn_SignUp")}
                </button>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="md-checkbox chk-cstm mb-0">
                            <input id="i2" type="checkbox" onClick={(e) => this.setState({ privacyPolicy: e.target.checked })} ref={this.inputPrivacyPolicy} />
                            <label htmlFor="i2">
                                {langService.t("UserAccount_PrivacyPolicy_Part1")}
                                <a href={eLinks.PrivacyPolicy} target="_blank" rel="noreferrer">
                                    {langService.t("UserAccount_PrivacyPolicy_Part2")}
                                </a>
                                {langService.t("UserAccount_PrivacyPolicy_Part3")}
                                <a href={eLinks.TAndC} target="_blank" rel="noreferrer">
                                    {langService.t("UserAccount_PrivacyPolicy_Part4")}
                                </a>
                                {langService.t("UserAccount_PrivacyPolicy_Part5")}
                            </label>
                        </div>
                        {this.state.isSubmitted && !this.state.privacyPolicy ? (
                            <span className="field-errors">{langService.t("UserAccount_AgreePrivacyPolicy")}</span>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="md-checkbox chk-cstm mt-0">
                            <input id="i3" type="checkbox" onClick={(e) => this.setState({ newsletter: e.target.checked })} />
                            <label htmlFor="i3">
                                {langService.t("UserAccount_Newsletter_Part1")}
                                <a href={eLinks.Newsletter} target="_blank" rel="noreferrer">
                                    {langService.t("UserAccount_Newsletter_Part2")}
                                </a>
                                {langService.t("UserAccount_Newsletter_Part3")}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Signup;
