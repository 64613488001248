import * as commonService from "./commonService.js";

export async function downloadData_AWS(dataURL, AWSKey, isTar = false) {
    commonService.Log("Downloading : " + dataURL);
    let fileObject;
    if (isTar) {
        fileObject = await fetch(dataURL, {
            method: "get",
            headers: {
                "x-api-key": AWSKey,
                "Content-Type": "application/x-tar",
            },
        });
    }
    else {
        fileObject = await fetch(dataURL, {
            method: "get",
            headers: {
                "x-api-key": AWSKey,
            },
        });
    }
    if (fileObject.status === 200) commonService.Log("Download completed");
    else commonService.Log("Download failed : " + fileObject.status);
    return fileObject;
}

export async function downloadData(dataURL) {
    commonService.Log("Downloading : " + dataURL);
    let fileObject = await fetch(dataURL, {
        //mode: "no-cors",
        method: "get",
        //headers: {
        //    "Accept": "application/json"
        //}
    });
    if (fileObject.status === 200) commonService.Log("Download completed");
    else commonService.Log("Download failed : " + fileObject.status);
    return fileObject;
}

export async function downloadData_Bushnell(dataURL, APIHeaders, isPost, postData) {
    let resultObject = null;
    commonService.Log("Downloading : " + dataURL);
    if (isPost) {
        resultObject = await fetch(dataURL, {
            method: "post",
            body: JSON.stringify(postData),
            headers: APIHeaders,
        });
    } else {
        resultObject = await fetch(dataURL, {
            method: "get",
            headers: APIHeaders,
        });
    }
    if (resultObject.status === 200) commonService.Log("Download completed");
    else commonService.Log("Download failed : " + resultObject.status);
    return resultObject;
}
