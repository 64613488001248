// Dialog

export async function selectFolderPicker() {
    let result = null;
    try {
        result = await window.showDirectoryPicker();
    } catch (err) {}
    return result;
}

export async function openFilePicker(options) {
    let result = null;
    try {
        if (options) {
            result = await window.showOpenFilePicker(options);
        } else {
            result = await window.showOpenFilePicker();
        }
    } catch (err) {}
    return result;
}

export async function saveFilePicker(options) {
    let result = null;
    try {
        if (options) {
            result = await window.showSaveFilePicker(options);
        } else {
            result = await window.showSaveFilePicker();
        }
    } catch (err) {}
    return result;
}

// Folder

export async function selectChildFolder(parentFolder, childFolderName) {
    let result = null;
    try {
        result = await parentFolder.getDirectoryHandle(childFolderName);
    } catch (err) {}
    return result;
}

export async function childFolderExist(parentFolder, childFolderName) {
    let result = false;
    try {
        let folderHandle = await parentFolder.getDirectoryHandle(childFolderName);
        if (folderHandle != null) {
            result = true;
        }
    } catch (err) {}
    return result;
}

export async function createFolder(parentFolder, folderName) {
    await parentFolder.getDirectoryHandle(folderName, { create: true });
}

export async function deleteFolder(parentFolder, folderName) {
    await parentFolder.removeEntry(folderName, { recursive: true });
}

export async function getFolderContents(parentFolder) {
    let result = {
        files: [],
        directories: []
    };
    try {
        for await (let entry of parentFolder.values()) {
            if (entry.kind === "directory") {
                result.directories.push(entry.name);
            }
            else if (entry.kind === "file") {
                result.files.push(entry.name);
            }
        }
    } catch (err) {}
    return result;
}

// File

export async function fileExist(parentFolder, fileName) {
    let result = false;
    try {
        let fileHandle = await parentFolder.getFileHandle(fileName);
        if (fileHandle != null) {
            result = true;
        }
    } catch (err) {}
    return result;
}

export async function readFileData_Text(parentFolder, fileName) {
    let result = null;
    try {
        let fileHandle = await parentFolder.getFileHandle(fileName);
        let file = await fileHandle.getFile();
        result = await file.text();
    } catch (err) {}
    return result;
}

export async function readFileData_ArrayBuffer(parentFolder, fileName) {
    let result = null;
    try {
        let fileHandle = await parentFolder.getFileHandle(fileName);
        let file = await fileHandle.getFile();
        result = await file.arrayBuffer();
    } catch (err) {}
    return result;
}

export async function createFile(parentFolder, fileName, data) {
    const newFileHandle = await parentFolder.getFileHandle(fileName, {
        create: true,
    });
    const writable = await newFileHandle.createWritable();
    await writable.write(data);
    await writable.close();
}

export async function appendFile(parentFolder, fileName, data) {
    let fileHandle = await parentFolder.getFileHandle(fileName);
    let fileInfo = await fileHandle.getFile();
    let courseFileStream = await fileHandle.createWritable({
        keepExistingData: true,
    });
    await courseFileStream.write({
        type: "write",
        position: fileInfo.size,
        data: data,
    });
    await courseFileStream.close();
}

export async function rewriteFile(parentFolder, fileName, data) {
    let fileHandle = await parentFolder.getFileHandle(fileName);
    let courseFileStream = await fileHandle.createWritable({
        keepExistingData: false,
    });
    await courseFileStream.write({ type: "write", position: 0, data: data });
    await courseFileStream.close();
}

export async function deleteFile(parentFolder, fileName) {
    await parentFolder.removeEntry(fileName);
}

export async function createOrUpdateFile(parentFolder, fileName, data) {
    let exist;
    exist = await fileExist(parentFolder, fileName);
    if (exist) {
        await rewriteFile(parentFolder, fileName, data);
    } else {
        await createFile(parentFolder, fileName, data);
    }
}

export async function copyFile(fromFolder, fromFileName, toFolder, toFileName) {
    let exist = false;
    exist = fileExist(fromFolder, fromFileName);
    if (!exist) throw new Error("File not found : " + fromFileName);
    let data = await readFileData_ArrayBuffer(fromFolder, fromFileName);
    exist = await fileExist(toFolder, toFileName);
    if (exist) {
        await rewriteFile(toFolder, toFileName, data);
    } else {
        await createFile(toFolder, toFileName, data);
    }
}

export async function moveFile(fromFolder, fileName, toFolder) {
    await copyFile(fromFolder, fileName, toFolder, fileName);
    await deleteFile(fromFolder, fileName);
}

// Request Permissions

export async function getReadWritePermission(handle) {
    const options = {};
    options.mode = "readwrite";
    // Check if permission was already granted. If so, return true.
    if ((await handle.queryPermission(options)) === "granted") {
        return true;
    }
    // Request permission. If the user grants permission, return true.
    if ((await handle.requestPermission(options)) === "granted") {
        return true;
    }
    // The user didn't grant permission, so return false.
    return false;
}
