import React from "react";
import * as langService from "../services/langService";
import * as bushnellAPI from "../services/bushnellAPIService.js";
import * as commonService from "../services/commonService.js";
import * as sessionService from "../services/sessionService.js";

class Login extends React.Component {
    constructor(props) {
        super(props);
        let userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : "";
        this.state = {
            nextForm: props.nextForm,
            email: userData.rememberMe ? userData.email : "",
            password: userData.rememberMe ? userData.password : "",
            rememberMe: userData.rememberMe ? userData.rememberMe : false,
            btnClicked: false,
            isSubmitted: false,
            invalidPassword: false,
        };
        let haveToken = sessionService.isValidAccessToken();
        if (haveToken) {
            this.state.nextForm("LoginDone");
        } else {
            sessionService.logout();
        }
        this.inputEmail = React.createRef();
        this.inputPassword = React.createRef();
    }

    componentDidMount() {
        this.inputEmail.current.focus();
        document.body.classList.add("bg-login");
    }

    handleChangeEmail(value) {
        this.setState({
            email: value.trim(),
        });
    }

    handleChangePassword(value) {
        this.setState({
            password: value,
        });
    }

    handleLoginDone() {
        this.state.nextForm("LoginDone");
    }

    handleSignup() {
        this.state.nextForm("NoAccount");
    }

    handleForgotPassword() {
        this.state.nextForm("Forgot");
    }

    validateFormData() {
        if (this.state.email === "") {
            this.inputEmail.current.focus();
        } else if (this.state.password === "") {
            this.inputPassword.current.focus();
        }
        return this.state.email === "" || this.state.password === "" ? true : false;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        try {
            this.setState({
                isSubmitted: true,
                invalidPassword: false,
            });
            if (!this.validateFormData() && this.validateEmailAddress(this.state.email)) {
                let rmCheck = document.getElementById("rememberMe");
                let emailInput = document.getElementById("email");
                if (rmCheck.checked) {
                    localStorage.setItem(
                        "userData",
                        JSON.stringify({
                            email: emailInput.value,
                            password: "",
                            rememberMe: rmCheck.checked,
                        })
                    );
                } else {
                    localStorage.removeItem("userData");
                }
                this.setState({ btnClicked: true });
                commonService.showLoader(true);
                let password = this.state.password;

                bushnellAPI.Login(this.state.email, password).then(responseData => {
                    commonService.showLoader(false);
                    this.setState({ btnClicked: false });
                    if (responseData.errorCode === 0) {
                        this.setState({ btnClicked: false, password: "" });
                        this.handleLoginDone();
                    } else {
                        if (responseData.errorCode === 401 || responseData.errorCode !== 412) {
                            this.setState({ invalidPassword: true });
                        } else {
                            commonService.showMessage(responseData.status);
                        }
                    }
                })
                .catch(err => {
                    commonService.showLoader(false);
                    this.setState({ btnClicked: false });
                    commonService.Log(err);
                    commonService.showMessage(langService.tErrorMsg(err.message));
                });
            }
        } catch (err) {
            commonService.showLoader(false);
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
            this.setState({ btnClicked: false });
        }
    };

    togglePassword() {
        var txtPass = document.getElementById("password");
        var iconPass = window.$(".toggle-password");
        if (txtPass.type === "password") {
            txtPass.type = "text";
            iconPass.removeClass("fa-eye");
            iconPass.addClass("fa-eye-slash");
        } else {
            txtPass.type = "password";
            iconPass.removeClass("fa-eye-slash");
            iconPass.addClass("fa-eye");
        }
    }

    validateEmailAddress = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return true;
        }
        return false;
    };

    handleRememberMe = (e) => {
        this.setState({ rememberMe: e.target.checked });
    };

    handleContactSupport = () => {
        this.state.nextForm("contactSupport");
    };

    render() {
        return (
            <div className="form-main">
                <h1>{langService.t("Txt_Login")}</h1>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">{langService.t("Txt_EmailAddress")}</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            aria-describedby="emailHelp"
                            placeholder=""
                            value={this.state.email}
                            onChange={(e) => this.handleChangeEmail(e.target.value)}
                            maxLength="200"
                            ref={this.inputEmail}
                        />
                    </div>
                    {this.state.isSubmitted && this.state.email === "" ? (
                        <div className="field-errors mt-10">{langService.t("UserAccount_NoEmail")}</div>
                    ) : this.state.isSubmitted && this.state.isEmailDuplicate ? (
                        <div className="field-errors mt-10">{langService.t("UserAccount_ExistEmail")}</div>
                    ) : this.state.isSubmitted && !this.validateEmailAddress(this.state.email) ? (
                        <div className="field-errors mt-10">{langService.t("UserAccount_InvalidEmail")}</div>
                    ) : (
                        ""
                    )}
                    <div className="form-group position-relative">
                        <label htmlFor="exampleInputEmail1">{langService.t("Txt_Password")}</label>
                        <input
                            type="password"
                            name="pwd"
                            id="password"
                            className="form-control validate"
                            onChange={(e) => this.handleChangePassword(e.target.value)}
                            value={this.state.password}
                            maxLength="50"
                            ref={this.inputPassword}
                        />
                        <span onClick={(e) => this.togglePassword()}>
                            <span className="fa fa-fw fa-eye field-icon toggle-password"></span>
                        </span>
                    </div>
                    {this.state.isSubmitted && this.state.password === "" ? (
                        <div className="field-errors mt-10">{langService.t("UserAccount_NoPassword")}</div>
                    ) : this.state.invalidPassword ? (
                        <div className="field-errors mt-10">{langService.t("UserAccount_InvalidCredentials")}</div>
                    ) : (
                        ""
                    )}
                    <button type="submit" className="btn btn-primary btn-block" disabled={this.state.btnClicked}>
                        {langService.t("Txt_Login")}
                    </button>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="md-checkbox">
                                <input id="rememberMe" type="checkbox" onChange={this.handleRememberMe} checked={this.state.rememberMe} />
                                <label htmlFor="rememberMe">{langService.t("Txt_RememberMe")}</label>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 forgot">
                            <a onClick={(e) => this.handleForgotPassword()} href="# ">
                                {langService.t("Txt_ForgotPassword")}
                            </a>
                        </div>
                    </div>
                    <div className="sign-up mt-3">
                        <p>
                            {langService.t("Txt_NoAccount")}{" "}
                            <a onClick={(e) => this.handleSignup()} href="# ">
                                {langService.t("Txt_SignUp")}
                            </a>
                        </p>
                    </div>
                </form>
            </div>
        );
    }
}

export default Login;
