import React from "react";
import * as langService from '../services/langService';
import * as commonService from "../services/commonService";
import * as updateManager from "../services/updateManager";
import * as courseSearchService from "../services/courseSearchService";

class CourseSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextForm: props.nextForm,
            searchItems: [],
            selectedItems: [],
            selectAll: false,
            showSelectedPopup: false,
            selectedCountry:'',
            updateAllCourse: false,
            disabledState: ""
        };
    };

    handleNext = () => {
        if (!this.state.updateAllCourse && this.state.selectedItems.length === 0) {
            commonService.showMessage(langService.t("CourseSearch_NoRecordsSelected"));
        } else {
            try {
                commonService.showLoader(true);
                if(this.state.updateAllCourse)
                    updateManager.setFullCourseUpdate();
                else
                    updateManager.setPartialCourses(this.state.selectedItems);
                commonService.showLoader(false);
                this.state.nextForm("UpdateStep3");
            } catch (err) {
                commonService.showLoader(false);
                commonService.Log(err);
                commonService.showMessage(langService.tErrorMsg(err.message));
            }
        }
    };

    handleCancel = () => {
        this.state.nextForm("UpdateStep2");
    };

    handleOnSelectAll = () => {
        let value = this.state.selectAll;
        value = !value;
        this.toggleSelectAll(value);
    };

    handleShowSelectedPopup = () => {
        this.setState({
            selectAll: false,
            showSelectedPopup: true
        }, () => {
            window.$("#selectedCourses").modal("show");
        });
    }

    hideSelectedCoursePopup = () => {
        window.$("#selectedCourses").modal("hide");
        window.$("#selectedCourses").on("hidden.bs.modal", () => { this.setState({ showSelectedPopup: false }) });
    }

    toggleSelectAll(checkFlag) {
        let i;
        commonService.showLoader(true);
        let searchItems = this.state.searchItems;
        let selectItems = this.state.selectedItems;
        // Remove all search items from select items
        for (i = 0; i < searchItems.length; i++) {
            let selectedId = searchItems[i].cid;
            selectItems = selectItems.filter(item => item.cid !== selectedId);
        }
        if (checkFlag) {
            // Add all search items from select items
            for (i = 0; i < searchItems.length; i++) {
                selectItems.push(this.copyCourse(searchItems[i]));
            }
        }
        this.setState({
            selectedItems: selectItems,
            selectAll: checkFlag
        }, () => {
            commonService.showLoader(false);
        });
    };

    searchCourse = async (country, stateCode, city, courseName) => {
        try {
            commonService.showLoader(true);
            let searchData = await courseSearchService.downloadData_CourseSearchFilter(country, stateCode, city, courseName);
            this.setState({
                searchItems: searchData,
                selectAll: false,
                selectedCountry: country
            });
            commonService.showLoader(false);
        } catch (err) {
            commonService.showLoader(false);
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    };

    copyCourse(item) {
        let str = JSON.stringify(item);
        return JSON.parse(str);
    };

    selectCourse(cid, flag) {
        let checkFlag = this.state.selectAll;
        let searchItems = this.state.searchItems;
        let selectItems = this.state.selectedItems;
        if (flag) {
            for (let i = 0; i < searchItems.length; i++) {
                if (searchItems[i].cid === cid) {
                    selectItems.push(this.copyCourse(searchItems[i]));
                    break;
                }
            }
        }
        else {
            checkFlag = false;
            selectItems = selectItems.filter(item => item.cid !== cid);
        }
        this.setState({
            selectedItems: selectItems,
            selectAll: checkFlag
        });
    };

    removeCourse(cid) {
        this.selectCourse(cid, false);
    };

    removeAllCourses() {
        window.$("#selectedCourses").modal("hide");
        this.setState({
            selectedItems: [],
            showSelectedPopup: false
        });
    }

    isSelected(cid) {
        let items = this.state.selectedItems.filter(item => item.cid === cid);
        if (items.length > 0) {
            return true;
        }
        else {
            return false;
        }
    };

    updateAllCourses = (update) => {
        const isDisabled = update? "disabled" : "";
        this.setState({
            searchItems: [],
            selectedItems: [],
            selectAll: false,
            updateAllCourse: update,
            disabledState: isDisabled
        })
    };

    getRemainingTime(obj, approxUpdateTime) {
        let result = "";
        if (obj.newVersion) {
            result = langService.t("UpdateProcess_ApproxTime").replace("{time}", approxUpdateTime);
        }
        return result;
    }

    updateList = () => {
        if(this.state.searchItems.length === 0){
            return (
                <tr>
                    <td colSpan={5}>
                        <div className="emptyData">{langService.t("CourseSearch_NoRecords")}</div>
                    </td>
                </tr> 
            );
        } else {
            if( (langService.getLanguage() === 'ja' && this.state.selectedCountry === 'JP') || (langService.getLanguage() === 'ko' && this.state.selectedCountry === 'KR') ){
                return (
                    this.state.searchItems.map((item, index) => {
                        let checked = this.isSelected(item.cid);
                        return (
                            <tr key={item.cid}>
                                <td><CheckBox id={item.cid} value={checked} onChange={() => this.selectCourse(item.cid, !checked)} /></td>
                                <td className="text-left">{item.nam_nl}</td>
                                <td className="text-left">{item.adr_nl}</td>
                                <td className="text-left">{item.cit_nl}</td>
                                <td className="text-left">{item.zip}</td>
                            </tr>
                        );
                    })
                );
            } else {
                return (
                    this.state.searchItems.map((item, index) => {
                        let checked = this.isSelected(item.cid);
                        return (
                            <tr key={item.cid}>
                                <td><CheckBox id={item.cid} value={checked} onChange={() => this.selectCourse(item.cid, !checked)} /></td>
                                <td className="text-left">{item.nam}</td>
                                <td className="text-left">{item.adr}</td>
                                <td className="text-left">{item.cit}</td>
                                <td className="text-left">{item.zip}</td>
                            </tr>
                        );
                    })
                );
            }
        }
    }

    searchResult = () => {
        if(this.state.disabledState){
            let deviceData = updateManager.getVersionData();
            return (
                <div className="content-txt">
                    {langService.t("UpdateProcess_CourseVersion")}: {deviceData.course.serverVersion} {this.getRemainingTime(deviceData.course, deviceData.approxUpdateTime)}
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="header-course">
                            <div className="row">
                                <div className="col">
                                    <h1>{langService.t("CourseSearch_Courses")}</h1>
                                </div>
                                <div className="col-12 col-md-auto">
                                    {
                                        this.state.selectAll ?
                                        <a className={this.state.disabledState ? "list-btn list-btn-disabled mb-2 mr-2" : "list-btn mb-2 mr-2"} href="# " id="CheckDeselectAll" onClick={() => this.handleOnSelectAll()} ><i className="far fa-window-close"></i>{langService.t("CourseSearch_DeselectAll")}</a> :
                                        <a className={this.state.disabledState ? "list-btn list-btn-disabled mb-2 mr-2" : "list-btn mb-2 mr-2"} href="# " id="CheckSelectAll" onClick={() => this.handleOnSelectAll()} ><i className="far fa-check-square"></i>{langService.t("CourseSearch_SelectAll")}</a>
                                    }
                                    <a className={this.state.disabledState ? "list-btn list-btn-disabled" : "list-btn"}  href="# " onClick={() => this.handleShowSelectedPopup()}><i className="fas fa-list"></i>{langService.t("CourseSearch_SelectedCoursesCount").replace("{count}", this.state.selectedItems.length)}</a>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive scroll-h450 border-btm mt-3">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap" style={{ width: 40 + 'px' }}>
                                            <CheckBox id="checkSelectAll" value={this.state.selectAll} onChange={this.handleOnSelectAll} />
                                        </th>
                                        <th className="text-nowrap">{langService.t("CourseSearch_CourseName")}</th>
                                        <th className="text-left text-nowrap">{langService.t("CourseSearch_CourseAddress")}</th>
                                        <th className="text-left text-nowrap">{langService.t("CourseSearch_City")}</th>
                                        <th className="text-nowrap">{langService.t("CourseSearch_Zip")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.updateList() }
                                </tbody>
                            </table>
                        </div>
                </React.Fragment>
            );
        }
    }
    render() {
        return (
            <div className="container-fluid mt-4">
                <FilterBar searchClick={ async (country, stateCode, city, courseName) => { await this.searchCourse(country, stateCode, city, courseName); } } 
                        updateAllCourses = {(update) => {this.updateAllCourses(update);}} />
                <div className="co-bx form-cstm">
                    { this.searchResult() }
                    <div className="button-wrap">
                        <button className="secondary" onClick={() => this.handleCancel()}>{langService.t("Btn_Cancel")}</button>
                        <button className="primary" onClick={() => this.handleNext()}>{langService.t("Btn_Done")}</button>
                    </div>
                </div>
                {
                    this.state.showSelectedPopup ?
                    <SelectedCourses items={this.state.selectedItems} removeCourse={(cid) => this.removeCourse(cid)} removeAllCourses={() => this.removeAllCourses()} hideSelectedCoursePopup={() => this.hideSelectedCoursePopup()} /> :
                    null
                }
            </div>
        );
    }
}

class FilterBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countryData: null,
            stateData: null,

            country: "",
            city: "",
            stateCode: "",
            stateName: "",
            courseName: "",
            disabledState: "",
            updateAllCourse: false
        };
        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
    };

    componentDidMount = async () => {
        try {
            commonService.showLoader(true);
            await this.storeCountryData();
            commonService.showLoader(false);
        } catch (err) {
            commonService.showLoader(false);
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    };

    handleChangeCountry = (value) => {
        let newState = this.getStateByCountryCode(this.state.stateData.states, value);
        let disabled = "";
        let updateAllCourse = false;
        if (value === 'SELECT' || value === 'ALL'){
            disabled = "disabled";
        }
        if(value === "ALL")
            updateAllCourse = true;

        this.setState({ country: value, stateCode: newState.code, stateName: newState.name, disabledState: disabled, updateAllCourse: updateAllCourse });
        this.props.updateAllCourses(updateAllCourse);
    };

    handleChangeState(value) {
        this.setState({ stateCode: value, stateName: value });
    };

    handleChangeCity(value) {
        this.setState({ city: value });
    };

    handleChangeCourseName(value) {
        this.setState({ courseName: value });
    };

    getStateByCountryCode(states, countryCode) {
        var filterData = states.filter(function (el) {
            return el.countryCode === countryCode;
        });
        if (filterData.length !== 0) {
            return { code: filterData[0].code, name: filterData[0].name };
        }
        else {
            return { code: "", name: "" };
        }
    };

    storeCountryData = async () => {
        let data = this.state.countryData;
        if (!data) {
            let csData = await courseSearchService.getCountryStateData();
            let cData = csData.countryData;
            let sData = csData.stateData;
            let newState = this.getStateByCountryCode(sData.states, cData.countries[0].code);
            let disabled = "";
            if (cData.countries[0].code === 'SELECT' || cData.countries[0].code === 'ALL'){
                disabled = "disabled";
            }
            this.setState({
                countryData: cData,
                country: cData.countries[0].code,
                stateData: sData,
                stateCode: newState.code,
                stateName: newState.name,
                disabledState: disabled
            });
        }
    };

    searchCourse() {
        this.props.searchClick(this.state.country, this.state.stateCode, this.state.city, this.state.courseName);
    };

    render() {
        let selectCountry = "";
        let cData = this.state.countryData;
        if (cData) {
            selectCountry = <SelectList handleChange={this.handleChangeCountry} value={this.state.country} dataList={cData.countries} />;
        } else {
            selectCountry = "";
        }
        let selectState = "";
        let sData = this.state.stateData;
        let countryCode = this.state.country;
        if (sData) {
            var filterData = sData.states.filter(function (el) {
                return el.countryCode === countryCode;
            });
            if (filterData.length === 0) {
                selectState = <input type="text" className="form-control" name="stateName" value={this.state.stateName} onChange={(e) => this.handleChangeState(e.target.value)} disabled />;
            } else {
                selectState = <SelectList handleChange={this.handleChangeState} value={this.state.stateName} dataList={filterData} />;
            }
        } else {
            selectState = "";
        }

        return (
            <div className="co-bx search-bx mb-3">
                <div className="row">
                    <div className="col-lg-12">
                        <form>
                            <div className="form-row">
                                <div className="form-group col-md-6 col-lg-4">
                                    {selectCountry}
                                </div>
                                <div className="form-group col-md-6 col-lg-4">
                                    {selectState}
                                </div>
                                <div className="form-group col-md-6 col-lg-4">
                                    <input type="input" className="form-control" placeholder={langService.t("CourseSearch_PHCity")} onKeyDown={(e) => (e.key === 'Enter' ? this.searchCourse() : null)} onChange={(e) => this.handleChangeCity(e.target.value)} disabled={this.state.disabledState}  />
                                </div>
                                <div className="form-group col-md-6 col-lg-12 col-xl-12">
                                    <div className="search-wrap">
                                        <input type="input" className="form-control" placeholder={langService.t("CourseSearch_PHNameOrZip")} onKeyDown={(e) => (e.key === 'Enter' ? this.searchCourse() : null)} onChange={(e) => this.handleChangeCourseName(e.target.value)} disabled={this.state.disabledState} />
                                        <button type="button" className="btn-search" onClick={ () => { this.searchCourse(); } } disabled={this.state.disabledState}>{langService.t("Btn_Search")}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function SelectList(props) {
    return (
        <select className="form-control" value={props.value} onChange={(e) => props.handleChange(e.target.value)}>
            {props.dataList.map(function (item) {
                return (
                    <option key={item.code} value={item.code}>
                        {item.name}
                    </option>
                );
            })}
        </select>
    );
}

function CheckBox(props) {
    return (
        <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id={"customCheck" + props.id} checked={props.value} onChange={() => props.onChange(props.id)} />
            <label className="custom-control-label" htmlFor={"customCheck" + props.id}>{props.value}</label>
        </div>
    );
}

function SelectedCourses(props) {
    const updateList = () => {
        if (props.items.length === 0 ) {
            <tr>
                <td colSpan={5}>
                    <div className="emptyData">{langService.t("CourseSearch_NoRecordsSelected")}</div>
                </td>
            </tr> 
        } else {
            return (
                props.items.map((item) => {
                    if( (langService.getLanguage() === 'ja' && item.cny === 'JP') || (langService.getLanguage() === 'ko' && item.cny === 'KR') ){
                        return (
                            <tr key={item.cid}>
                                <td className="text-left">{item.nam_nl}</td>
                                <td className="text-left">{item.adr_nl}</td>
                                <td className="text-left">{item.cit_nl}</td>
                                <td className="text-left">{item.zip}</td>
                                <td><a className="link-td" href="# " onClick={() => props.removeCourse(item.cid)}><i className="far fa-trash-alt"></i></a></td>
                            </tr>
                        );
                    } else {
                        return (
                            <tr key={item.cid}>
                                <td className="text-left">{item.nam}</td>
                                <td className="text-left">{item.adr}</td>
                                <td className="text-left">{item.cit}</td>
                                <td className="text-left">{item.zip}</td>
                                <td><a className="link-td" href="# " onClick={() => props.removeCourse(item.cid)}><i className="far fa-trash-alt"></i></a></td>
                            </tr>
                        );
                    }
                })
            )
        }
    }
    return (
        <div className="modal custom-pop fade" id="selectedCourses" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered model-768" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{langService.t("CourseSearch_SelectedCourses")}</h5>
                        <button type="button" className="close" aria-label="Close" onClick={() => props.hideSelectedCoursePopup()}>
                            <span aria-hidden="true"><img alt="Close" src="images/close_icon.svg" /></span>
                        </button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="table-responsive scroll-h450 border-btm">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap">{langService.t("CourseSearch_CourseName")}</th>
                                        <th className="text-left text-nowrap">{langService.t("CourseSearch_CourseAddress")}</th>
                                        <th className="text-left text-nowrap">{langService.t("CourseSearch_City")}</th>
                                        <th className="text-nowrap">{langService.t("CourseSearch_Zip")}</th>
                                        <th><a className="link-td" href="# " onClick={() => props.removeAllCourses()}><i className="far fa-trash-alt"></i></a></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { updateList() }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseSearch;
