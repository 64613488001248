import React from 'react'
import * as langService from '../services/langService'
import * as bushnellAPI from '../services/bushnellAPIService.js'
import * as commonService from '../services/commonService.js'
import * as configService from '../services/configDataService'

class ForgotOptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      verificationCode: '',
      password: '',
      nextForm: props.nextForm,
      isOTPError: false,
      btnClicked: false,
      email: props.email,
      phone: '',
      countryCode: '',
      emailVerified: false,
      phoneVerified: false,
      sendType: '',
      sendTypeChecked: 0,
      sid: '',
      captchaAction: 'ForgotPassword',
    }
  }

  componentDidMount = async () => {
    try {
      let verifiedData = await bushnellAPI.GetVerifiedChannels(this.state.email)
      if (verifiedData.status === 200) {
        let verifiedJson = await verifiedData.json()
        this.setState({
          emailVerified: verifiedJson.emailVerified,
          phoneVerified: verifiedJson.phoneVerified,
          countryCode: verifiedJson.countryCode,
          phone: verifiedJson.phone,
          sendTypeChecked:
            (verifiedJson.emailVerified && verifiedJson.phoneVerified) ||
            (verifiedJson.emailVerified && !verifiedJson.phoneVerified)
              ? 1
              : 2,
        })
      }
    } catch (err) {
      commonService.Log(err)
      commonService.showMessage(langService.tErrorMsg(err.message))
    }
  }

  handleBack() {
    this.state.nextForm('LogoutDone')
  }

  togglePassword() {
    var txtPass = document.getElementById('input-pwd')
    var iconPass = window.$('.toggle-password')
    if (txtPass.type === 'password') {
      txtPass.type = 'text'
      iconPass.removeClass('fa-eye')
      iconPass.addClass('fa-eye-slash')
    } else {
      txtPass.type = 'password'
      iconPass.removeClass('fa-eye-slash')
      iconPass.addClass('fa-eye')
    }
  }

  handlePassword = (value) => {
    this.setState({ password: value })
  }

  validateFormData() {
    return this.state.sendTypeChecked === 1 || this.state.sendTypeChecked === 2
      ? false
      : true
  }

  handleOTPVerification = async (captchaToken) => {
    try { 
      this.setState({ isOTPError: false, btnClicked: true })
      this.props.showLoader(true)
      if (!this.validateFormData()) {
        let to = this.state.email
        await bushnellAPI
          .SendVerificationCode(to, this.state.sendTypeChecked, captchaToken, this.state.captchaAction)
          .then(async (response) => {
              let jsonData = await response.json()
              if (jsonData.gateWayErrorCode === 0) {
                this.props.sendType(this.state.sendTypeChecked)
                await this.setState({ 
                  sid: jsonData.sid, 
                })
                if (jsonData.send_code_attempts.length > 3) {
                  this.props.sendType(this.state.sendTypeChecked === 1 ? 2 : 1)
                }
                if (this.state.sendTypeChecked === 1) {
                  await this.setState({
                    emailAttemptCount: jsonData.send_code_attempts.length,
                  })
                } else {
                  await this.setState({
                    phoneAttemptCount: jsonData.send_code_attempts.length,
                  })
                }
                if (this.state.sendTypeChecked === 1) {
                  this.props.emailAttemptCount(this.state.emailAttemptCount)
                } else {
                  this.props.phoneAttemptCount(this.state.phoneAttemptCount)
                }
                this.props.setForgotPhone(
                  this.state.countryCode + this.state.phoneNumber,
                )
                this.props.sendType(this.state.sendTypeChecked)
                this.props.sid(this.state.sid)
                this.props.nextForm('ForgotSubmit')
              } else if (jsonData.gateWayErrorCode) {
                this.setState({
                    gateWayErrorCode: jsonData.gateWayErrorCode,
                });
                commonService.Log(jsonData.gateWayErrorCode);
                commonService.showMessage(langService.tErrorMsg(jsonData.gateWayMessage));
                return false;
              } else if (jsonData.errorCode) {
                if (commonService.checkIpBlockingError(jsonData.errorCode)){
                    commonService.Log(`${jsonData.errorCode} - ${langService.tErrorMsg("OTP_IP_Blocking")} `);
                    commonService.showMessage(langService.tErrorMsg("OTP_IP_Blocking"));
                } else {
                    commonService.Log(`${jsonData.errorCode} - ${langService.tErrorMsg("UserAccount_OTPSentFailed")} `);
                    commonService.showMessage(langService.tErrorMsg("UserAccount_OTPSentFailed"));
                }
                return false;
              } else {
                commonService.Log(jsonData)
                commonService.showMessage(langService.tErrorMsg("UserAccount_OTPSentFailed"))
                return false
              }
          })
      }
      this.setState({ btnClicked: false })
      this.props.showLoader(false)
    } catch (err) {
      this.setState({ btnClicked: false })
      this.props.showLoader(false)
      commonService.Log(err)
      commonService.showMessage(langService.tErrorMsg(err.message))
    }
  }

  handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const configData = configService.getConfigData();
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(configData.Google_ReCaptcha_SiteKey, { action: this.state.captchaAction })
        .then(token => {
            this.handleOTPVerification(token);
        });
      });
    } catch (err) {
        commonService.Log(err);
        commonService.showMessage(langService.tErrorMsg(err.message));
    }
  };

  handleVerificationType = async (type, val) => {
    type === 1
      ? await this.setState({ sendTypeChecked: type, email: val })
      : await this.setState({ sendTypeChecked: type, phone: val })
  }

  render() {
    return (
      <div className="form-main verify">
        <h1>{langService.t('UserAccount_ForgotPassword')}</h1>
        <p className="content">
          {langService.t('UserAccount_ResetInstructions')}
        </p>

        <div className="form-group cstm-radio">
          {this.state.emailVerified ? (
            <div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  onChange={() =>
                    this.handleVerificationType(1, this.state.email)
                  }
                  checked={this.state.sendTypeChecked === 1 ? true : false}
                />
                <label className="form-check-label pr-3" htmlFor="inlineRadio1">
                  {langService.t('UserAccount_Email')}
                </label>
              </div>
              <span className="inputspan">
                {commonService.replaceCensorEmail(this.state.email)}
              </span>
            </div>
          ) : (
            ''
          )}
          {this.state.phoneVerified ? (
            <div className="form-check form-check-inline mt-4">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                onChange={() =>
                  this.handleVerificationType(
                    2,
                    this.state.countryCode + this.state.phone,
                  )
                }
                checked={this.state.sendTypeChecked === 2 ? true : false}
              />
              <label className="form-check-label pr-2" htmlFor="inlineRadio2">
                {langService.t('UserAccount_TextMessage')}
              </label>
              <span className="phoneno">{this.state.phone}</span>
            </div>
          ) : (
            ''
          )}
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-block"
          onClick={this.handleSubmit}
        >
          {langService.t('Btn_Send')}
        </button>
      </div>
    )
  }
}

export default ForgotOptions
