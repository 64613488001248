import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEnglish from "./locales/en.json";
import translationChineseSimplified from "./locales/zh-CN.json";
import translationJapanese from "./locales/ja.json";
import translationFrench from "./locales/fr.json";
import translationGerman from "./locales/de.json";
import translationPortuguese from "./locales/pt-PT.json";
import translationKorean from "./locales/ko.json";
import translationIndonesian from "./locales/id.json";
import translationSpanish from "./locales/es.json";
import translationItalian from "./locales/it.json";
import translationSwedish from "./locales/sv.json";
import translationDanish from "./locales/da.json";
import translationThai from "./locales/th.json";

/*
const options = {
    order: ['navigator']
}
*/

i18n.use(XHR)
    .use(LanguageDetector)
    .init({

        // Force browser language only
        //detection: options,

        // we init with resources
        resources: {
            en: { translations: translationEnglish },
            'zh-CN': { translations: translationChineseSimplified },
            ja: { translations: translationJapanese },
            fr: { translations: translationFrench },
            de: { translations: translationGerman },
            'pt-PT': { translations: translationPortuguese },
            ko: { translations: translationKorean },
            id: { translations: translationIndonesian },
            es: { translations: translationSpanish },
            it: { translations: translationItalian },
            sv: { translations: translationSwedish },
            da: { translations: translationDanish },
            th: { translations: translationThai }
        },
        // lng: "en",
        fallbackLng: "en",
        debug: false,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ",",
        },

        react: {
            wait: true,
        },
    });

export default i18n;
