import React from "react";
import * as configDataService from "./services/configDataService";
import * as langService from './services/langService';

class InitScript extends React.Component {
    constructor(props) {
        super(props);

        configDataService.init();
    }

    componentDidMount() {
        document.title = langService.t("Title");
    }

    render() {
        return (
            <div></div>
        );
    }
}

export default InitScript;
