import React from "react";
import './css/styles.css';
import SearchPage from './search/SearchPage';
import RequestCourse from './requestCourse/RequestCourse';
import PageNotFound from './pageNotFound/PageNoteFound';
import {
    Switch,
    Route,
    HashRouter
  } from "react-router-dom";

  function AppCourse() {
    return (
      <div className="App course">
        <HashRouter>

            <Switch>

                <Route path="/course/*">
                  <PageNotFound/>
                </Route>
                <Route path="/request/*">
                  <PageNotFound/>
                </Route>
                <Route path="/request">
                  <RequestCourse/>
                </Route>
                <Route exact path="/course" render={ props => <SearchPage {...props} />} >
                </Route>

            </Switch>
  
        </HashRouter>
      </div>
    );
  }

  export default AppCourse;