import i18n from "../i18n";

export function t(key) {
    return i18n.t(key);
}

export function tErrorMsg(key) {
    return i18n.t("ErrorPrefix") + i18n.t(key);
}

export function changeLanguage(newlang) {
    i18n.changeLanguage(newlang);
}

export function getLanguage() {
    return i18n.language;
}
