import * as langService from '../services/langService';
import * as configDataService from "../services/configDataService";
import * as downloadService from "../services/downloadService";

export async function downloadData_CourseSearch(countryCode, stateCode) {
    let configData = configDataService.getConfigData();
    let url = configData.CourseSearchURL.SearchURL;
    let params = countryCode;
    if (stateCode !== null && stateCode !== "") {
        params = params + "-" + stateCode;
    }
    url = url + params;
    let fileObject = await downloadService.downloadData_AWS(url, configData.AWS_APIKey);
    if (fileObject.status !== 200) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    let data = await fileObject.json();
    if (data.errorMessage) throw new Error(langService.t("UpdateService_FileRequestFailed"));
    return data.body.items;
}

export async function downloadData_CourseSearchFilter(countryCode, stateCode, city, nameOrZip) {
    let data = await downloadData_CourseSearch(countryCode, stateCode);
    if (data.length > 0 && city !== null && city !== "") {
        city = city.trim().toLowerCase();
        data = data.filter(function (el) {
            return el.cit.toLowerCase().startsWith(city);
        });
    }
    if (data.length > 0 && nameOrZip !== null && nameOrZip !== "") {
        nameOrZip = nameOrZip.trim().toLowerCase();
        data = data.filter(function (el) {
            return el.nam.toLowerCase().startsWith(nameOrZip) 
            || el.zip.toLowerCase().startsWith(nameOrZip)
            || el.nam_nl.toLowerCase().startsWith(nameOrZip);
        });
    }
    return data;
}

async function downloadDataFile(dataURL) {
    let fileObject = await downloadService.downloadData(dataURL);
    if (fileObject.status !== 200) throw new Error(langService.t("UserAccount_FileRequestFailed"));
    return fileObject.json();
};

export async function getCountryStateData() {
    let configData = configDataService.getConfigData();
    let URL_Country = configData.LocalURL.CountryList;
    let URL_State = configData.LocalURL.StateList;

    let cData = await downloadDataFile(URL_Country);
    let sData = await downloadDataFile(URL_State);
    cData.countries = [{"code": "SELECT", "name": "Select Country" }, ...cData.countries, {"code": "ALL", "name": "All Courses"}]
    return { countryData: cData, stateData: sData };
};
