import { BlobServiceClient } from "@azure/storage-blob";

export async function uploadFileToBlob(urlSAS, containerName, file, fileName) {
    if (!file) return [];

    // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
    const blobService = new BlobServiceClient(urlSAS); //`https://${account}.blob.core.windows.net/?${sasToken}`);
    const containerClient = blobService.getContainerClient(containerName);
    const blobClient = containerClient.getBlockBlobClient(fileName);

    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    await blobClient.uploadData(file, options);
}
