import React from "react";
import * as langService from '../services/langService';
import * as commonService from "../services/commonService.js";
import * as configService from "../services/configDataService";
import * as downloadService from "../services/downloadService";
import * as bushnellAPI from "../services/bushnellAPIService.js";

class Address extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countryData: null,
            stateData: null,
            nextForm: props.nextForm,
            country: "",
            street: "",
            city: "",
            stateName: "",
            ZIP: "",
            formData: props.formData,
            btnClicked: false,
        };
        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
    }

    componentDidMount = async () => {
        try {
            await this.storeCountryData();
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
        }
    };

    handleChangeCountry(value) {
        let newStateName = this.getStateName(this.state.stateData.states, value);
        this.setState({ country: value, stateName: newStateName });
    }

    handleChangeStreet(value) {
        this.setState({ street: value });
    }

    handleChangeCity(value) {
        this.setState({ city: value });
    }

    handleChangeState(value) {
        this.setState({ state: value, stateName: value });
    }

    handleChangeZIP(value) {
        this.setState({ ZIP: value });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        try {
            this.props.showLoader(true);
            let data = {
                StreetName: this.state.street.trim(),
                City: this.state.city.trim(),
                State: this.state.stateName.trim(),
                Country: this.state.country.trim(),
                Zip: this.state.ZIP.trim(),
            };
            let result = await bushnellAPI.updateUserAddress(data);
            if (result.errorCode === 0) {
                var response = await bushnellAPI.Login(this.state.formData.email, this.state.formData.password);
                if (response.errorCode === 0) {
                    this.state.nextForm("LoginDone");
                } else {
                    commonService.showMessage(response.status);
                }
                //commonService.showMessage("User address updated");
            } else {
                commonService.Log(result.returnMsg);
            }
            this.props.showLoader(false);
            this.setState({ btnClicked: false });
        } catch (err) {
            commonService.Log(err);
            commonService.showMessage(langService.tErrorMsg(err.message));
            this.props.showLoader(false);
            this.setState({ btnClicked: false });
        }
    };

    downloadDataFile = async (dataURL) => {
        let fileObject = await downloadService.downloadData(dataURL);
        if (fileObject.status !== 200) throw new Error(langService.t("UserAccount_FileRequestFailed"));
        return fileObject.json();
    };

    getStateName(states, countryCode) {
        var filterData = states.filter(function (el) {
            return el.countryCode === countryCode;
        });
        let newStateName = "";
        if (filterData.length !== 0) {
            newStateName = filterData[0].name;
        }
        return newStateName;
    }

    storeCountryData = async () => {
        let data = this.state.countryData;
        if (!data) {
            let configData = configService.getConfigData();
            let URL_Country = configData.LocalURL.CountryList;
            let URL_State = configData.LocalURL.StateList;

            let cData = await this.downloadDataFile(URL_Country);
            let sData = await this.downloadDataFile(URL_State);
            let newStateName = this.getStateName(sData.states, cData.countries[0].code);
            this.setState({
                countryData: cData,
                country: cData.countries[0].code,
                stateData: sData,
                stateName: newStateName
            });
        }
    };

    render() {
        let selectCountry = "";
        let cData = this.state.countryData;
        if (cData) {
            selectCountry = <SelectList handleChange={this.handleChangeCountry} value={this.state.country} dataList={cData.countries} />;
        } else {
            selectCountry = "";
        }
        let selectState = "";
        let sData = this.state.stateData;
        let countryCode = this.state.country;
        if (sData) {
            var filterData = sData.states.filter(function (el) {
                return el.countryCode === countryCode;
            });
            if (filterData.length === 0) {
                selectState = <input type="text" className="form-control" name="stateName" value={this.state.stateName} onChange={(e) => this.handleChangeState(e.target.value)} />;
            } else {
                selectState = <SelectList handleChange={this.handleChangeState} value={this.state.stateName} dataList={filterData} />;
            }
        } else {
            selectState = "";
        }

        return (
            <div className="form-main">
                <div className="row">
                    <div className="col">
                        <h1>{langService.t("UserAccount_Address")}</h1>
                    </div>
                </div>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label htmlFor="inputEmail4">{langService.t("UserAccount_Country")}</label>
                            {selectCountry}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label htmlFor="inputEmail4">{langService.t("UserAccount_Street")}</label>
                            <input type="text" className="form-control" onChange={(e) => this.handleChangeStreet(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label htmlFor="inputEmail4">{langService.t("UserAccount_City")}</label>
                            <input type="text" className="form-control" onChange={(e) => this.handleChangeCity(e.target.value)} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label htmlFor="inputEmail4">{langService.t("UserAccount_State")}</label>
                            {selectState}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label htmlFor="inputEmail4">{langService.t("UserAccount_ZIP")}</label>
                            <input type="text" className="form-control" onChange={(e) => this.handleChangeZIP(e.target.value)} />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block" disabled={this.state.btnClicked}>
                        {langService.t("Btn_Done")}
                    </button>
                </form>
            </div>
        );
    }
}

class SelectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: props.value };
    }
    handleChangeValue(selectedValue) {
        this.setState({ value: selectedValue });
        this.props.handleChange(selectedValue);
    }
    render() {
        return (
            <select className="form-control" value={this.state.value} onChange={(e) => this.handleChangeValue(e.target.value)}>
                {this.props.dataList.map(function (item) {
                    return (
                        <option key={item.code} value={item.code}>
                            {item.name}
                        </option>
                    );
                })}
            </select>
        );
    }
}

export default Address;
