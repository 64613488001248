import React from "react";
import * as langService from '../../services/langService'

class PageNotFound extends React.Component 
{
    render() 
    {
        return(
        <>
        <div className="container text-center">
            <div className="col-sm-10 col-md-5 marginAuto">
                <div className="form-main">
                    <h1>{langService.t("PageNotFound_404")}</h1>
                    <div>{langService.t("PageNotFound_PageMovedOrNotExist")}</div>
                    <a className="btn btn-secondary btn-block" href="#/course">{langService.t("Btn_BackToHome")}</a>
                </div>
            </div>
        </div>
        </>
        );
    }
}
export default PageNotFound;