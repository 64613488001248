import React from "react";
import * as commonService from "../services/commonService";
import * as langService from "../services/langService";
import * as configDataService from "../services/configDataService";

import UserProcess from "./UserProcess";

class UserHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = { nextForm: props.nextForm, showLoader: props.showLoader };
        this.nextForm = this.nextForm.bind(this);
    }
    nextForm(mode) {
        this.state.nextForm(mode);
    }

    getForm() {
        let browserProps = commonService.getBrowserProps();
        if (browserProps.isBrowserChrome) {
            return <UserProcess nextForm={this.nextForm} showLoader={this.handleShowLoader} />;
        } else {
            return <InvalidBrowser />;
        }
    }

    handleShowLoader = (data) => {
        this.setState({ showLoader: data });
        this.props.showLoader(data);
    };
    render() {
        return (
            <div className="bg-login">
                <div className="bg-overlay"></div>
                <div className="row loginouter">
                    <div className="col-lg-6 logbg d-none d-lg-block">
                        <div className="d-flex align-items-center logIcon">
                            <div className="col text-center">
                                <img alt="logo" src="images/bushnell_logo_xl_login.svg" />
                                <div className="texts-lf">
                                    <h1>{langService.t("UserHome_Welcome")}</h1>
                                    <h2>{langService.t("UserHome_Message")}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 bg-white login_container d-flex align-items-center">
                        <div className="logmain">
                            <div className="col-xl-6 col-lg-8 col-md-8 m-auto">
                                <div className="responsive-logo text-center">
                                    <img alt="logo" src="images/bushnell_logo_xl_login.svg" />
                                </div>
                                <div className="texts-rf">
                                    <h1>{langService.t("UserHome_Welcome")}</h1>
                                    <h2>{langService.t("UserHome_Message")}</h2>
                                </div>
                                {this.getForm()}
                            </div>
                            <LangSelector />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class LangSelector extends React.Component {
    onLanguageChange = (e) => {
        let lang = e.target.value;
        if (lang) {
            langService.changeLanguage(lang);
            window.location.replace(process.env.PUBLIC_URL + "/");
        }
    }

    render() {
        let canShow = configDataService.getConfigData().Flags.ShowLanguageSelector === "1";
        let langStyle = {
            float: "right",
            display: (canShow ? "block" : "none")
        };
        return (
            <div style={langStyle}>
                <select onChange={this.onLanguageChange}>
                    <option value="">Select language</option>
                    <option value="en">English</option>
                    <option value="zh-CN">Chinese Simplified</option>
                    <option value="ja">Japanese</option>
                    <option value="fr">French</option>
                    <option value="de">German</option>
                    <option value="pt-PT">Portuguese</option>
                    <option value="ko">Korean</option>
                    <option value="id">Indonesian</option>
                    <option value="es">Spanish</option>
                    <option value="it">Italian</option>
                    <option value="sv">Swedish</option>
                    <option value="da">Danish</option>
                    <option value="th">Thai</option>
                </select>
            </div>
        )
    }
}

class InvalidBrowser extends React.Component {
    render() {
        return (
            <div>
                <div className="form-main">
                    <div className="chrome text-center">
                        <p className="text-center">{langService.t("UserHome_NeedChrome")}</p>
                        <a className="mt-2" target="_blank" rel="noreferrer" href="https://www.google.com/chrome">
                            <img alt="Chrome" src="images/chrome.svg" />
                            {langService.t("UserHome_DownloadChrome")}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserHome;
