import React from 'react';
import PageLoader from '../Loader/Loader';
import Alerts from './alerts';
import _ from "lodash";
import * as configService from "../../services/configDataService";
import * as langService from '../../services/langService'

class SearchResult extends React.Component 
{

    constructor() 
    {
      super();
      this.state = {courseData:null, getData: false, loader:true, errorFlag:false, country:null, updateData:true, searchDataAll:null};
    }

    hideAlert=()=>
    {

      setTimeout(() => {
        this.setState({errorFlag:false});
      }, 3000);

    }

    updateData = (propsData)=>
    {

        // this.setState({updateData:propsData.searchParams.updateData});

        let cityFilter = propsData.searchParams.city;

        let searchAllFilter = propsData.searchParams.searchAll;
        
        this.setState({loader:true, country:propsData.searchParams.country});

        if(this.state.updateData === false)
        {

            let searchData = {items:this.state.searchDataAll, cityFilter:cityFilter, searchAllFilter:searchAllFilter};
            
            // this.setState({loader:true},()=>{

                setTimeout(() => {
                    
                    this.setState({loader:true});

                    this.searchFields(searchData).then((complete)=>{
                    
                        this.setState({loader:false});
    
                    });

                }, 2000);

            // })

            return;

        }
        
        let countryData = propsData.searchParams.country;
        
        let stateData = propsData.searchParams.state;
        
        let configData = configService.getConfigData();
        
        let url = configData.CourseSearchURL.SearchURL;
        
        let golfServicesUrl = `${url}${countryData}`;

        if((countryData === 'US') || (countryData === 'CA'))
        {
            golfServicesUrl = `${url}${countryData}-${stateData}`;
        }

        let token = configData.AWS_APIKey;

        const requestOptions = {
            method: 'GET',
            headers: {'x-api-key':token}
        };

        fetch(golfServicesUrl, requestOptions)
            .then(async response => {

                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                if ((response.ok) && (data.statusCode === 200)) 
                {

                    this.setState({searchDataAll:data.body.items},()=>{

                        let searchData = {items:data.body.items, cityFilter:cityFilter, searchAllFilter:searchAllFilter};
                        this.searchFields(searchData);

                    })

                }
                else
                {
                    this.setState({errorFlag:true});
                    this.hideAlert();
                }

                this.setState({loader:false});
    
            })
            .catch(error => {
                this.setState({errorFlag:true, updateData:true});
                this.hideAlert();
            });

    }

    searchFields= async (data)=>{

        let itemArray = data.items;
        let cityFilter = data.cityFilter;
        let searchAllFilter = data.searchAllFilter;

        if((cityFilter !== null) && (cityFilter !== ''))
        {
            itemArray = itemArray.filter(function(item) {
                return item.cit.toUpperCase().indexOf(cityFilter.toUpperCase()) > -1;
            });

        }
        
        if((searchAllFilter !== null) && (searchAllFilter !== ''))
        {

            itemArray = _.filter(itemArray, function (item) {

                    return item.nam.toUpperCase().indexOf(searchAllFilter.trim().toUpperCase()) >= 0 
                        || item.zip.indexOf(searchAllFilter.trim().toUpperCase()) >= 0 
                        || item.nam_nl.indexOf(searchAllFilter.trim()) >= 0 

              });

        }

        itemArray.sort(function(a, b) {
            var nameA = a.nam.toUpperCase();
            var nameB = b.nam.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
        });

        this.setState({courseData: itemArray},()=>{

            this.setState({getData: true});

        });


    }

    componentWillMount = ()=>
    {

        this.updateData(this.props)

    }

    updateList = ()=>
    {
        const koreanLangCode = ['ko', 'ko-kr']

        if((this.state.courseData === null) || (!this.state.courseData) || (this.state.courseData === '') || (this.state.courseData.length === 0))
        {
            return (<tr><td colSpan={5}><div className="emptyData">{langService.t("CourseSearch_NoRecords")}</div></td></tr>);
        }
        else
        {
            if( (langService.getLanguage() === 'ja' && this.state.country === 'JP') 
                || (koreanLangCode.includes(langService.getLanguage().toLowerCase()) && this.state.country === 'KR') ){
                return (
                    this.state.courseData.map((item, index) =>{
                        
                        return(<tr key={index}>
                            <td  style={{width: "30%"}}>{item.nam_nl}</td>
                            <td style={{width: "30%"}}>{item.adr_nl}</td>
                            {((this.state.country === 'US') || (this.state.country === 'CA')) ? <td style={{width: "13%"}}>{item.sta}</td> : null}
                            <td style={{width: "13%"}}>{item.cit_nl}</td>
                            <td style={{width: "14%"}}>{item.zip}</td>
                        </tr>);
                    })
                );
            } else {
                return (
                    this.state.courseData.map((item, index) =>{
                        
                        return(<tr key={index}>
                            <td  style={{width: "30%"}}>{item.nam}</td>
                            <td style={{width: "30%"}}>{item.adr}</td>
                            {((this.state.country === 'US') || (this.state.country === 'CA')) ? <td style={{width: "13%"}}>{item.sta}</td> : null}
                            <td style={{width: "13%"}}>{item.cit}</td>
                            <td style={{width: "14%"}}>{item.zip}</td>
                        </tr>);
                    })
                );
            }
        }


    }

    componentWillReceiveProps = (nextProps)=>
    {

        let updateData = false;

        if(nextProps.searchParams.country !== this.props.searchParams.country)
        {
            updateData = true;
        }
        else if(((typeof nextProps.searchParams.state !== 'undefined')) && (nextProps.searchParams.state !== this.props.searchParams.state))
        {
            updateData = true;
        }

        this.setState({updateData:updateData},()=>{

            this.updateData(nextProps);

        })

    }

    render() 
    {

        return (
            <>
                {(this.state.loader === true ? <PageLoader/>  : null)}
                <div className="bn_g_custom-container">
                    {(this.state.errorFlag === true ? <Alerts alertType="danger" alertMessage={langService.t("CourseSearch_CourseSearchFailed")} />  : null)}
                    <div className="search-result-head table-data">
                    <table className="table" cellSpacing="0" cellPadding="0" >
                        <thead>
                            <tr>
                                <th style={{width: "30%"}}>{langService.t("CourseSearch_CourseName")}</th>
                                <th style={{width: "30%"}}>{langService.t("CourseSearch_CourseAddress")}</th>
                                {((this.state.country === 'US') || (this.state.country === 'CA')) ? <th style={{width: "13%"}}>{langService.t("CourseSearch_State")}</th> : null}
                                <th style={{width: "13%"}}>{langService.t("CourseSearch_City")}</th>
                                <th style={{width: "14%"}}>{langService.t("CourseSearch_Zip")}</th>
                            </tr>
                        </thead>
                    </table>
                    </div>
                    <div className="search-result table-data">
                    <table className="table" cellSpacing="0" cellPadding="0" >
                        <tbody>
                            {this.updateList()}
                        </tbody>
                    </table>
                    </div>
                </div>

            </>
        );
    }
}

export default SearchResult;